<template>
  <div>
    <DataTable
      v-model:selection="selectedProductGroupNames"
      v-model:filters="filters"
      :value="productGroupNameComputed"
      data-key="id"
      class="c-datatable"
      :loading="loading"
      :striped-rows="true"
      selection-mode="multiple"
      data-testid="product-group-name-list"
      tabindex="0"
      responsive-layout="scroll"
      :global-filter-fields="['global', 'name']"
      scrollable
      scroll-height="70vh"
      @row-dblclick="onRowDblClick"
      @row-select="onRowSelected"
    >
      <template #header>
        <div class="flex overflow-hidden">
          <div class="grow flex items-center justify-center">
            <Button
              v-if="!toggleSelectRows"
              data-testid="btn-add-product-group-name"
              size="small"
              class="c-success-button w-full"
              @click.stop="showAddProductGroupNameDialog = true"
            >
              <i class="pi pi-plus c-circular-icon"></i>
              <div class="px-4 whitespace-nowrap overflow-hidden text-ellipsis w-full">
                {{ t("product-group-name.add") }}
              </div>
            </Button>

            <Button
              v-if="toggleSelectRows"
              data-testid="btn-delete-product-group-names"
              size="small"
              class="c-delete-button w-full"
              @click.stop="onConfirmDelete(null, $event)"
            >
              <i class="pi pi-trash c-circular-icon"></i>
              <div class="px-4 whitespace-nowrap overflow-hidden text-ellipsis w-full">
                {{ t("product-group-name.delete-selected") }}
              </div>
            </Button>
          </div>

          <div class="flex-none flex items-center justify-center">
            <Button
              icon="pi pi-cog"
              class="c-product-group-name-cog p-button-rounded p-button-text ml-2"
              data-testid="btn-group-name-options"
              size="large"
              @click="toggleSelectRows = !toggleSelectRows"
            />
          </div>
        </div>
        <div class="mt-6 w-full">
          <FloatLabel variant="on">
            <IconField>
              <InputIcon class="pi pi-search" />
              <InputText
                id="product-group-name-search"
                v-model="filters['global'].value"
                autocomplete="off"
                class="w-full"
              />
            </IconField>
            <label for="product-group-name-search">{{ t("product-group-name.filter") }}</label>
          </FloatLabel>
        </div>
      </template>

      <Column v-if="toggleSelectRows" selection-mode="multiple" header-style="width: 3rem"></Column>

      <Column field="name" :header="t('product-group-name.header')" filter-field="name" sortable>
        <template #body="{ data, field, index }">
          <div></div>

          <div class="flex justify-between flex-wrap">
            <div :data-testid="`product-group-${field}-row-${index}`" class="flex items-center justify-center w-4rem2">
              {{ data[field as keyof typeof data] }}
            </div>

            <div class="flex items-center justify-center">
              <div class="flex justify-evenly">
                <Button
                  icon="pi pi-pencil"
                  class="c-edit-button p-button-rounded mr-2"
                  text
                  :data-testid="`btn-group-name-edit-${index}`"
                  @click.stop="editProductGroupName(data)"
                />

                <Button
                  icon="pi pi-trash"
                  class="c-delete-button p-button-rounded mr-2 !bg-transparent"
                  text
                  :data-testid="`btn-group-name-delete-${index}`"
                  @click.stop="onConfirmDelete(data, $event)"
                />
              </div>
            </div>
          </div>
        </template>

        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter"
            :placeholder="t('product-group-name.filter-by')"
            @input="filterCallback()"
          />
        </template>
      </Column>

      <template #empty>{{ t("product-group-name.empty-list") }} </template>
      <template #loading>{{ t("product-group-name.loading-list") }}</template>
    </DataTable>

    <AddProductGroupName v-if="showAddProductGroupNameDialog" v-model:show-dialog="showAddProductGroupNameDialog" />
    <EditProductGroupName
      v-if="showEditProductGroupNameDialog"
      v-model:show-dialog="showEditProductGroupNameDialog"
      :product-group-name="selectedProductGroupName"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { ref, computed, onMounted, watch } from "vue";
import type { DataTableRowDoubleClickEvent, DataTableRowSelectEvent } from "primevue/datatable";
import { useAuth } from "@cumulus/event-bus";
import { FilterMatchMode } from "@primevue/core/api";
import { ProductGroupName } from "../model/ProductGroupName";
import { ProductGroupNameByLanguage } from "../model/ProductGroupNameByLanguage";
import { useProductHierarchyStore } from "../api/ProductHierarchyStore";
import { useConfirm } from "primevue/useconfirm";

import AddProductGroupName from "../components/AddProductGroupName.vue";
import EditProductGroupName from "../components/EditProductGroupName.vue";
import { useProductLanguageFunctions } from "@/utils/productLanguageFunctions";

const { t } = useI18n();

const productHierarchyStore = useProductHierarchyStore();
const { loading, productGroupNames } = storeToRefs(productHierarchyStore);
const { transformLanguageCode } = useProductLanguageFunctions();

const showAddProductGroupNameDialog = ref<boolean>(false);
const showEditProductGroupNameDialog = ref<boolean>(false);
const selectedProductGroupName = ref<ProductGroupName>(new ProductGroupName());
const selectedProductGroupNames = ref<ProductGroupNameByLanguage[] | null>(null);

const editProductGroupName = (productGroupNameByLanguage: ProductGroupNameByLanguage) => {
  const productGroupName = productGroupNames.value.find(
    (pgn: ProductGroupName) => pgn.id === productGroupNameByLanguage.id,
  );

  if (productGroupName) {
    selectedProductGroupName.value = productGroupName;
    showEditProductGroupNameDialog.value = true;
  }
};

onMounted(async () => await productHierarchyStore.getProductGroupNames());

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  editProductGroupName(event.data as ProductGroupNameByLanguage);
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  editProductGroupName(event.data as ProductGroupNameByLanguage);
};

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
});

const { getUser } = useAuth();
const languageIso = ref("en-GB");

onMounted(async () => {
  const userLanguage = (await getUser()).getEmployee()?.languageCode ?? "en-GB";
  languageIso.value = transformLanguageCode(userLanguage);
});

const productGroupNameComputed = computed(() =>
  productGroupNames.value.map((productGroupName) =>
    ProductGroupNameByLanguage.createFromProductGroupName(productGroupName, languageIso.value),
  ),
);

const toggleSelectRows = ref<boolean>(false);

const confirm = useConfirm();
const onConfirmDelete = (productGroupNameByLanguage: ProductGroupNameByLanguage | null, event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    group: "confirm-popup",
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: async () => {
      if (productGroupNameByLanguage) {
        await deleteProductGroupName(productGroupNameByLanguage);
      } else {
        await deleteSelectedItems();
      }
    },
  });
};

const deleteProductGroupName = async (productGroupName: ProductGroupNameByLanguage) => {
  await productHierarchyStore.deleteProductGroupName(productGroupName.id);
};

const deleteSelectedItems = async () => {
  selectedProductGroupNames.value?.forEach(async (productGroupName) => {
    if (productGroupName?.id) {
      await productHierarchyStore.deleteProductGroupName(productGroupName?.id);
    }
  });
};

watch(
  () => toggleSelectRows.value,
  (newValue) => {
    if (!newValue) {
      selectedProductGroupNames.value = null;
    }
  },
);
</script>

<style scoped lang="scss">
.c-product-gGroup-name:hover {
  text-decoration: underline;
}

.p-datatable-selectable-row.p-highlight .p-button.p-button-text {
  color: var(--primary-color-text);
}

.c-edit-button.p-button.p-button-text,
.c-delete-button.p-button.p-button-text {
  visibility: hidden;
}

.c-delete-button.p-button.p-button-text:hover {
  color: var(--delete-btn-bg);
}

.c-edit-button.p-button.p-button-text:hover {
  color: var(--success-btn-bg);
}

.p-datatable-hoverable .p-datatable-tbody > tr:not(.p-datatable-row-selected):hover,
.p-datatable-hoverable .p-datatable-tbody > tr[data-p-selected="true"] {
  .c-edit-button,
  .c-delete-button {
    visibility: visible;
  }
}

.c-product-group-name-cog.p-button:focus {
  box-shadow: none;
}

:deep(.p-datatable) .p-datatable-header {
  background: none;
  border: none;
}
</style>
