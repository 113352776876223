<template>
  <Dialog
    :visible="visible"
    position="top"
    content-class="h-full w-[70vw] max-w-[1750px]"
    :modal="true"
    :closable="false"
    @hide="handleClose"
    @keydown.esc.stop="handleClose"
    @keydown.left.prevent="handleKeyboardNavigation('left')"
    @keydown.right.prevent="handleKeyboardNavigation('right')"
  >
    <template #header>
      <div class="flex justify-between items-center w-full">
        <h2 class="text-xl font-semibold text-gray-800">
          {{ t("product.image.manage") }}
        </h2>

        <Button icon="pi pi-times" class="p-button-text p-button-sm" @click="handleClose" />
      </div>
    </template>

    <div class="border-b pb-3 mb-4">
      <div class="flex items-center px-2">
        <div v-if="totalCount > 1" class="flex items-center gap-1 mr-3">
          <Button
            icon="pi pi-chevron-left"
            class="p-button-text p-button-sm"
            :disabled="currentIndex === 0"
            @click="handlePrevious"
          />
          <span class="mx-1 text-sm text-gray-600">{{ currentIndex + 1 }} / {{ totalCount }}</span>
          <Button
            icon="pi pi-chevron-right"
            class="p-button-text p-button-sm"
            :disabled="currentIndex === totalCount - 1"
            @click="handleNext"
          />
        </div>

        <div v-else class="h-8 mr-3"></div>

        <div v-if="currentProduct" class="flex items-center">
          <div class="h-5 w-[1px] bg-gray-300 mr-3"></div>
          <p class="text-base font-medium truncate max-w-lg">
            {{ currentProduct.productNumber }} - {{ currentProduct.name }}
          </p>
        </div>
      </div>
    </div>

    <div class="overflow-y-auto" style="max-height: calc(85vh - 140px)">
      <div class="px-4">
        <ProductImageEditor
          v-if="currentProduct"
          :product-images="currentProductImages"
          @update-image-order="handleUpdateImageOrder"
          @delete-image="handleDeleteImage"
          @update-image="handleUpdateImage"
          @add-image="handleAddImage"
        />
      </div>
    </div>

    <template #footer>
      <div class="flex justify-end w-full">
        <Button
          class="p-button-primary"
          icon="pi pi-check"
          :label="t('common.save')"
          :loading="saving"
          :disabled="saving || !hasChanges"
          @click="saveCurrentProduct"
        />
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { useToast } from "primevue/usetoast";
import { Product } from "@/repositories/product/model/Product";
import { ProductImage } from "@/repositories/product/model/ProductImage";
import { useProductStore } from "@/repositories/product/ProductStore";
import { useUnsavedChanges } from "@cumulus/components";

const props = defineProps<{
  searchProducts: Product[];
  selectedSearchProducts: Product[];
}>();

const emit = defineEmits<{
  (e: "productUpdated", product: Product): void;
}>();

const visible = defineModel<boolean>("visible", {
  required: true,
});

const { t } = useI18n();
const toast = useToast();
const { getProduct, updateProduct } = useProductStore();
const saving = ref(false);

const currentProductImages = ref<ProductImage[]>([]);
const deletedImages = ref<ProductImage[]>([]);
const hasChanges = ref(false);

const { currentIndex, currentProduct, totalCount, next, previous, resetCurrentIndex } = useProductSearchNavigation(
  computed(() => props.searchProducts),
  computed(() => props.selectedSearchProducts),
);

const setupCurrentProduct = () => {
  if (!currentProduct.value) {
    currentProductImages.value = [];
    deletedImages.value = [];
    hasChanges.value = false;
    return;
  }

  currentProductImages.value = (currentProduct.value.productImages || []).map((img) => ({
    name: img.name,
    format: img.format,
    url: img.url,
    description: img.description,
  }));

  deletedImages.value = [];
  hasChanges.value = false;
};

const handleUpdateImageOrder = (images: ProductImage[]) => {
  currentProductImages.value = [...images];
  hasChanges.value = true;
};

const handleDeleteImage = (url: string) => {
  const originalImages = currentProduct.value?.productImages || [];
  const imageToDelete = originalImages.find((img) => img.url === url);

  if (imageToDelete) {
    deletedImages.value.push({ ...imageToDelete });
  }

  currentProductImages.value = currentProductImages.value.filter((img) => img.url !== url);
  hasChanges.value = true;
};

const handleUpdateImage = (index: number, updatedImage: ProductImage) => {
  currentProductImages.value[index] = {
    ...currentProductImages.value[index],
    name: updatedImage.name,
    description: updatedImage.description,
  };

  hasChanges.value = true;
};

const handleKeyboardNavigation = (direction: "left" | "right") => {
  if (totalCount.value <= 1) return;

  if (direction === "left" && currentIndex.value > 0) {
    handlePrevious();
  } else if (direction === "right" && currentIndex.value < totalCount.value - 1) {
    handleNext();
  }
};

const handleAddImage = (image: ProductImage) => {
  currentProductImages.value.push(image);
  hasChanges.value = true;
};

const saveCurrentProduct = async () => {
  if (!currentProduct.value || saving.value || !hasChanges.value) return;

  try {
    saving.value = true;

    const productToUpdate = await getProduct(currentProduct.value.id);

    if (!productToUpdate) {
      throw new Error("Product not found");
    }

    productToUpdate.productImages = [...currentProductImages.value];

    await updateProduct(productToUpdate, deletedImages.value);

    hasChanges.value = false;
    deletedImages.value = [];

    toast.add({
      severity: "success",
      summary: t("product.import.product-updated-success-summary"),
      detail: t("product.import.product-updated-success-detail", { productNumber: currentProduct.value.productNumber }),
      life: 3000,
    });

    emit("productUpdated", productToUpdate);
  } catch (error) {
    toast.add({
      severity: "error",
      summary: t("common.error"),
      detail: t("product.image.update-error"),
      life: 5000,
    });
    console.error("Error saving product images:", error);
  } finally {
    saving.value = false;
  }
};

const handlePrevious = () => {
  if (confirmClose()) {
    previous();
    setupCurrentProduct();
  }
};

const handleNext = () => {
  if (confirmClose()) {
    next();
    setupCurrentProduct();
  }
};

const handleClose = () => {
  if (confirmClose()) {
    hasChanges.value = false;
    visible.value = false;
  }
};

watch(() => currentProduct.value?.id, setupCurrentProduct);

watch(
  () => visible.value,
  (newValue) => {
    if (newValue) {
      resetCurrentIndex();
      setupCurrentProduct();
    }
  },
);

const { confirmClose } = useUnsavedChanges(hasChanges);
</script>
