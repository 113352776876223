<template>
  <Dialog
    v-model:visible="visible"
    :modal="true"
    class="w-[110vw] max-w-[90%] h-[75vh] max-h-[90%] sm:w-[90vw] lg:w-[75vw]"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :closable="true"
    :close-on-escape="true"
    pt:content:class="h-full"
    content-class="h-full"
  >
    <template #header>
      <div class="flex justify-center w-full">
        <div class="font-bold whitespace-nowrap">{{ t("product.relations.dialog-add-header") }}</div>
      </div>
    </template>

    <div class="grid grid-cols-12 gap-6 h-full">
      <div class="col-span-12 lg:col-span-12 h-full">
        <div class="flex flex-col h-full">
          <div class="flex-none">
            <ProductSearchInput
              ref="productSearchInput"
              class="w-full my-2"
              :page-size="pageSize"
              :page="page"
              autofocus
              @update:search-results="onSearchResultsUpdated"
              @update:is-searching="($event: boolean) => (loading = $event)"
              @focus-search-result="onFocusSearchResult"
            />
          </div>

          <div class="flex-1 flex flex-col min-h-0">
            <DataTable
              v-show="searchResultsComputed.length > 0"
              ref="searchResultTable"
              v-model:selection="selectedProducts"
              :value="searchResultsComputed"
              data-key="id"
              data-testid="search-result-table"
              :auto-layout="true"
              :lazy="true"
              :paginator="true"
              responsive-layout="scroll"
              selection-mode="multiple"
              scrollable
              scroll-height="calc(75vh - 250px)"
              :loading="loading"
              :rows="pageSize"
              :total-records="totalRecords"
              :current-page-report-template="
                t('common.current-page-template', {
                  first: '{first}',
                  last: '{last}',
                  totalRecords: '{totalRecords}',
                })
              "
              paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              paginator-position="bottom"
              class="h-full"
              :pt="{
                wrapper: 'h-full flex flex-col',
                content: 'flex-1 overflow-auto',
                footer: 'mt-auto bg-white',
              }"
              @page="onPage"
            >
              <Column selection-mode="multiple" class="w-12"></Column>

              <Column field="productImages" class="p-1">
                <template #body="{ data }">
                  <img
                    v-if="data.productImages.length > 0"
                    :src="resizeImage(data.productImages[0].url, 30, 30)"
                    class="c-product-image"
                    width="30"
                    height="30"
                    :alt="`${data.productImages[0].name}-product-image`"
                  />
                </template>
              </Column>

              <Column field="productNumber" :header="t('product.relations.header-product-number')" class="p-1">
                <template #body="{ data }">
                  {{ data.productNumber }}
                </template>
              </Column>

              <Column field="name" :header="t('product.relations.header-name')" class="p-1">
                <template #body="{ data }">
                  {{ data.name }}
                </template>
              </Column>

              <Column field="description" :header="t('product.relations.header-description')" class="p-1">
                <template #body="{ data }">
                  {{ data.description }}
                </template>
              </Column>

              <Column :header="t('product.relations.relation-type')" class="w-[250px] p-1">
                <template #body="{ data }">
                  <div @click.stop>
                    <Select
                      :model-value="getProductRelationType(data)"
                      :options="relationTypes"
                      option-label="label"
                      option-value="type"
                      :placeholder="t('product.relations.select-relation-type')"
                      :disabled="!isSelected(data)"
                      class="w-full"
                      @update:model-value="(value: ProductRelationType) => setProductRelationType(data, value)"
                    />
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-between items-center gap-4 pt-5">
        <div class="text-sm text-gray-500">
          {{ t("product.relations.selected-count", { count: selectedProducts.length }) }}
        </div>
        <div class="flex gap-4">
          <Button
            v-tooltip.top="tooltipComputed"
            type="button"
            data-testid="btn-add"
            class="c-success-button"
            :disabled="!canSave"
            @click="save"
          >
            <i class="pi pi-check"></i>
            <span class="px-2">{{ t("product.relations.button-add") }}</span>
          </Button>
          <Button data-testid="btn-cancel" severity="cancel" text @click="visible = false">
            <i class="pi pi-times"></i>
            <span class="px-2 font-bold">{{ t("common.cancel") }}</span>
          </Button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ProductRelation } from "@/repositories/product/model/ProductRelation";
import { Product } from "@/repositories/product/model/Product";
import { ref } from "vue";
import ProductSearchInput from "../ProductSearchInput.vue";
import { ProductRelationType } from "@/repositories/product/model/ProductRelationType";
import { useImageService } from "@/repositories/image/ImageService";
import { useProductPopupSearchManagement } from "@/product/composables/product-relations/useProductPopupSearchManagement";
import { useProductPopupRelationManagement } from "@/product/composables/product-relations/useProductPopupRelationManagement";
import type { DataTablePageEvent } from "primevue/datatable";

const { t } = useI18n();
const { resizeImage } = useImageService();

const relationTypes = [
  { label: t("product.relations.accessory"), type: ProductRelationType.Accessory },
  { label: t("product.relations.accessoryto"), type: ProductRelationType.AccessoryTo },
  { label: t("product.relations.alternativecrosssell"), type: ProductRelationType.AlternativeCrossSell },
  { label: t("product.relations.alternativeupsell"), type: ProductRelationType.AlternativeUpSell },
  { label: t("product.relations.alternativedownsell"), type: ProductRelationType.AlternativeDownSell },
];

const visible = defineModel<boolean>("visible", { required: true, default: false });

const props = defineProps<{
  relations: ProductRelation[];
  productId: string;
  relatedProducts: Product[];
}>();

const emit = defineEmits<{
  (e: "addRelations", value: ProductRelation[]): void;
  (e: "update:visible", value: boolean): void;
}>();

const productSearchInput = ref();
const searchResultTable = ref();
const loading = ref(false);
const page = ref(1);
const pageSize = 25;

const {
  selectedProducts,
  productRelationTypes,
  searchResults,
  totalRecords,
  searchResultsComputed,
  onSearchResultsUpdated: onSearchResultsUpdatedComposable,
  isSelected,
} = useProductPopupSearchManagement(props.relations, props.productId, props.relatedProducts);

const { canSave, tooltipComputed, getProductRelationType, setProductRelationType, save, resetData } =
  useProductPopupRelationManagement(
    selectedProducts,
    productRelationTypes,
    searchResults,
    emit as (event: "addRelations" | "update:visible", value: ProductRelation[] | boolean) => void,
  );

const onPage = async (event: DataTablePageEvent) => {
  page.value = event.page + 1;
};

const onFocusSearchResult = () => {
  searchResultTable.value.$el.querySelector("tbody tr:first-of-type").focus();
};

watch(
  () => visible.value,
  (newValue) => {
    if (!newValue) {
      resetData();
    }
  },
);

const onSearchResultsUpdated = (products: Product[], totalHits: number, searchTerm: string) => {
  onSearchResultsUpdatedComposable(products, totalHits, searchTerm, page.value);
};
</script>
