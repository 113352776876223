<template>
  <div class="flex items-center">
    <div v-if="loading" class="flex items-center text-sm text-gray-600">
      <i class="pi pi-spin pi-spinner mr-2"></i>
      {{ t("product.import.ai.loading") }}
    </div>
    <PrimeButton v-else-if="!hasSuggestions" class="p-button-text" style="min-width: 250px" @click="getSuggestions">
      <i class="pi pi-star text-yellow-500 mr-2"></i>
      {{ t("product.import.ai.suggest-mapping") }}
    </PrimeButton>
    <PrimeButton v-else class="p-button-text p-button-sm" @click="getSuggestions">
      <i class="pi pi-refresh mr-1"></i>
      {{ t("product.import.ai.retry-suggest-mapping") }}
    </PrimeButton>
  </div>
</template>

<script setup lang="ts">
import { useProductImportStore } from "@/product/api/import/ProductImportStore";
import { ColumnMapping } from "@/product/models/ColumnMapping";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  columns: string[];
}>();

const emit = defineEmits<{
  (e: "suggestionsReceived", mappings: ColumnMapping[], confidenceScores: Record<string, number>): void;
}>();

const loading = ref(false);
const productImportStore = useProductImportStore();
const hasSuggestions = ref(false);
const { t } = useI18n();

const getSuggestions = async () => {
  try {
    loading.value = true;
    const { mappings, confidenceScores } = await productImportStore.getColumnMappingSuggestions(props.columns);

    if (mappings.length > 0) {
      emit("suggestionsReceived", mappings, confidenceScores);
      hasSuggestions.value = true;
    }
  } finally {
    loading.value = false;
  }
};
</script>
