import { type AxiosResponse } from "axios";
import { Document } from "@/repositories/product/model/Document";
import { httpClient, type AuthHeaders } from "@multicase/portal-api";
import { DocumentType } from "@/repositories/product/model/DocumentType";

class DocumentApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_DOCUMENT != "undefined" && import.meta.env.VITE_APP_API_DOCUMENT
        ? import.meta.env.VITE_APP_API_DOCUMENT + "/documents"
        : `${import.meta.env.VITE_APP_API_URL as string}/documents`;
  }

  public async getDocumentUrl(authHeaders: AuthHeaders, fileName: string): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .get("", { params: { documentName: fileName, documentType: DocumentType.Product } })
      .then(({ data }: AxiosResponse) => data);
  }

  public async upload(authHeaders: AuthHeaders, file: FormData): Promise<Document> {
    return await httpClient(this.uri, authHeaders)
      .postForm("", file, { params: { documentType: DocumentType.Product } })
      .then(({ data }: AxiosResponse) => data);
  }
}

const documentApi = new DocumentApi();
export { documentApi };
