<template>
  <Dialog
    v-model:visible="visible"
    :modal="true"
    :closable="true"
    :style="{ width: '800px' }"
    :draggable="false"
    @hide="onHide"
  >
    <template #header>
      <div class="flex justify-between items-center w-full">
        <div class="text-xl font-semibold">
          {{ t("product.import.edit-product.title") }}
          <span v-if="product" class="text-sm ml-2 text-gray-500">
            ({{ t("product.import.edit-product.row", { row: product.product.rowNumber }) }})
          </span>
        </div>
        <div v-if="hasErrors" class="flex items-center">
          <div class="bg-red-100 text-red-600 px-3 py-1 rounded-md flex items-center gap-2">
            <i class="pi pi-exclamation-circle"></i>
            <span>{{ t("product.import.validation.has-errors") }} {{ numberOfErrorsInProduct }}</span>
          </div>
        </div>
      </div>
    </template>

    <div v-if="product" class="p-4">
      <ProductBasicInfoSection
        :product="editedProduct"
        :sales-unit-options="salesUnitOptions"
        :vat-options="vatOptions"
        :loading-sales-units="loadingSalesUnits"
        :loading-taxes="loadingTaxes"
        :errors="activeSystemErrors"
        @update:product="(val) => (editedProduct = val)"
        @field-changed="removeSystemError"
      />

      <ProductReferencesSection
        :product="editedProduct"
        :product-group-options="productGroupOptions"
        :client-options="clientOptions"
        :warehouse-options="warehouseOptions"
        :supplier-options="supplierOptions"
        :loading-product-groups="loadingProductGroups"
        :loading-clients="loadingClients"
        :loading-warehouses="loadingWarehouses"
        :loading-suppliers="loadingSuppliers"
        :errors="activeSystemErrors"
        @update:product="(val) => (editedProduct = val)"
        @update:client-names="updateClientNames"
        @update:warehouse-names="updateWarehouseNames"
        @update:supplier-name="updateSupplierName"
        @field-changed="removeSystemError"
      />
    </div>

    <template #footer>
      <div class="flex justify-between w-full">
        <div>
          <Button
            icon="pi pi-arrow-left"
            :label="t('product.import.edit-product.previous')"
            class="p-button-secondary"
            :disabled="isSaving"
            @click="navigateToPrevious"
          />
          <Button
            icon="pi pi-arrow-right"
            :icon-pos="'right'"
            :label="t('product.import.edit-product.next')"
            class="p-button-secondary ml-2"
            :disabled="isSaving"
            @click="navigateToNext"
          />
        </div>
        <div>
          <Button
            :label="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-text"
            :disabled="isSaving"
            data-testid="cancel-product-button"
            @click="cancel"
          />
          <Button
            :label="isSaving ? t('common.saving') : t('common.save')"
            icon="pi pi-check"
            :loading="isSaving"
            class="ml-2"
            :disabled="!isDirty || isSaving"
            data-testid="save-product-button"
            @click="save"
          />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useCumulusToast } from "@cumulus/components";
import { useToast } from "primevue/usetoast";
import ProductBasicInfoSection from "./ProductBasicInfoSection.vue";
import ProductReferencesSection from "./ProductReferencesSection.vue";
import type { MappedImportProduct } from "@/product/models/MappedImportProduct";
import type { Reference } from "@/product/models/ImportOptions";
import type { Tax } from "@/repositories/tax/model/Tax";
import type { ProductValidationViewModel } from "@/product/models/ProductValidationViewModel";
import type { ValidationError } from "@/product/models/ValidationError";
import { TaxType } from "@/repositories/tax/model/TaxType";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { useProductImportSessionStore } from "@/product/stores/ProductImportSessionStore";
import { useProductImportReferenceStore } from "@/product/stores/ProductImportReferenceStore";
import useVuelidate from "@vuelidate/core";
import type { VatOption } from "@/product/models/VatOption";

interface ProductGroupOption {
  name: string;
}

const props = defineProps<{
  visible: boolean;
  product: ProductValidationViewModel | null;
  products: ProductValidationViewModel[];
  importId: string;
  errors: ValidationError[];
}>();

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
  (e: "navigate", direction: "next" | "previous"): void;
  (e: "productUpdated", product: MappedImportProduct, navigationDirection?: "next" | "previous"): void;
}>();

const { t } = useI18n();
const sessionStore = useProductImportSessionStore();
const referenceStore = useProductImportReferenceStore();
const toast = useCumulusToast(useToast());

const originalProduct = ref<MappedImportProduct | null>(null);
const editedProduct = ref<MappedImportProduct>({} as MappedImportProduct);
const ignoredSystemErrors = ref(new Set<string>());

const isSaving = ref(false);
const val = useVuelidate();

const clientOptions = ref<Reference[]>([]);
const warehouseOptions = ref<Reference[]>([]);
const salesUnitOptions = ref<Reference[]>([]);
const productGroupOptions = ref<ProductGroupOption[]>([]);
const supplierOptions = ref<Reference[]>([]);
const vatOptions = ref<VatOption[]>([]);

const loadingClients = ref(false);
const loadingWarehouses = ref(false);
const loadingSalesUnits = ref(false);
const loadingProductGroups = ref(false);
const loadingSuppliers = ref(false);
const loadingTaxes = ref(false);

const visible = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

const hasErrors = computed(() => props.product?.hasErrors ?? false);

const numberOfErrorsInProduct = computed(() => {
  return props.product?.errors.length || 0;
});

const isDirty = computed(() => {
  if (!originalProduct.value || !editedProduct.value) return false;
  return !isEqual(originalProduct.value, editedProduct.value);
});

const productErrors = computed(() => {
  if (!props.product) return [];
  return props.errors.filter((e) => e.rowNumber === props.product?.product.rowNumber);
});

const activeSystemErrors = computed(() => {
  return productErrors.value
    .filter((error) => !ignoredSystemErrors.value.has(error.field || ""))
    .map((error) => [error.field || "", error.message || ""] as [string, string]);
});

const removeSystemError = (fieldName: string) => {
  ignoredSystemErrors.value.add(fieldName);
};

const updateClientNames = (clientNames: string[]) => {
  editedProduct.value.clientNames = clientNames;
  removeSystemError("clients");
};

const updateWarehouseNames = (warehouseNames: string[]) => {
  editedProduct.value.warehouseNames = warehouseNames;
  removeSystemError("warehouse");
};

const updateSupplierName = (supplierName: string) => {
  if (!editedProduct.value.supplierPrices || editedProduct.value.supplierPrices.length === 0) {
    editedProduct.value.supplierPrices = [
      {
        supplierName,
        productNumber: "",
        costPrice: null,
        customsPrice: null,
        freightPrice: null,
        netPrice: null,
        currency: "",
      },
    ];
  } else {
    editedProduct.value.supplierPrices[0].supplierName = supplierName;
  }
  removeSystemError("supplierPrices");
  removeSystemError("supplierNames");
};

const loadClients = async (): Promise<void> => {
  loadingClients.value = true;
  try {
    clientOptions.value = await referenceStore.getClients();
  } finally {
    loadingClients.value = false;
  }
};

const loadWarehouses = async (): Promise<void> => {
  loadingWarehouses.value = true;
  try {
    warehouseOptions.value = await referenceStore.getWarehouses();
  } finally {
    loadingWarehouses.value = false;
  }
};

const loadSalesUnits = async (): Promise<void> => {
  loadingSalesUnits.value = true;
  try {
    salesUnitOptions.value = await referenceStore.getAvailableSalesUnits();
  } finally {
    loadingSalesUnits.value = false;
  }
};

const loadProductGroups = async (): Promise<void> => {
  loadingProductGroups.value = true;
  try {
    const groups = await referenceStore.getProductGroups();
    productGroupOptions.value = groups.map((group) => {
      return {
        name: group.names[0]?.text || "",
      };
    });
  } finally {
    loadingProductGroups.value = false;
  }
};

const loadSuppliers = async (): Promise<void> => {
  loadingSuppliers.value = true;
  try {
    supplierOptions.value = await referenceStore.getSuppliers();
  } finally {
    loadingSuppliers.value = false;
  }
};

const loadTaxes = async (): Promise<void> => {
  loadingTaxes.value = true;
  try {
    const taxes = await referenceStore.getAvailableTaxes();

    vatOptions.value = taxes
      .filter((tax) => tax.taxType === TaxType.Outgoing)
      .map((tax: Tax) => ({
        rate: tax.rate,
        display: `${tax.rate}%`,
      }));
  } finally {
    loadingTaxes.value = false;
  }
};

const getAllOptions = async (): Promise<void> => {
  await Promise.all([
    loadClients(),
    loadWarehouses(),
    loadSalesUnits(),
    loadProductGroups(),
    loadSuppliers(),
    loadTaxes(),
  ]);
};

const save = async (): Promise<void> => {
  val.value.$touch();
  await val.value.$validate();

  try {
    if (!props.product) return;

    if (val.value.$error) {
      toast.add({
        severity: "warn",
        summary: t("common.validation-error.summary", { defaultValue: "Validation Error" }),
        detail: t("common.validation-error.detail"),
        closable: true,
        life: 5000,
      });
      return;
    }

    isSaving.value = true;

    await sessionStore.updateProduct(props.importId, props.product.product.rowNumber, editedProduct.value);
    originalProduct.value = cloneDeep(editedProduct.value);

    ignoredSystemErrors.value.clear();

    emit("productUpdated", editedProduct.value);

    toast.add({
      severity: "success",
      summary: t("product.import.product-updated-success-summary"),
      detail: t("product.import.product-updated-success-detail", { productNumber: editedProduct.value.productNumber }),
      life: 3000,
    });
  } finally {
    isSaving.value = false;
  }
};

const navigateToNext = async (): Promise<void> => {
  if (isDirty.value) {
    await save();

    emit("productUpdated", editedProduct.value, "next");
  }

  emit("navigate", "next");
};

const navigateToPrevious = async (): Promise<void> => {
  if (isDirty.value) {
    await save();

    emit("productUpdated", editedProduct.value, "previous");
  }

  emit("navigate", "previous");
};

const cancel = (): void => {
  if (isDirty.value && originalProduct.value) {
    editedProduct.value = cloneDeep(originalProduct.value);
  }
  ignoredSystemErrors.value.clear();
  visible.value = false;
};

const onHide = (): void => {
  if (isDirty.value && originalProduct.value) {
    editedProduct.value = cloneDeep(originalProduct.value);
  }
  ignoredSystemErrors.value.clear();
  visible.value = false;
};

onMounted(async () => {
  if (props.product) {
    originalProduct.value = cloneDeep(props.product.product);
    editedProduct.value = cloneDeep(props.product.product);
    ignoredSystemErrors.value.clear();
  }

  await getAllOptions();

  val.value.$touch();
  await val.value.$validate();
});

watch(
  () => props.product,
  (newProduct) => {
    if (newProduct) {
      originalProduct.value = cloneDeep(newProduct.product);
      editedProduct.value = cloneDeep(newProduct.product);
      ignoredSystemErrors.value.clear();
    }
  },
);
</script>
