<template>
  <div class="mt-8 first:mt-0">
    <p class="text-xl font-bold mb-2">{{ title }}</p>
    <ProductRelationList
      :products="products"
      :relations="relations"
      :relation-types="relationTypes"
      @remove-relation="onRemoveRelation"
      @set-relation-type="onSetRelationType"
    />
  </div>
</template>

<script setup lang="ts">
import { Product } from "@/repositories/product/model/Product";
import { ProductRelation } from "@/repositories/product/model/ProductRelation";
import { ProductRelationType } from "@/repositories/product/model/ProductRelationType";
import ProductRelationList from "@/product/components/ProductRelationList.vue";

defineProps<{
  title: string;
  products: Product[];
  relations: ProductRelation[];
  relationTypes: ProductRelationType[];
}>();

const emit = defineEmits<{
  (e: "remove-relation", relationId: string): void;
  (e: "set-relation-type", relationId: string, relationType: ProductRelationType): void;
}>();

const onRemoveRelation = (relationId: string) => {
  emit("remove-relation", relationId);
};

const onSetRelationType = (relationId: string, relationType: ProductRelationType) => {
  emit("set-relation-type", relationId, relationType);
};
</script>
