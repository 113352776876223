<template>
  <div v-if="loading" class="flex justify-center py-8">
    <ProgressSpinner />
  </div>

  <div v-else>
    <div class="flex flex-col space-y-6 max-w-6xl mx-auto">
      <div class="flex items-center space-x-8 mb-4">
        <div class="flex items-center space-x-2">
          <Checkbox v-model="importOptions.overwriteExisting" :binary="true" inputId="overwrite" />
          <label for="overwrite">{{ t("product.import.options.has-headers") }}</label>
        </div>
        <div class="flex items-center space-x-2">
          <Checkbox v-model="importOptions.createNew" :binary="true" inputId="createNew" />
          <label for="createNew">{{ t("product.import.options.create-new") }}</label>
        </div>
        <div class="flex items-center space-x-2">
          <Checkbox v-model="importOptions.updateEmpty" :binary="true" inputId="updateEmpty" />
          <label for="updateEmpty">{{ t("product.import.options.update-existing") }}</label>
        </div>

        <div class="ml-auto flex items-center space-x-4">
          <div class="flex items-center">
            <span class="mr-2">{{ t("product.import.options.product-identifier") }}</span>
            <Select
              v-model="selectedIdentifier"
              :options="systemFields"
              optionLabel="label"
              :placeholder="t('product.import.options.product-number-placeholder')"
              class="w-48"
            />
          </div>

          <AiSuggestionButton :columns="columns" @suggestions-received="handleSuggestions" />
        </div>
      </div>
    </div>

    <DataTable :value="tableData" class="p-datatable-sm" scrollable scrollHeight="400px" :resizableColumns="true">
      <Column v-for="column in columns" :key="column" :field="column" :style="columnStyles[column]">
        <template #header>
          <div class="column-header flex flex-col gap-2">
            <div class="text-sm text-gray-600 font-medium truncate mb-2">
              {{ column }}
              <span v-if="getConfidenceIcon(column)" class="ml-1" :class="getConfidenceColor(column)">
                <i
                  :class="getConfidenceIcon(column)"
                  :title="t('product.import.ai.confidence-level', { score: getConfidenceScore(column) })"
                />
              </span>
            </div>
            <Select
              v-model="getColumnMapping(column).systemField"
              :options="filteredSystemFields(column)"
              optionLabel="label"
              optionValue="value"
              :placeholder="t('product.import.column-mapping.select-field-placeholder')"
              class="w-full"
              :filter="true"
              :filterPlaceholder="t('product.import.column-mapping.search-placeholder')"
              appendTo="body"
            />
          </div>
        </template>
        <template #body="{ data }">
          <div class="cell-content">{{ data[column] }}</div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { ColumnMapping } from "@/product/models/ColumnMapping";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import AiSuggestionButton from "./AiSuggestionButton.vue";
import { useProductImportStore } from "@/product/api/import/ProductImportStore";
import { storeToRefs } from "pinia";

const columnMappings = defineModel<ColumnMapping[]>("columnMappings", {
  required: true,
});

const props = defineProps<{
  importId?: string;
}>();

const { t } = useI18n();
const store = useProductImportStore();
const { importOptions, systemFields, columns, rows } = storeToRefs(store);

const loading = ref(false);
const selectedIdentifier = ref<string>("productNumber");
const confidenceMappings = ref<Map<string, number>>(new Map());

const initializeColumnMappings = (cols: string[]) => {
  if (!columnMappings.value.length) {
    columnMappings.value = cols.map((col) => ({
      importColumn: col,
      systemField: "",
    }));
  }
};

const initializeData = () => {
  if (store.columns.length && store.rows.length) {
    columns.value = store.columns;
    rows.value = store.rows;
    initializeColumnMappings(store.columns);
    return true;
  }
  return false;
};

const loadPreviewData = async () => {
  if (!props.importId) return;

  try {
    loading.value = true;
    const response = await store.getImportSessionPreview(props.importId);

    columns.value = response.columns;
    rows.value = response.rows;
    initializeColumnMappings(response.columns);
  } finally {
    loading.value = false;
  }
};

watch(
  () => props.importId,
  async (newId) => {
    if (newId && (!store.columns.length || !store.rows.length)) {
      await loadPreviewData();
    }
  },
  { immediate: true },
);

onMounted(async () => {
  await store.getSystemFields();

  if (!props.importId) {
    initializeData();
  }
});

const handleSuggestions = (mappings: ColumnMapping[], confidenceScores: Record<string, number>) => {
  columnMappings.value = mappings;
  confidenceMappings.value = new Map(Object.entries(confidenceScores));
};

const getColumnMapping = (column: string) => {
  let mapping = columnMappings.value.find((m) => m.importColumn === column);
  if (!mapping) {
    mapping = { importColumn: column, systemField: "" };
    columnMappings.value.push(mapping);
  }
  return mapping;
};

const columnStyles = computed(() => {
  return columns.value.reduce(
    (acc, column) => {
      acc[column] = { minWidth: "200px" };
      return acc;
    },
    {} as Record<string, { minWidth: string }>,
  );
});

const tableData = computed(() => {
  if (!columns.value.length || !rows.value.length) return [];

  return rows.value.map((row) => {
    const rowData: Record<string, string> = {};
    columns.value.forEach((col, index) => {
      rowData[col] = row.values[index];
    });
    return rowData;
  });
});

const filteredSystemFields = computed(() => {
  return (currentColumn: string) => {
    const selectedValues = columnMappings.value
      .filter((mapping) => mapping.importColumn !== currentColumn && mapping.systemField !== "")
      .map((mapping) => mapping.systemField);

    return systemFields.value
      .filter((field) => !selectedValues.includes(field.name))
      .map((field) => ({
        ...field,
        label: t(`product.import.system-fields.${field.name.toLowerCase()}`),
        value: field.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };
});

const getConfidenceScore = (column: string): number => {
  return Math.round((confidenceMappings.value.get(column) ?? 0) * 100);
};

const getConfidenceIcon = (column: string) => {
  const confidence = confidenceMappings.value.get(column) ?? 0;
  if (confidence >= 0.8) return "pi pi-check-circle";
  if (confidence >= 0.6) return "pi pi-info-circle";
  return "pi pi-exclamation-circle";
};

const getConfidenceColor = (column: string) => {
  const confidence = confidenceMappings.value.get(column) ?? 0;
  if (confidence >= 0.8) return "text-green-500";
  if (confidence >= 0.6) return "text-yellow-500";
  return "text-gray-400";
};
</script>
