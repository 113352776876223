<template>
  <div>
    <Toolbar @import-product-hierarchy-clicked="importProductHierarchy" />

    <div class="c-product-hierarchy">
      <div class="flex c-max-height">
        <div class="flex-0 w-[26rem]">
          <Card class="h-full mr-4">
            <template #content> <ProductGroupNameList /></template>
          </Card>
        </div>
        <div class="flex-auto">
          <Card class="h-full">
            <template #content><ProductHierarchyList /> </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Toolbar from "../components/Toolbar.vue";

import ProductHierarchyList from "../components/ProductHierarchyList.vue";
import ProductGroupNameList from "../components/ProductGroupNameList.vue";

const importProductHierarchy = () => {
  alert("Missing implementation for import");
};
</script>

<style scoped lang="scss">
.c-product-hierarchy {
  margin: var(--default-content-margin);
  @media (min-width: 992px) {
    margin-top: 0;
    margin-bottom: 2rem;
  }
}

.c-max-height {
  max-height: 90vh;
}
</style>
