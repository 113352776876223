import { ref, onMounted, onUnmounted } from "vue";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useProductImportStore } from "../api/import/ProductImportStore";

export enum ImportStatus {
  Idle = "idle",
  Mapping = "mapping",
  MappingComplete = "mappingComplete",
  Validating = "validating",
  ValidationComplete = "validationComplete",
  Failed = "failed",
}

export interface ImportProgress {
  messageType: string;
  progressPercentage: number;
}

export function useProductImportProgress() {
  const webSocket = ref<WebSocket | null>(null);
  const status = ref<ImportStatus>(ImportStatus.Idle);
  const progressPercentage = ref<number>(0);
  const error = ref<string | null>(null);

  const { getWebPubSubUrl } = useProductImportStore();
  const toast = useCumulusToast(useToast());
  const { t } = useI18n();

  const handleWebSocketMessage = (event: MessageEvent) => {
    try {
      const data = JSON.parse(event.data) as ImportProgress;

      switch (data.messageType) {
        case "StartedMapping":
          status.value = ImportStatus.Mapping;
          progressPercentage.value = 0;
          break;
        case "Mapping":
          status.value = ImportStatus.Mapping;
          progressPercentage.value = data.progressPercentage;
          break;
        case "MappingComplete":
          status.value = ImportStatus.MappingComplete;
          progressPercentage.value = 100;
          break;
        case "StartedValidating":
          status.value = ImportStatus.Validating;
          progressPercentage.value = 0;
          break;
        case "Validating":
          status.value = ImportStatus.Validating;
          progressPercentage.value = data.progressPercentage;
          break;
        case "ValidationComplete":
          status.value = ImportStatus.ValidationComplete;
          progressPercentage.value = 100;
          break;
        case "Failed":
          status.value = ImportStatus.Failed;
          error.value = "Import failed";
          break;
      }
    } catch (err) {
      console.error("Error parsing websocket message:", err);
    }
  };

  const handleWebSocketError = () => {
    toast.add({
      severity: "warn",
      summary: t("product.import.websocket-error"),
      detail: t("product.import.websocket-error-details"),
      life: 5000,
    });
  };

  const connect = async () => {
    try {
      const url = await getWebPubSubUrl();

      if (!url) {
        handleWebSocketError();
        return;
      }

      webSocket.value = new WebSocket(url);
      webSocket.value.onmessage = handleWebSocketMessage;
      webSocket.value.onerror = handleWebSocketError;
    } catch (err) {
      console.error("WebSocket connection error:", err);
      handleWebSocketError();
    }
  };

  const disconnect = () => {
    if (webSocket.value) {
      webSocket.value.close();
      webSocket.value = null;
    }
  };

  onMounted(connect);
  onUnmounted(disconnect);

  return {
    status,
    progressPercentage,
    error,
  };
}
