import { Product } from "@/repositories/product/model/Product";
import { ProductRelation } from "@/repositories/product/model/ProductRelation";
import { useProductStore } from "@/repositories/product/ProductStore";
import cloneDeep from "lodash.clonedeep";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import { useI18n } from "vue-i18n";

export function useProductRelationProductManagement(
  selectedProducts: Ref<Product[]>,
  currentIndex: Ref<number>,
  currentProduct: Ref<Product | null>,
  relations: Ref<ProductRelation[]>,
  initialRelations: Ref<ProductRelation[]>,
  relatedProducts: Ref<Product[]>,
) {
  const { t } = useI18n();
  const { getProduct, getProducts, updateProductRelations } = useProductStore();
  const toast = useCumulusToast(useToast());
  const isSaving = ref(false);

  const loadCurrentProduct = async () => {
    if (!selectedProducts.value.length || currentIndex.value >= selectedProducts.value.length) {
      relations.value = [];
      initialRelations.value = [];
      relatedProducts.value = [];
      return;
    }

    const productId = selectedProducts.value[currentIndex.value].id;
    const product = await getProduct(productId, true);

    if (currentProduct.value) {
      Object.assign(currentProduct.value, product);
    } else {
      currentProduct.value = product;
    }

    relations.value = [...(currentProduct.value?.relations || [])].sort((a, b) =>
      a.relationType.localeCompare(b.relationType),
    );
    initialRelations.value = cloneDeep(relations.value);

    await fetchRelatedProducts();
  };

  const fetchRelatedProducts = async () => {
    const productIds = relations.value.map((relation) => relation.productId);
    const missingProductIds = productIds.filter((id) => !relatedProducts.value.some((p) => p.id === id));

    if (missingProductIds.length) {
      relatedProducts.value.push(...(await getProducts(missingProductIds)));
    }
  };

  const saveRelations = async () => {
    if (!currentProduct.value) return;
    try {
      isSaving.value = true;
      await updateProductRelations(currentProduct.value.id, relations.value);
      currentProduct.value.relations = relations.value;
      initialRelations.value = cloneDeep(relations.value);

      toast.add({
        severity: "success",
        summary: t("product.toast.success-edit.summary"),
        detail: t("product.toast.success-edit.detail", { name: currentProduct.value.name }),
        life: 3000,
      });
    } finally {
      isSaving.value = false;
    }
  };

  return { isSaving, loadCurrentProduct, fetchRelatedProducts, saveRelations };
}
