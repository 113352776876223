<template>
  <FloatLabel variant="on">
    <InputText
      :id="`product-name-${props.languageIso.toLowerCase()}`"
      v-model="name"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('product-info-dialog.name').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      :data-testid="`product-name-${props.languageIso.toLowerCase()}`"
      type="text"
      class="w-full"
      :invalid="val.name.$error"
      autofocus
    />
    <label :for="`product-name-${props.languageIso.toLowerCase()}`">{{ t("product-info-dialog.name") }}</label>
  </FloatLabel>
  <small v-if="val.name.$error" class="p-error" :data-testid="`product-name-error-${props.languageIso.toLowerCase()}`">
    {{ val.name.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{ languageIso: string }>();
const name = defineModel<string>("name", { required: true, default: "" });

const { t } = useI18n();

const rules = {
  name: {
    required,
  },
};

const val = useVuelidate(rules, { name: name });
</script>
