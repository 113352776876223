<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button
        id="btn-cancel"
        data-testid="btn-cancel"
        size="small"
        variant="text"
        @click="router.push({ name: 'product-search', params: { query: '*' } })"
      >
        <i class="pi pi-arrow-left"></i>
        <span class="px-2">{{ t("common.back") }}</span>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const router = useRouter();
const { t } = useI18n();
</script>
