import { ref, computed, watch } from "vue";
import { ProductRelation } from "@/repositories/product/model/ProductRelation";
import { ProductRelationType } from "@/repositories/product/model/ProductRelationType";
import { Product } from "@/repositories/product/model/Product";
import cloneDeep from "lodash.clonedeep";

export function useProductPopupSearchManagement(
  relations: ProductRelation[],
  productId: string,
  relatedProducts: Product[] = [],
) {
  const selectedProducts = ref<Product[]>([]);
  const productRelationTypes = ref(new Map<string, ProductRelationType>());
  const searchResults = ref<Product[]>([]);
  const totalRecords = ref(0);
  const lastSearchTerm = ref<string>("");
  const copyOfSelectedProducts = ref<Product[]>([]);

  const isCurrentProduct = (searchProduct: Product) => searchProduct.id === productId;

  const isAlreadySelected = (searchProduct: Product) => {
    return relations.some((relation) => relation.productId === searchProduct.id);
  };

  const isAlreadyRelated = (searchProduct: Product) => {
    return relatedProducts.some((product) => product.id === searchProduct.id);
  };

  const isSelected = (product: Product) => {
    return selectedProducts.value.some((p) => p.id === product.id);
  };

  const onSearchResultsUpdated = (products: Product[], totalHits: number, searchTerm: string, page: number) => {
    let resultsWithoutSelected: Product[] = [];
    let filteredResults: Product[] = [];

    filteredResults = products.filter((x) => !isCurrentProduct(x) && !isAlreadySelected(x) && !isAlreadyRelated(x));

    if (searchTerm !== lastSearchTerm.value) {
      resultsWithoutSelected = filteredResults.filter(
        (result) => !selectedProducts.value.some((selected) => selected.id === result.id),
      );

      copyOfSelectedProducts.value = cloneDeep(selectedProducts.value);

      searchResults.value = [...selectedProducts.value, ...resultsWithoutSelected];
    } else {
      if (page === 1) {
        resultsWithoutSelected = filteredResults.filter(
          (result) => !copyOfSelectedProducts.value.some((selected) => selected.id === result.id),
        );
        searchResults.value = [...copyOfSelectedProducts.value, ...resultsWithoutSelected];
      } else {
        resultsWithoutSelected = filteredResults.filter(
          (result) => !copyOfSelectedProducts.value.some((selected) => selected.id === result.id),
        );
        searchResults.value = resultsWithoutSelected;
      }
    }

    lastSearchTerm.value = searchTerm;
    totalRecords.value = totalHits;
  };

  const searchResultsComputed = computed(() => {
    return searchResults.value;
  });

  watch(selectedProducts, (newSelection, oldSelection) => {
    if (oldSelection) {
      const oldIds = oldSelection.map((p) => p.id);
      const newlySelected = newSelection.filter((p) => !oldIds.includes(p.id));

      newlySelected.forEach((product) => {
        if (!productRelationTypes.value.has(product.id)) {
          productRelationTypes.value.set(product.id, ProductRelationType.AlternativeCrossSell);
        }
      });

      const newIds = newSelection.map((p) => p.id);
      oldSelection.forEach((product) => {
        if (!newIds.includes(product.id)) {
          productRelationTypes.value.delete(product.id);
        }
      });
    }
  });

  return {
    selectedProducts,
    productRelationTypes,
    searchResults,
    totalRecords,
    searchResultsComputed,
    onSearchResultsUpdated,
    isCurrentProduct,
    isAlreadySelected,
    isSelected,
  };
}
