<template>
  <FloatLabel variant="on">
    <InputText
      id="attribute-name"
      v-model="attributeName"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('attribute.name').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      :disabled="disabled"
      :data-testid="`attribute-name-${languageIso}`"
      class="inputfield w-full"
      autofocus="true"
    />

    <label for="attribute-name">{{ t("attribute.name") }}</label>
  </FloatLabel>
  <small v-if="val.attributeName.$error" class="p-error" data-testid="attribute-name-error">
    {{ val.attributeName.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import useVuelidate from "@vuelidate/core";
import { useI18n } from "vue-i18n";

defineProps<{
  languageIso: string;
  disabled: boolean;
}>();

const attributeName = defineModel<string>("attributeName", {
  required: true,
});

const { t } = useI18n();

const rules = {
  attributeName: {
    required: required,
  },
};

const val = useVuelidate(rules, { attributeName: attributeName });
</script>
