<template>
  <div class="c-product-image-gallery">
    <Galleria
      ref="galleria"
      v-model:activeIndex="activeIndex"
      :value="productImages"
      :numVisible="5"
      :showItemNavigators="true"
      :showThumbnails="true"
      :circular="true"
      :responsiveOptions="responsiveOptions"
    >
      <template #item="slotProps">
        <div class="relative w-full h-[300px]">
          <img
            :src="slotProps.item.url"
            :alt="slotProps.item.name"
            class="w-full h-full object-contain"
            @click="openFullScreen"
          />
        </div>
      </template>

      <template #thumbnail="slotProps">
        <div class="grid gap-4 justify-center">
          <img
            :src="resizeImage(slotProps.item.url, 100, 500)"
            :alt="slotProps.item.alt"
            class="object-cover w-20 h-20"
          />
        </div>
      </template>

      <template #footer>
        <div class="flex align-items-center px-3 bg-zinc-50 rounded-b-lg">
          <div v-if="productImages.length > 0" class="flex items-center gap-4 text-ellipsis overflow-hidden mr-4">
            <span class="text-sm">{{ activeIndex + 1 }}/{{ productImages.length }}</span>
            <span class="font-bold text-sm">{{ productImages[activeIndex].name }}</span>
            <span class="text-sm line-clamp-2">{{ productImages[activeIndex].description }}</span>
          </div>
          <PrimeButton
            :icon="fullScreenIcon"
            class="ml-auto w-10 m-1 text-zinc-900"
            pt:icon:class="!text-2xl"
            text
            @click="openFullScreen"
          />
        </div>
      </template>
    </Galleria>
  </div>

  <FullScreenGalleria
    v-model:isVisible="isFullScreenVisible"
    :images="productImages"
    :initialIndex="activeIndex"
    @close="onFullScreenClose"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { ProductImage } from "@/repositories/product/model/ProductImage";
import { useImageService } from "@/repositories/image/ImageService";
import FullScreenGalleria from "./FullScreenGalleria.vue";

defineProps<{
  productImages: ProductImage[];
}>();

const isFullScreenVisible = ref(false);
const galleria = ref();
const activeIndex = ref(0);
const fullscreenIndex = ref(0);
const { resizeImage } = useImageService();

const fullScreenIcon = computed(() => {
  return `pi ${isFullScreenVisible.value ? "pi-window-minimize" : "pi-window-maximize"}`;
});

const openFullScreen = () => {
  isFullScreenVisible.value = true;
  fullscreenIndex.value = activeIndex.value;
};

const onFullScreenClose = (index: number) => {
  activeIndex.value = index;
};

const responsiveOptions = ref([
  {
    breakpoint: "1300px",
    numVisible: 4,
  },
  {
    breakpoint: "575px",
    numVisible: 1,
  },
]);
</script>

<style scoped lang="scss">
.c-product-image-gallery {
  .p-galleria {
    border: none;
  }

  :deep(.p-galleria-items-container) {
    border-bottom: 1px solid #e5e7eb;
  }

  :deep(.p-galleria-prev-button),
  :deep(.p-galleria-next-button) {
    color: black;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    padding: 0.5rem;
  }
}

.c-fullscreen-galleria {
  width: 100%;

  :deep(.p-galleria-prev-button),
  :deep(.p-galleria-next-button) {
    color: black;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    padding: 0.5rem;
  }
}
</style>
