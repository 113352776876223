export const ProductLinesColumns = {
  SelectColumn: "selectColumn",
  ProductImages: "productImages",
  ProductNumber: "productNumber",
  Gtin: "gtin",
  Name: "name",
  Description: "description",
  ProductInfo: "productInfo",
  ActiveOnWebshop: "activeOnWebshop",
} as const;
