<template>
  <div class="mb-10">
    <FloatLabel variant="on">
      <Select
        id="ai-version"
        v-model="selectedAiVersion"
        :options="dropdownOptions"
        data-testid="ai-version"
        optionLabel="name"
        optionValue="value"
        class="inputfield w-full md:w-80"
      />
      <label for="ai-version">{{ t("product.ai-search.label") }}</label>
    </FloatLabel>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { Options } from "@/repositories/product/model/Options";

import { AIVersions } from "@/models/product-info/AIVersions";
const { t } = useI18n();
const productAiVersions = ref<Options[]>([]);
const languageKey = "product.ai-search.";

const selectedAiVersion = defineModel<string>("version");

const fetchAiVersion = () => {
  for (const prAiVersion in AIVersions) {
    productAiVersions.value.push({
      name: t(`${languageKey}${prAiVersion.toString().toLowerCase()}`),
      code: prAiVersion.toString(),
    });
  }

  if (
    (selectedAiVersion.value === null || selectedAiVersion.value === undefined) &&
    productAiVersions.value.length > 0
  ) {
    selectedAiVersion.value = AIVersions.OpenAIGPT4o_mini;
  }
};

const dropdownOptions = computed(() => {
  return productAiVersions.value.map((productAiVersion) => {
    return { name: productAiVersion.name, value: productAiVersion.code };
  });
});

onMounted(fetchAiVersion);
</script>
