import { useToast } from "primevue/usetoast";
import { useCumulusToast, useUnsavedChanges } from "@cumulus/components";
import useValidate from "@vuelidate/core";
import { ShortcutAction, useShortcut } from "@cumulus/components";
import { Product } from "@/repositories/product/model/Product";
import { ProductImage } from "@/repositories/product/model/ProductImage";
import { type Supplier } from "@/repositories/supplier/model/Supplier";
import { ProductStatus as ProductState } from "@/repositories/product/model/ProductStatus";
import { usePriceFunctions } from "@/product/PriceFunctions";
import { useSupplier } from "@/repositories/supplier/SupplierService";
import { useProductStore } from "@/repositories/product/ProductStore";
import { usePriceGroup } from "@/repositories/price-group/PriceGroupService";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { Currency } from "@/repositories/currency/model/Currency";
import { useCurrencyService } from "@/repositories/currency/CurrencyService";
import { Client as ClientModel } from "@/repositories/client/model/Client";
import { useClient } from "@/repositories/client/ClientService";
import { useAuth } from "@cumulus/event-bus";
import { Price as PriceModel } from "@/repositories/product/model/Price";
import { paramsToObject, timeout } from "@/utils/helperFunctions";

export function useProductManager() {
  const { t } = useI18n();
  const toast = useCumulusToast(useToast());
  const route = useRoute();
  const router = useRouter();
  const productId = ref("");
  const suppliers = ref<Supplier[]>([]);
  const product = ref<Product>(new Product());
  const currencies = ref<Currency[]>([]);
  const userClient = ref<ClientModel>(new ClientModel());

  const imagesToDelete: ProductImage[] = [];
  const isLoading = ref(false);
  const isSaving = ref(false);
  const loadFailed = ref(false);
  const initialProduct = ref<Product | null>(null);
  const errorReason = ref("");
  const { defaultOutgoingVatPercentage } = usePriceFunctions();
  const { getAllSuppliers } = useSupplier();
  const { getProduct, updateProduct, deleteProduct } = useProductStore();
  const { getDefaultPriceGroupByUsersClient } = usePriceGroup();
  const confirmedDiscard = ref(false);
  const { getAllCurrencies } = useCurrencyService();
  const { getClient } = useClient();
  const { getAuthHeaders } = useAuth();

  const routeToProductList = async () => {
    confirmedDiscard.value = true;
    if (window.history.state.back === null || window.history.state.back.indexOf("/product/search") === -1) {
      router.push({ name: "product-search", query: { search: "" } });
    } else {
      const url = new URL(window.location.origin + window.history.state.back);
      url.searchParams.set("focusResult", product.value.id);
      router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
    }
  };

  //Nested validation
  const validate = useValidate();

  const fetchProduct = async () => {
    productId.value = route.params.id as string;
    product.value = await getProduct(productId.value);
    if (product.value) {
      if (product.value.altProductNumber === undefined) {
        product.value.altProductNumber = "";
      }
    }
  };

  const getPrice = async (): Promise<PriceModel> => {
    const defaultPrice = await getDefaultPriceGroupByUsersClient();

    const price = new PriceModel();
    price.priceGroupId = defaultPrice.id;
    price.priceGroupName = defaultPrice.name;
    price.currencyIso = defaultPrice.currencyIso;

    return price;
  };

  const getSuppliers = async () => {
    suppliers.value = await getAllSuppliers();
  };

  const getCurrencies = async () => {
    currencies.value = await getAllCurrencies();
  };

  const getUserClient = async () => {
    const authHeaders = await getAuthHeaders();
    const clientId = authHeaders.clientId;
    userClient.value = await getClient(clientId);
  };

  onMounted(async () => {
    isLoading.value = true;
    Promise.all([getPrice(), fetchProduct(), getSuppliers(), getUserClient(), getCurrencies()])
      .then((values) => {
        if (product.value.prices.length === 0) {
          product.value.prices.push(values[0]);
        }

        nextTick(() => {
          // Wait for auto-select first option in dropdowns etc
          initialProduct.value = cloneDeep(product.value);
        });
      })
      .finally(() => (isLoading.value = false));
  });

  const saveProduct = async () => {
    //Wait for onblur functions
    await timeout(300);

    validate.value.$touch();

    if (!(await validate.value.$validate())) {
      toast.add({
        severity: "warn",
        summary: t("common.validation-error.summary"),
        detail: t("common.validation-error.detail"),
        closable: true,
      });
      return;
    }

    isSaving.value = true;

    await updateProduct(product.value, imagesToDelete);
    initialProduct.value = cloneDeep(product.value);

    toast.add({
      severity: "success",
      summary: t("product.toast.success-edit.summary"),
      detail: t("product.toast.success-edit.detail", { name: product.value.name }),
      closable: true,
    });

    routeToProductList();
  };

  const onDeleteProduct = async () => {
    if (product.value.productStatus === ProductState.Active) {
      toast.add({
        severity: "warn",
        summary: t("product.active-error.header"),
        detail: t("product.active-error.detail"),
        life: 5000,
      });
      return;
    }

    await deleteProduct(product.value.id);

    toast.add({
      severity: "success",
      summary: t("product.toast.success-delete.summary"),
      detail: t("product.toast.success-delete.detail", { name: product.value.name }),
      closable: true,
    });

    routeToProductList();
  };

  const setFocusProductStatus = ref(false);

  const hasUpdates = computed(() => {
    return !isEqual(product.value, initialProduct.value);
  });

  useShortcut(ShortcutAction.save, saveProduct);

  const reload = () => {
    window.location.reload();
  };

  useUnsavedChanges(hasUpdates);

  return {
    reload,
    setFocusProductStatus,
    onDeleteProduct,
    defaultOutgoingVatPercentage,
    errorReason,
    loadFailed,
    isSaving,
    product,
    saveProduct,
    hasUpdates,
    suppliers,
    userClient,
    currencies,
    isLoading,
  };
}
