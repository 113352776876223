<template>
  <Dialog
    v-model:visible="visible"
    :header="t('product.relations.menu-title')"
    :modal="true"
    :closable="true"
    class="p-fluid w-[90vw] max-w-[1200px]"
  >
    <div class="flex flex-col h-full">
      <ProductPopupDialogNavigation
        :current-product="currentProduct"
        :current-index="currentIndex"
        :total-products="selectedProducts.length"
        @previous="previous"
        @next="next"
      />
      <div v-if="isSaving" class="flex justify-center items-center py-4 text-gray-600">
        <i class="pi pi-spin pi-spinner text-primary text-2xl mr-2"></i>
        <span>{{ t("common.saving") }}...</span>
      </div>
      <div v-if="currentProduct" class="flex flex-col gap-4">
        <Card class="h-full">
          <template #content>
            <div class="flex items-center gap-2 mb-6">
              <div class="flex-1 text-color font-semibold leading-6">
                <Button
                  id="btn-commit"
                  class="flex items-center justify-center rounded-full border border-gray-300 hover:bg-primary-50 mr-4"
                  data-testid="btn-commit"
                  :disabled="isSaving"
                  @click="showAddDialog = true"
                >
                  <i class="pi pi-plus text-green-600 mr-2"></i>
                  <span class="px-4 font-normal">{{ t("product.relations.button-add") }}</span>
                </Button>
              </div>
            </div>

            <ProductRelationSection
              v-for="section in relationSections"
              :key="section.title"
              :title="t(section.title)"
              :products="relatedProducts"
              :relations="relations"
              :relation-types="section.relationTypes"
              @remove-relation="removeRelation"
              @set-relation-type="setRelationType"
            />
          </template>
        </Card>
      </div>
      <div v-else class="flex justify-center items-center py-8">
        <div class="text-center">
          <i v-if="loading" class="pi pi-spin pi-spinner text-primary text-4xl mb-3"></i>
          <template v-else>
            <i class="pi pi-exclamation-triangle text-yellow-500 text-4xl mb-3"></i>
            <p class="text-gray-600">{{ t("product.no-products-available") }}</p>
          </template>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button :label="t('common.close')" icon="pi pi-times" variant="outlined" @click="close" />
        <Button
          :label="t('common.save')"
          icon="pi pi-save"
          :disabled="!hasUpdates || isSaving"
          @click="saveRelations"
        />
      </div>
    </template>

    <ProductRelationAddDialog
      v-model:visible="showAddDialog"
      :relations="relations"
      :product-id="currentProduct?.id ?? ''"
      :related-products="relatedProducts"
      :disabled="isSaving"
      @add-relations="addRelations"
    />
  </Dialog>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, watch, ref } from "vue";
import { Product } from "@/repositories/product/model/Product";
import { ProductRelationType } from "@/repositories/product/model/ProductRelationType";
import ProductRelationAddDialog from "./ProductPopupRelationAddDialog.vue";
import ProductPopupDialogNavigation from "../ProductPopupDialogNavigation.vue";
import ProductRelationSection from "./ProductRelationSection.vue";
import { useProductSearchNavigation } from "@/product/composables/useProductSearchNavigation";
import { useProductRelationManagement } from "@/product/composables/product-relations/useProductRelationManagement";
import { useProductRelationProductManagement } from "@/product/composables/product-relations/useProductRelationProductManagement";
import { useUnsavedChanges } from "@cumulus/components";
import isEqual from "lodash.isequal";

const relationSections = ref<{ title: string; relationTypes: ProductRelationType[] }[]>([
  {
    title: "product.relations.header-alternatives",
    relationTypes: [
      ProductRelationType.AlternativeCrossSell,
      ProductRelationType.AlternativeDownSell,
      ProductRelationType.AlternativeUpSell,
    ],
  },
  {
    title: "product.relations.header-accessories",
    relationTypes: [ProductRelationType.Accessory],
  },
  {
    title: "product.relations.header-accessory-to",
    relationTypes: [ProductRelationType.AccessoryTo],
  },
]);

const props = defineProps<{ selectedProducts: Product[] }>();

const visible = defineModel<boolean>("visible", { required: true });
const { t } = useI18n();

const relations = ref([]);
const initialRelations = ref([]);
const relatedProducts = ref([]);
const showAddDialog = ref(false);
const loading = ref(false);

const hasUpdates = computed(() => !isEqual(relations.value, initialRelations.value));
const { confirmClose } = useUnsavedChanges(hasUpdates);

// TODO: use allProductsRef for the moment selectedProductsRef has all or selected products add this just to use same useProductSearchNavigation compose
const allProductsRef = ref([]);

const selectedProductsRef = computed(() => props.selectedProducts);

const {
  currentIndex,
  currentProduct,
  next: nextProduct,
  previous: previousProduct,
  resetCurrentIndex,
} = useProductSearchNavigation(allProductsRef, selectedProductsRef);

const { isSaving, loadCurrentProduct, fetchRelatedProducts, saveRelations } = useProductRelationProductManagement(
  selectedProductsRef,
  currentIndex,
  currentProduct,
  relations,
  initialRelations,
  relatedProducts,
);

const next = () => {
  if (confirmClose()) {
    nextProduct();
    loadCurrentProduct();
  }
};

const previous = () => {
  if (confirmClose()) {
    previousProduct();
    loadCurrentProduct();
  }
};

const close = async () => {
  if (confirmClose()) {
    visible.value = false;
    resetData();
  }
};

const resetData = () => {
  resetCurrentIndex();
  relations.value = [];
  initialRelations.value = [];
  relatedProducts.value = [];
};

const { addRelations, removeRelation, setRelationType } = useProductRelationManagement({
  relations,
  fetchRelatedProducts,
});

watch(visible, async (newValue) => {
  if (newValue && props.selectedProducts.length > 0) {
    loading.value = true;
    try {
      await loadCurrentProduct();
    } finally {
      loading.value = false;
    }
  } else if (!newValue) {
    close();
  }
});
</script>
