<template>
  <div class="p-6">
    <div class="flex justify-between items-center mb-6">
      <div class="flex items-center gap-4">
        <h1 class="text-2xl font-semibold">{{ t("product.import.import-history") }}</h1>
      </div>
      <PrimeButton
        :label="t('product.import.new-import')"
        icon="pi pi-plus"
        @click="
          router.push({
            name: 'product-import',
          })
        "
      />
    </div>

    <div class="card">
      <DataTable
        :value="importSessions"
        :loading="loading"
        :paginator="true"
        :rows="pageSize"
        :total-records="totalRecords"
        :lazy="true"
        :sort-field="sortField"
        :sort-order="sortOrder"
        class="p-datatable-sm"
        @page="onPage($event)"
        @sort="onSort($event)"
      >
        <Column field="originalFileName" :header="t('product.import.filename')" :sortable="true" />
        <Column field="createdAt" :header="t('product.import.created')" :sortable="true">
          <template #body="{ data }">
            {{ d(data.createdAt) }}
          </template>
        </Column>

        <Column field="totalRows" :header="t('product.import.total-rows')" :sortable="true" />
        <Column field="status" :header="t('product.import.status')" :sortable="false">
          <template #body="{ data }">
            <StatusBadge :status="data.status" />
          </template>
        </Column>
        <Column :header="t('product.import.actions')" :sortable="false" style="width: 100px">
          <template #body="{ data }">
            <PrimeButton
              icon="pi pi-eye"
              class="p-button-text p-button-sm"
              :tooltip="t('common.view')"
              @click="viewImport(data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useProductImportStore } from "@/product/api/import/ProductImportStore";
import StatusBadge from "@/product/components/import/StatusBadge.vue";
import { type DataTableSortEvent } from "primevue";
import { type ProductImportSessionSummary } from "../models/ProductImportSessionSummary";

const { t, d } = useI18n();
const router = useRouter();
const store = useProductImportStore();

const importSessions = ref<ProductImportSessionSummary[]>([]);
const loading = ref(true);
const totalRecords = ref(0);
const pageSize = ref(25);
const currentPage = ref(1);
const sortField = ref("createdAt");
const sortOrder = ref(-1);

const loadData = async () => {
  try {
    loading.value = true;
    const response = await store.getImportSessions(
      currentPage.value,
      pageSize.value,
      sortField.value,
      sortOrder.value === 1 ? "asc" : "desc",
    );
    importSessions.value = response.sessionSummaries;
    totalRecords.value = response.totalCount;
  } catch (error) {
    console.error("Error loading import sessions:", error);
  } finally {
    loading.value = false;
  }
};

const onPage = (event: { page: number; rows: number }) => {
  currentPage.value = event.page + 1;
  pageSize.value = event.rows;
  loadData();
};

const onSort = (event: DataTableSortEvent) => {
  sortField.value = typeof event.sortField === "string" ? event.sortField : "createdAt";
  sortOrder.value = event.sortOrder ?? -1;
  loadData();
};

const viewImport = (session: ProductImportSessionSummary) => {
  let step = "1";
  switch (session.status) {
    case "FileUploaded":
    case "Created":
    case "MappingStarted":
    case "MappingCompleted":
      step = "2";
      break;
    case "Validating":
    case "ValidationComplete":
    case "Processing":
    case "Completed":
      step = "3";
      break;
  }

  router.push({
    name: "product-import",
    params: { id: session.id, step },
  });
};

onMounted(loadData);
</script>
