<template>
  <Dialog
    v-model:visible="visible"
    :modal="true"
    :header="t(`product.manufacturer.label`)"
    :style="{ width: '90vw', maxWidth: '700px', height: 'auto', maxHeight: '90%' }"
    baze-z-index="1000"
    @keydown.escape.stop="visible = false"
  >
    <div class="grid grid-cols-12 gap-4 !text-lg mx-6">
      <div v-if="!editMode && !isNew" class="col-span-12 text-surface-500 dark:text-surface-400">
        {{ t("product.manufacturer.info") }}
      </div>

      <div v-if="!editMode && !isNew" class="col-span-12 mt-4">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-8">
            <CumulusSelect
              v-model="selectedValue"
              :options="manufacturers"
              :label="t('product.manufacturer.label')"
              size="small"
              :autofocus="true"
              :required="true"
              :disabled="saving"
              :loading="loading"
              :default-select-first="true"
            />
          </div>

          <div class="col-span-2">
            <Button
              :label="t('common.change')"
              class="w-full"
              variant="outlined"
              size="small"
              :disabled="saving"
              @click="onChangeClick"
            />
          </div>
          <div class="col-span-2">
            <Button
              :label="t('common.delete')"
              class="w-full"
              variant="outlined"
              size="small"
              :disabled="saving"
              @click="onDeleteClick"
            />
          </div>

          <div class="col-span-12">
            <Button
              v-if="!editMode"
              :label="t('product.manufacturer.create')"
              text
              size="small"
              icon="pi pi-plus"
              :disabled="saving"
              @click="onCreateClick"
            />
          </div>
        </div>
      </div>

      <div v-else class="col-span-12">
        <div v-if="isNew" class="grid grid-cols-1 gap-4">
          <h2>{{ t("product.manufacturer.create") }}</h2>
          <CumulusInputText
            v-model="addManufacturerRequest.name"
            :label="t('product.manufacturer.name')"
            class="w-full"
            :autofocus="isNew"
            :focus="isNew"
            :required="true"
            :disabled="saving"
          />

          <CumulusInputText
            v-model="addManufacturerRequest.description"
            :label="t('product.manufacturer.description')"
            class="w-full"
            :disabled="saving"
          />

          <CumulusInputText
            v-model="addManufacturerRequest.websiteUrl"
            :label="t('product.manufacturer.website-url')"
            class="w-full"
            :disabled="saving"
          />

          <ManufacturerLogo v-model:logo="addManufacturerRequest.logo" />
        </div>
        <div v-else class="grid grid-cols-1 gap-4">
          <h2>{{ t("product.manufacturer.rename") }}</h2>
          <CumulusInputText
            v-model="manufacturer.name"
            :label="t('product.manufacturer.name')"
            class="w-full"
            :autofocus="editMode"
            :focus="editMode"
            :required="true"
            :disabled="saving"
          />

          <CumulusInputText
            v-model="manufacturer.description"
            :label="t('product.manufacturer.description')"
            class="w-full"
            :disabled="saving"
          />

          <CumulusInputText
            v-model="manufacturer.websiteUrl"
            :label="t('product.manufacturer.website-url')"
            class="w-full"
            :disabled="saving"
          />

          <CumulusSelect
            v-model="manufacturer.manufacturerStatus"
            :options="manufacturerStatus"
            :label="t('product.manufacturer.active')"
            size="small"
            :autofocus="true"
            :required="true"
            :disabled="saving"
            :loading="loading"
            :default-select-first="true"
          />

          <ManufacturerLogo v-model:logo="manufacturer.logo" />
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-center gap-4 mt-4">
        <Button
          v-if="editMode || isNew"
          :label="t('common.save')"
          class="min-w-32"
          severity="primary"
          @click="onSave"
        />

        <Button
          v-if="editMode || isNew"
          :label="t('common.discard')"
          class="inline-block min-w-32 ml-4"
          variant="outlined"
          size="small"
          :disabled="saving"
          @click="resetDialog"
        />

        <Button
          v-if="!editMode && !isNew"
          :label="t('common.close')"
          severity="secondary"
          class="min-w-32"
          variant="outlined"
          :disabled="saving"
          @click="visible = false"
        />
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { useConfirm } from "primevue/useconfirm";
import { Manufacturer, ManufacturerStatus, AddManufacturerRequest } from "@multicase/portal-api/manufacturer";
import cloneDeep from "lodash.clonedeep";

const visible = defineModel<boolean>("visible", { required: true });
const selectedValue = defineModel<string>({ required: true, default: "" });
const addManufacturerRequest = ref<AddManufacturerRequest>(new AddManufacturerRequest());
const manufacturer = ref<Manufacturer>(new Manufacturer());
const isNew = ref<boolean>(false);
const editMode = ref<boolean>(false);

const { manufacturers, createManufacturer, updateManufacturer, deleteManufacturer, loading, saving } =
  useManufacturers();

const emit = defineEmits<{ (e: "update", value: Manufacturer[]): void }>();
const { t } = useI18n();

const resetDialog = () => {
  editMode.value = false;
  isNew.value = false;
};

const onSave = async () => {
  if (isNew.value) {
    const id = await createManufacturer(addManufacturerRequest.value);
    if (id === "") {
      return; //Failed to create
    }
    selectedValue.value = id;
    resetDialog();
    emit("update", manufacturers.value);
    return;
  }

  if (!(await updateManufacturer(manufacturer.value))) {
    return; //Failed to update
  }
  selectedValue.value = manufacturer.value.id;
  resetDialog();
  emit("update", manufacturers.value);
};

const confirm = useConfirm();

const onDeleteClick = (event: Event) => {
  if (selectedValue.value === "") {
    return;
  }

  confirm.require({
    target: event.currentTarget as HTMLElement,
    group: "confirm-popup",
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: async () => {
      if (await deleteManufacturer(selectedValue.value)) {
        if (manufacturers.value.length > 0) {
          selectedValue.value = manufacturers.value[0].id;
        }
        resetDialog();
        emit("update", manufacturers.value);
      }
    },
  });
};

const onCreateClick = () => {
  isNew.value = true;
  addManufacturerRequest.value = new AddManufacturerRequest();
};

const onChangeClick = () => {
  if (selectedValue.value === "") {
    return;
  }

  const index = manufacturers.value.findIndex((option) => option.id === selectedValue.value);
  if (index === -1) {
    return;
  }

  const item = manufacturers.value[index];
  manufacturer.value = cloneDeep(item);
  editMode.value = true;
};

const manufacturerStatus = computed(() => {
  return (Object.keys(ManufacturerStatus) as Array<keyof typeof ManufacturerStatus>).map((status) => ({
    id: status,
    name: t(`product.manufacturer.${status.toLowerCase()}`),
  }));
});
</script>
