<template>
  <PrimeButton
    :label="t('common.add')"
    icon="pi pi-plus"
    severity="secondary"
    size="small"
    class="w-full h-full rounded-lg border-2 border-dashed border-gray-200 flex flex-col hover:bg-gray-50 hover:border-gray-300 disabled:opacity-50 disabled:cursor-not-allowed group"
    @click="emit('addAttributeClicked')"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const emit = defineEmits<{
  (e: "addAttributeClicked"): void;
}>();

const { t } = useI18n();
</script>
