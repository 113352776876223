<template>
  <label for="attribute-type">{{ t("attribute.type") }}</label>
  <Select
    id="attribute-type"
    v-model="attributeType"
    class="inputfield w-full"
    :options="Object.keys(AttributeType)"
    :data-testid="`attribute-type-${languageIso}`"
    :pt:list:data-testid="`attribute-type-list-${languageIso}`"
    @show="isActive = true"
    @hide="isActive = false"
    @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
  />
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { AttributeType } from "@/models/attribute/AttributeType";
import { LanguageIsoType } from "@/models/language/LanguageIsoType";

defineProps<{
  languageIso: LanguageIsoType;
}>();

const attributeType = defineModel<AttributeType>("attributeType", {
  required: true,
});

const { t } = useI18n();

const isActive = ref(false);
</script>
