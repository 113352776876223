<template>
  <Card>
    <template #header>
      <div class="flex justify-between items-center py-2 border-b mx-2">
        <div class="flex items-center">
          <PrimeButton
            data-testid="previous-product"
            severity="secondary"
            variant="text"
            size="small"
            @click="emit('previousProduct')"
            :disabled="props.productIndex === 0"
            v-tooltip.bottom="{
              value: t('product-info-dialog.previous'),
              showDelay: 1000,
              hideDelay: 300,
            }"
          >
            <div class="inline-block material-symbols-outlined">arrow_back</div>
          </PrimeButton>
          <PrimeButton
            data-testid="next-product"
            severity="secondary"
            variant="text"
            size="small"
            @click="emit('nextProduct')"
            :disabled="props.productIndex === props.maxProductIndex"
            v-tooltip.bottom="{
              value: t('product-info-dialog.next'),
              showDelay: 1000,
              hideDelay: 300,
            }"
          >
            <div class="inline-block material-symbols-outlined">arrow_forward</div>
          </PrimeButton>
        </div>
        <div class="pt-2 font-bold whitespace-nowrap">
          <FloatLabel variant="on" class="inline-block">
            <Select
              id="info-language"
              data-testid="language"
              pt:list:data-testid="language-list"
              class="w-full"
              :options="props.languages"
              optionLabel="name"
              optionValue="code"
              v-model="selectedLanguage"
              v-tooltip.bottom="{
                value: t('placeholder.select', { property: t('product-info-dialog.language-selector').toLowerCase() }),
                showDelay: 1000,
                hideDelay: 300,
              }"
            >
              <template #option="slotProps">
                <div class="flex items-center">
                  <img
                    src="@/assets/flag_placeholder.png"
                    :class="'flag flag-' + flaggConvertion(slotProps.option.code.toLowerCase())"
                    width="16"
                  />
                  <div class="ml-4">{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Select>
            <label for="info-language">
              {{ t(`product-info-dialog.language-selector`) }}
            </label>
          </FloatLabel>
        </div>
        <div class="flex items-center gap-2">
          <PrimeButton
            data-testid="slitt-view"
            severity="secondary"
            variant="text"
            size="small"
            @click="emit('toggleSplitView')"
          >
            <span v-if="hasSplitView" class="material-symbols-outlined">
              {{ showSplitView ? "left_panel_close" : "left_panel_open" }}
            </span>

            <span v-else class="material-symbols-outlined"> close_small </span>
          </PrimeButton>
        </div>
      </div>
    </template>
    <template #content>
      <div class="grid grid-cols-12 mx-4">
        <div class="col-span-12 mb-2 mt-4">
          <ProductName v-model:name="activeProductText.name" :languageIso="selectedLanguage" />
        </div>

        <div class="col-span-12 mb-2 mt-4">
          <ProductDescription v-model:description="activeProductText.description" :languageIso="selectedLanguage" />
        </div>

        <div class="col-span-12 mb-2 mt-4">
          <ProductInformation v-model:information="activeProductText.productInfo" :languageIso="selectedLanguage" />
        </div>

        <div class="col-span-12 mb-2 mt-4">
          <ProductTitleTag v-model:titleTag="activeProductText.titleTag" :languageIso="selectedLanguage" />
        </div>

        <div class="col-span-12 mb-2 mt-4">
          <ProductMetaDescription
            v-model:metaDescription="activeProductText.metaDescription"
            :languageIso="selectedLanguage"
          />
        </div>
      </div>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { LanguageIsoType } from "@/models/language/LanguageIsoType";
import ProductName from "./ProductName.vue";
import ProductDescription from "./ProductDescription.vue";
import ProductInformation from "./ProductInformation.vue";
import ProductTitleTag from "./ProductTitleTag.vue";
import ProductMetaDescription from "./ProductMetaDescription.vue";
import { ProductText } from "@/product/models/ProductText";
import { LanguageViewModel } from "@/product/models/LanguageViewModel";
import { ref, computed } from "vue";
import { ProductLanguageText } from "@/product/models/ProductLanguageText";

const { t } = useI18n();

const props = defineProps<{
  languages: LanguageViewModel[];
  hasSplitView: boolean;
  showSplitView: boolean;
  productIndex: number;
  maxProductIndex: number;
}>();

const emit = defineEmits<{ (e: "toggleSplitView"): void; (e: "previousProduct"): void; (e: "nextProduct"): void }>();
const productLanguageText = defineModel<ProductLanguageText>("productLanguageText", { required: true });

//TODO: Get company default language as default
const selectedLanguage = ref<LanguageIsoType>(productLanguageText.value.defualtLanguage);

const activeProductText = computed(() => {
  return (
    productLanguageText.value.productText.find((x) => x.languageIso === selectedLanguage.value) ?? new ProductText()
  );
});

const flaggConvertion = (language: string) => {
  switch (language) {
    case "en":
      return "gb";
  }
  return language;
};
</script>
