import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { Attribute } from "@/models/attribute/Attribute";
import { AttributeValueByLanguage } from "@/models/attribute/AttributeValueByLanguage";
import { LanguageText } from "@/models/language/LanguageText";
import { AttributeValue } from "@/models/attribute/AttributeValue";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import { useProductLanguageFunctions } from "../../utils/productLanguageFunctions";
import { useProductLanguageStore } from "@/stores/ProductLanguageStore";
import { useAiAttributeTranslation } from "../composables/useAiAttributeTranslation";

export const useAddAttributeStore = defineStore("addAttributeStore", () => {
  const attribute = ref<Attribute>(new Attribute());
  const initialAttribute = ref<Attribute>(cloneDeep(attribute.value));
  const { languages } = useProductLanguageFunctions();

  const aiAttributeTranslation = useAiAttributeTranslation();
  const { translateAll } = aiAttributeTranslation;

  const productLanguageStore = useProductLanguageStore();
  const { defaultLanguage } = storeToRefs(productLanguageStore);

  const isAttributeModified = computed(() => {
    return !isEqual(attribute.value, initialAttribute.value);
  });

  const attributeNamesByLanguage = ref<Record<string, string>>({});

  const attributeValuesByLanguage = ref<Record<string, AttributeValueByLanguage[]>>({});

  const languageModified = ref<Record<string, boolean>>({});

  // check and updates the keys of the existing records
  const updateRecordKeys = (key: string) => {
    if (attributeNamesByLanguage.value[key] === undefined || attributeNamesByLanguage.value[key] === null)
      attributeNamesByLanguage.value[key] = "";
    if (attributeValuesByLanguage.value[key] === undefined || attributeValuesByLanguage.value[key] === null)
      attributeValuesByLanguage.value[key] = [];
    if (languageModified.value[key] === undefined || languageModified.value[key] === null)
      languageModified.value[key] = false;
  };

  const setAttributeName = (
    language: string,
    value: string,
    shouldCopy: boolean = true,
    setModified: boolean = true,
  ) => {
    attributeNamesByLanguage.value[language] = value;
    const index = attribute.value.names.findIndex((n) => n.languageIso === language);
    if (index !== -1) {
      attribute.value.names[index].text = value;
    } else {
      attribute.value.names.push(new LanguageText(language, value));
    }
    if (setModified) languageModified.value[language] = true;
    if (shouldCopy) copyLanguageName(language);
  };

  const setAttributeValue = (
    language: string,
    newValue: AttributeValueByLanguage,
    shouldCopy: boolean = true,
    setModified: boolean = true,
  ) => {
    updateRecordKeys(language);

    const values = attributeValuesByLanguage.value[language];
    const index = values.findIndex((v) => v.id === newValue.id);
    if (index !== -1) {
      values[index] = newValue;
    } else {
      values.push(newValue);
    }

    let attributeValue = attribute.value.values.find((v) => v.id === newValue.id);
    if (attributeValue) {
      attributeValue.setValueForLanguage(language, newValue.value);
      attributeValue.setDescriptionForLanguage(language, newValue.description);
      attributeValue.additionalProperties = newValue.additionalProperties;
    } else {
      const newAttributeValue = new AttributeValue();
      newAttributeValue.id = newValue.id;
      newAttributeValue.setValueForLanguage(language, newValue.value);
      newAttributeValue.setDescriptionForLanguage(language, newValue.description);
      newAttributeValue.additionalProperties = newValue.additionalProperties;
      attribute.value.values.push(newAttributeValue);

      attributeValue = newAttributeValue;
    }
    if (setModified) languageModified.value[language] = true;
    setAdditionalPropertiesForAllLanguages(newValue);

    if (shouldCopy) copyLanguageValues(language, attributeValue.id);
  };

  const setAdditionalPropertiesForAllLanguages = (newValue: AttributeValueByLanguage) => {
    languages.forEach((lang) => {
      updateRecordKeys(lang.code);

      const values = attributeValuesByLanguage.value[lang.code];
      const index = values.findIndex((v) => v.id === newValue.id);
      if (index !== -1) {
        values[index].additionalProperties = newValue.additionalProperties;
      } else {
        values.push(newValue);
      }

      const attributeValue = attribute.value.values.find((v) => v.id === newValue.id);
      if (attributeValue) {
        attributeValue.additionalProperties = newValue.additionalProperties;
      } else {
        const newAttributeValue = new AttributeValue();
        newAttributeValue.id = newValue.id;
        newAttributeValue.additionalProperties = newValue.additionalProperties;
        attribute.value.values.push(newAttributeValue);
      }
    });
  };

  const addNewAttributeValue = () => {
    const newValue = new AttributeValue();
    attribute.value.values.push(newValue);
    languages.forEach((lang) => {
      updateRecordKeys(lang.code);

      attributeValuesByLanguage.value[lang.code].push({
        id: newValue.id,
        value: "",
        description: "",
        additionalProperties: {},
      });
    });
  };

  const copyLanguageName = (sourceLanguage: string) => {
    languages.forEach(({ code: targetLanguage }) => {
      if (targetLanguage === sourceLanguage) return;

      updateRecordKeys(targetLanguage);

      if (!languageModified.value[targetLanguage]) {
        attributeNamesByLanguage.value[targetLanguage] = attributeNamesByLanguage.value[sourceLanguage];
        const nameIndex = attribute.value.names.findIndex((n) => n.languageIso === targetLanguage);
        if (nameIndex !== -1) {
          attribute.value.names[nameIndex].text = attributeNamesByLanguage.value[sourceLanguage];
        } else {
          attribute.value.names.push(new LanguageText(targetLanguage, attributeNamesByLanguage.value[sourceLanguage]));
        }
      }
    });
  };

  const copyLanguageValues = (sourceLanguage: string, attributeId: string | undefined | null) => {
    updateRecordKeys(sourceLanguage);

    if (sourceLanguage === defaultLanguage.value || !languageModified.value[defaultLanguage.value])
      languages.forEach(({ code: targetLanguage }) => {
        if (targetLanguage === sourceLanguage) return;

        updateRecordKeys(targetLanguage);

        if (!languageModified.value[targetLanguage]) {
          let attributeValues = attributeValuesByLanguage.value[sourceLanguage];
          if (attributeId) {
            const currentAttributeValue = attributeValues.find(({ id }) => id === attributeId);
            attributeValues = currentAttributeValue ? [currentAttributeValue] : attributeValues;
          }

          attributeValues.forEach((sourceValue) => {
            const targetValue = attributeValuesByLanguage.value[targetLanguage].find((v) => v.id === sourceValue.id);
            if (targetValue) {
              targetValue.value = sourceValue.value;
              targetValue.description = sourceValue.description;
            } else {
              attributeValuesByLanguage.value[targetLanguage].push({ ...sourceValue });
            }

            const attributeValue = attribute.value.values.find((v) => v.id === sourceValue.id);
            if (attributeValue) {
              attributeValue.setValueForLanguage(targetLanguage, sourceValue.value);
              attributeValue.setDescriptionForLanguage(targetLanguage, sourceValue.description);
            }
          });
        }
      });
  };

  const deleteValue = (valueId: string) => {
    attribute.value.values = attribute.value.values.filter((v) => v.id !== valueId);

    languages.forEach((lang) => {
      updateRecordKeys(lang.code);
      attributeValuesByLanguage.value[lang.code] = attributeValuesByLanguage.value[lang.code].filter(
        (v) => v.id !== valueId,
      );
    });
  };

  const translate = async () => {
    languages.forEach(({ code }) => {
      updateRecordKeys(code);
    });

    await translateAll(
      attributeNamesByLanguage.value,
      attributeValuesByLanguage.value,
      languageModified.value,
      attribute.value,
      setAttributeName,
      setAttributeValue,
    );
  };

  const resetStore = () => {
    attribute.value = new Attribute();
    initialAttribute.value = cloneDeep(attribute.value);
    attributeNamesByLanguage.value = {
      ["en-GB"]: "",
      ["nb-NO"]: "",
    };
    attributeValuesByLanguage.value = {
      ["en-GB"]: [],
      ["nb-NO"]: [],
    };
    languageModified.value = {
      ["en-GB"]: false,
      ["nb-NO"]: false,
    };
  };

  return {
    attribute,
    attributeNamesByLanguage,
    attributeValuesByLanguage,
    isAttributeModified,
    setAttributeName,
    setAttributeValue,
    addNewAttributeValue,
    deleteValue,
    copyLanguageValues,
    resetStore,
    translate,
  };
});
