import { type AxiosResponse } from "axios";
import { httpClient, type AuthHeaders } from "@multicase/portal-api";
import type { ProductGroupName } from "@/product-hierarchy/model/ProductGroupName";

class ProductHierarchyApi {
  private uri = "";

  constructor() {
    this.uri = import.meta.env.VITE_APP_API_PRODUCT
      ? import.meta.env.VITE_APP_API_PRODUCT
      : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async getProductGroupNames(authHeaders: AuthHeaders): Promise<ProductGroupName[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/product-group-names")
      .then(({ data }: AxiosResponse) => data);
  }
}

const productHierarchyApi = new ProductHierarchyApi();
export { productHierarchyApi };
