import { useErrorHandler } from "@/repositories/ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { defineStore } from "pinia";
import { ref } from "vue";
import { productImportApi } from "./ProductImportApi";
import { ImportOptions } from "@/product/models/ImportOptions";
import { SystemField } from "@/product/models/SystemField";
import { type ProductImportInitializeResponse } from "@/product/models/ProductImportInitializeResponse";
import { type ProductImportMapRequest } from "@/product/models/ProductImportMapRequest";
import { type ValidationResultsResponse } from "@/product/models/ValidationResultsResponse";
import { type ParsedRow } from "@/product/models/ParsedRow";
import { type ColumnMappingSuggestionResponse } from "@/product/models/ColumnMappingSuggestionResponse";
import { type ProductImportSessionResponse } from "@/product/models/ProductImportSessionResponse";
import { type ProductImportPreviewResponse } from "@/product/models/ProductImportPreviewResponse";

export const useProductImportStore = defineStore("productImportstore", () => {
  const importId = ref<string>("");
  const columns = ref<string[]>([]);
  const rows = ref<ParsedRow[]>([]);
  const importOptions = ref<ImportOptions>({
    overwriteExisting: true,
    createNew: true,
    updateEmpty: false,
    productIdentifier: "",
  });
  const systemFields = ref<SystemField[]>([]);

  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const resetState = (): void => {
    importId.value = "";
    columns.value = [];
    rows.value = [];
    importOptions.value = {
      overwriteExisting: true,
      createNew: true,
      updateEmpty: false,
      productIdentifier: "",
    };
    systemFields.value = [];
  };

  const initializeImport = async (formData: FormData): Promise<ProductImportInitializeResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      const response = await productImportApi.initializeImport(authHeaders, formData);
      importId.value = response.id;
      columns.value = response.columns;
      rows.value = response.rows;
      return response;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getWebPubSubUrl = async (): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productImportApi.getWebPubSubUrl(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const startMapping = async (request: ProductImportMapRequest): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await productImportApi.startMapping(authHeaders, request);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getSystemFields = async (): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      systemFields.value = await productImportApi.getSystemFields(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getValidationResult = async (
    id: string,
    page: number,
    pageSize: number,
  ): Promise<ValidationResultsResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productImportApi.getValidationResult(authHeaders, id, page, pageSize);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getColumnMappingSuggestions = async (columns: string[]): Promise<ColumnMappingSuggestionResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productImportApi.getColumnMappingSuggestions(authHeaders, columns);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getImportSessions = async (
    page: number,
    pageSize: number,
    sortBy?: string,
    sortOrder?: string,
  ): Promise<ProductImportSessionResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productImportApi.getImportSessions(authHeaders, page, pageSize, sortBy, sortOrder);
    } catch (error) {
      console.error("Error fetching import sessions:", error);
      throw error;
    }
  };

  const getImportSessionPreview = async (id: string): Promise<ProductImportPreviewResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productImportApi.getImportSessionPreview(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    importId,
    columns,
    rows,
    importOptions,
    systemFields,
    initializeImport,
    getWebPubSubUrl,
    startMapping,
    getSystemFields,
    getValidationResult,
    getColumnMappingSuggestions,
    getImportSessions,
    resetState,
    getImportSessionPreview,
  };
});
