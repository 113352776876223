import { AIPrompt } from "@/models/product-info/AIPrompts";
import { UpdateAIPrompt } from "@/models/product-info/UpdateAIPrompts";
import { AIJsonModel } from "@/repositories/product/model/AIJsonModel";
import { httpClient, AuthHeaders } from "@cumulus/http";

class AiProductApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != "undefined" && import.meta.env.VITE_APP_API_PRODUCT
        ? import.meta.env.VITE_APP_API_PRODUCT + "/products"
        : `${import.meta.env.VITE_APP_API_URL as string}/products`;
  }

  public async getAllPrompts(authHeaders: AuthHeaders): Promise<AIPrompt[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/prompts")
      .then(({ data }) => data);
  }

  public async createPrompt(authHeaders: AuthHeaders, prompt: AIPrompt): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("/prompts", prompt);
  }

  public async updatePrompt(authHeaders: AuthHeaders, prompt: UpdateAIPrompt): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/prompts", prompt);
  }

  public async deletePrompt(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(`/prompts/${id}`);
  }

  public async getProductDescription(
    authHeaders: AuthHeaders,
    productName: string,
    prompt: string,
    openAIVersion: string,
    language: string,
  ): Promise<AIJsonModel> {
    return await httpClient(this.uri, authHeaders)
      .get("/generate-description", {
        params: {
          openAIVersion: openAIVersion,
          prompt: prompt,
          productName: productName,
          language: language,
        },
      })
      .then(({ data }) => data);
  }
}

export const aiProductApi = new AiProductApi();
