<template>
  <div class="flex mt-4">
    <FloatLabel variant="on">
      <Select
        id="ai-prompt"
        v-model="promptRef"
        variant="filled"
        :options="prompts"
        option-label="promptTitle"
        class="inputfield w-full md:w-80"
      >
        <template #option="{ option }">
          <i v-if="Object.values(promptTitles).includes(option.promptTitle)" class="pi pi-lock mr-2"></i>
          <span>{{ getPromptTitle(option.promptTitle) }}</span>
        </template>
        <template #value="{ value }">
          <i v-if="Object.values(promptTitles).includes(value?.promptTitle)" class="pi pi-lock mr-2"></i>
          <span>{{ getPromptTitle(value?.promptTitle) }}</span>
        </template>
      </Select>
      <label for="ai-prompt">{{ t("product.ai-search.style") }}</label>
    </FloatLabel>
    <Button
      severity="secondary"
      data-testid="btn-ai-prompt-chooser"
      aria-haspopup="true"
      aria-controls="overlay_menu"
      outlined
      :pt:root:class="`bg-white ml-3 px-5`"
      @click="promptRef && editSelectedPrompt(promptRef)"
    >
      <i class="pi pi-file-edit"></i>
    </Button>
  </div>
</template>

<script setup lang="ts">
import { AIPrompt } from "@/models/product-info/AIPrompts";
import { AIStandardPrompt } from "@/models/product-info/AIStandardPrompt";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const prompts = defineModel<AIPrompt[]>("prompts");
const visibleProperty = defineModel<boolean>("visibleProperty");

const promptRef = defineModel<AIPrompt>("promptRef");

const emit = defineEmits<{
  (e: "editPrompt", value: AIPrompt): void;
}>();

const promptTitles = ref<AIStandardPrompt[]>(Object.values(AIStandardPrompt));

const getPromptTitle = (promptTitle: string | undefined) => {
  if (promptTitle === AIStandardPrompt.DetailedPrompt) {
    return t("product.ai-search.prompt-title.detailedprompt");
  }
  if (promptTitle === AIStandardPrompt.SimplePrompt) {
    return t("product.ai-search.prompt-title.simpleprompt");
  }
  return promptTitle;
};

const editSelectedPrompt = (prompt: AIPrompt) => {
  emit("editPrompt", prompt);
  visibleProperty.value = !visibleProperty.value;
};

watch(
  () => prompts.value,
  (newVal) => {
    if (newVal && newVal.length > 0) {
      promptRef.value = newVal[0];
    }
  },
  { immediate: true },
);
</script>
