const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const paramsToObject = (entries: URLSearchParams) => {
  const result = {} as Record<string, string>;
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

export { timeout, paramsToObject };
