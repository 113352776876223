<template>
  <FileUpload
    accept="image/jpeg,image/png,image/webp"
    :multiple="true"
    :maxFileSize="10000000"
    :auto="true"
    :customUpload="true"
    data-testid="product-images-upload"
    pt:root:class="border-none p-0"
    pt:header:class="p-0"
    pt:content:class="p-0 border-none"
    pt:footer:class="rounded-lg"
    @uploader="uploader"
  >
    <!-- class="h-full flex flex-col border-none" -->
    <template #header="{ chooseCallback }">
      <div class="flex flex-col sm:flex-row justify-center items-center w-full gap-4 p-4">
        <PrimeButton class="c-circular-button w-full sm:w-auto" @click="chooseCallback()">
          <i class="pi pi-plus c-success-button c-circular-icon mr-2"></i>
          <span class="ml-2">{{ t("product.image.select") }}</span>
        </PrimeButton>
        <PrimeButton
          class="c-circular-button w-full sm:w-auto"
          data-testid="manage-images"
          @click="emit('openImageManagementModal')"
        >
          <i class="pi pi-cog c-default-button c-circular-icon mr-2"></i>
          <span>{{ t("product.image.manage") }}</span>
        </PrimeButton>
        <PrimeButton
          class="c-circular-button w-full sm:w-auto"
          :disabled="productImages.length === 0"
          @click="emit('clearImagesClicked')"
        >
          <i class="pi pi-times c-delete-button c-circular-icon mr-2"></i>
          <span>{{ t("product.image.clear") }}</span>
        </PrimeButton>
      </div>
    </template>
    <template #content>
      <div class="c-file-upload-content">
        <div v-if="productImages.length === 0" class="c-empty-state">
          <i class="pi pi-cloud-upload !border-2 !rounded-full !p-8 !text-4xl !text-muted-color" />
          <p class="mt-6 mb-0">{{ t("product.image.drag-and-drop") }}</p>
          <p class="text-sm text-gray-500">{{ t("product.image.supported-formats") }}</p>
        </div>
        <ImageGallery v-else :productImages="productImages" />
      </div>
    </template>
  </FileUpload>
</template>

<script setup lang="ts">
import { useImageService } from "@/repositories/image/ImageService";
import { ImageInfo } from "@/repositories/image/model/ImageInfo";
import { useAuth } from "@cumulus/event-bus";
import { type FileUploadUploaderEvent } from "primevue/fileupload";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import ImageGallery from "./ImageGallery.vue";

const { t } = useI18n();
const uploadInProgress = ref(false);
const { getAuthHeaders } = useAuth();
const { uploadImage } = useImageService();

defineProps<{
  productImages: ImageInfo[];
}>();

const emit = defineEmits<{
  (e: "imageUploaded", imageInfo: ImageInfo): void;
  (e: "clearImagesClicked"): void;
  (e: "openImageManagementModal"): void;
}>();

const uploader = async (event: FileUploadUploaderEvent) => {
  const files = event.files;
  if (files !== undefined && files !== null) {
    uploadInProgress.value = true;

    try {
      const authHeaders = await getAuthHeaders();
      for (const file of files as File[]) {
        const imageInfo = await uploadImage(file, authHeaders);
        emit("imageUploaded", imageInfo);
      }
    } finally {
      uploadInProgress.value = false;
      document.getElementById("gallery-wrapper")?.focus();
    }
  }
};
</script>

<style scoped>
.c-file-upload-content {
  display: flex;
  flex-direction: column;
  height: 32.119rem;
}

.c-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 32rem;
  padding: 2rem;
}

:deep(.c-circular-button) {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
