<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button id="btn-cancel" data-testid="btn-cancel" size="small" variant="text" @click="routeToProductList(true)">
        <i class="pi pi-arrow-left"></i>
        <span class="px-2">{{ t("product.title-add") }}</span>
      </Button>
    </div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center"></div>

    <div
      id="teleport-product-toolbar"
      class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5"
    ></div>
  </div>

  <ProductHeader
    :edit-mode="false"
    :has-updates="hasUpdates"
    :collapsed-panels="panelCollapsed"
    :show-dialog="showDialog"
    @on-save="saveProduct"
    @on-toggle-all-panels="onToggleAllPanels()"
    @update:show-dialog="showDialog = $event"
  />

  <div class="c-page-content mt-3 ml-5 pr-1" data-testid="add-product">
    <div class="grid grid-cols-12 gap-4 mb-4">
      <Card class="col-span-12 lg:col-span-8">
        <template #content>
          <div id="general-info" class="grid grid-cols-12 gap-4">
            <div class="col-span-12 lg:col-span-4">
              <ProductNumber v-model:product-number="product.productNumber" :focus="true" :is-editing="false" />
            </div>

            <div class="col-span-12 lg:col-span-4">
              <CumulusInputText v-model="product.gtin" test-id="product-gti-number" :label="t('product.gti-number')" />
            </div>

            <div class="col-span-12 lg:col-span-4">
              <CumulusInputText
                v-model="product.altProductNumber"
                test-id="alt-product-number"
                :label="t('product.alt-number')"
              />
            </div>

            <div class="col-span-12 lg:col-span-8">
              <ProductHierarchySearchInput v-model:product-hierarchy-id="product.productHierarchyId" />
            </div>
            <div class="col-span-12 lg:col-span-4">
              <CumulusSelect
                v-model="product.manufacturerId"
                test-id="manufacturer"
                :options="manufacturers"
                :label="t('product.manufacturer.label')"
                :show-button="true"
                :required="true"
                :default-select-first="true"
                @button-click="showManufacturerDialog = true"
              />
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-6">
              <ProductType v-model:product-type="product.productType" />
            </div>
            <div class="col-span-6">
              <CumulusSelect
                v-model="product.salesUnitId"
                test-id="sales-unit"
                :options="salesUnits"
                :label="t('product.sales-unit.label')"
                :show-button="true"
                :required="true"
                :default-select-first="true"
                @button-click="showSalesUnitDialog = true"
              />
            </div>
            <div class="col-span-6">
              <ProductStatus v-model:product-status="product.productStatus" />
            </div>
            <div class="col-span-6">
              <CumulusSelect
                v-model="product.accountGroupId"
                test-id="account-group"
                :options="accountGroups"
                :label="t('product.account-group.label')"
                :show-button="true"
                :required="true"
                :default-select-first="true"
                @button-click="showAccountGroupDialog = true"
              />
            </div>
          </div>
        </template>
      </Card>
    </div>

    <div class="grid grid-cols-12 gap-4 mb-4">
      <Card class="col-span-12 lg:col-span-8">
        <template #title>
          <div class="w-full text-center">{{ t("product.product-info") }}</div>
        </template>
        <template #content>
          <div class="grid grid-cols-12 gap-y-4 mb-2">
            <div class="col-span-12">
              <Name v-model:name="product.name" />
            </div>
            <div class="col-span-12">
              <Description v-model:description="product.description" />
            </div>
            <div class="col-span-12">
              <ProductInfo v-model:product-info="product.productInfo" />
            </div>
          </div>
        </template>
      </Card>

      <div class="col-span-12 lg:col-span-4">
        <Card>
          <template #title>
            <div class="w-full text-center">{{ t("product.media-and-files") }}</div>
          </template>
          <template #content>
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12">
                <MediaTabs
                  v-model:product-images="product.productImages"
                  v-model:product-videos="product.media"
                  v-model:product-documents="product.documents"
                />
              </div>
            </div>
          </template>
        </Card>

        <Card class="mt-4">
          <template #title>
            <div class="w-full text-center">{{ t("product.dimension.label") }}</div>
          </template>
          <template #content>
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12">
                <DimensionAndWeight v-model:dimension="product.dimension" v-model:weight="product.weight" />
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <Panel
      id="warehouseClientCollapsed"
      :header="t('product.warehouse-and-client')"
      toggleable
      :collapsed="panelCollapsedWarehouseClient"
      class="mb-4"
      :pt:header:on-click="() => (panelCollapsedWarehouseClient = !panelCollapsedWarehouseClient)"
    >
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-8 lg:border-r">
          <Warehouse
            :warehouse-ids="product.warehouseIds"
            @add-warehouse="addWarehouse"
            @delete-warehouse="deleteWarehouse"
          />
        </div>

        <div class="col-span-12 lg:col-span-4">
          <Client :clients="product.clientIds" @add-client="addClient" @delete-client="deleteClient" />

          <WebshopActive v-model:active-on-webshop="product.activeOnWebshop" />
        </div>
      </div>
    </Panel>

    <Panel
      id="suppliersCollapsed"
      :header="t('product.supplier.label')"
      toggleable
      :collapsed="panelCollapsedSupplier"
      class="mb-4"
      :pt:header:on-click="() => (panelCollapsedSupplier = !panelCollapsedSupplier)"
    >
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12">
          <SupplierPrices
            :supplier-prices="product.supplierPrices"
            :suppliers="suppliers"
            :client-currency-iso="userClient.currencyIso"
            :currencies="currencies"
            @main-supplier-cost-price-updated="($event) => (mainSupplierCostPrice = $event)"
            @update:supplier-prices="updateSupplierPrices"
            @add-supplier-price="addSupplierPrice"
            @delete-supplier-price="deleteSupplierPrice"
          />
        </div>
      </div>
    </Panel>

    <Panel
      id="pricesAndFeesCollapsed"
      :header="t('product.prices-and-fees')"
      toggleable
      :collapsed="panelCollapsedPricesAndFees"
      class="mb-4"
      :pt:header:on-click="() => (panelCollapsedPricesAndFees = !panelCollapsedPricesAndFees)"
    >
      <div class="grid grid-cols-1 gap-4">
        <div>
          <Price
            :main-supplier-cost-price="mainSupplierCostPrice"
            :client-currency-iso="userClient.currencyIso"
            :prices="product.prices"
            :vat-percentage="defaultOutgoingVatPercentage(product)"
            @update-price="updatePrice($event.price)"
          />
        </div>

        <div>
          <Tax :product-taxes="product.taxes" @update:product-taxes="updateTaxes" />
        </div>
        <div>
          <ExciseDuties />
        </div>
      </div>
    </Panel>

    <Panel
      id="attributesCollapsed"
      :header="t('product.attributes-and-connections')"
      toggleable
      :collapsed="panelCollapsedAttribues"
      class="mb-4"
      :pt:header:on-click="() => (panelCollapsedAttribues = !panelCollapsedAttribues)"
    >
      <div class="grid grid-cols-12 gap-4 mt-4">
        <div class="col-span-12 lg:col-span-4">
          <ProductAttributes v-model:product-attributes="product.attributes" :show-header="true" />
        </div>
      </div>
    </Panel>

    <Panel
      id="variantsCollapsed"
      :header="t('product.variants.label')"
      toggleable
      :collapsed="panelCollapsedVariants"
      class="mb-4"
      :pt:header:on-click="() => (panelCollapsedVariants = !panelCollapsedVariants)"
    >
      <GenerateVariants v-model:product-attributes="product.variantAttributes" :base-product="product" />
    </Panel>
  </div>
  <AIProductInfoDialog
    v-if="showDialog"
    v-model:visible="showDialog"
    v-model:product="product"
    data-testid="description-dialog"
    @add-to-product-from-a-i-assistant="addToProductFromAIAssistant"
  />

  <SalesUnitDialog v-model="product.salesUnitId" v-model:visible="showSalesUnitDialog" @hide="onUpdateSalesUnits" />

  <ManufacturerDialog
    v-if="showManufacturerDialog"
    v-model="product.manufacturerId"
    v-model:visible="showManufacturerDialog"
    @update="onUpdateManufacturers"
  />

  <AccountGroupDialog
    v-if="showAccountGroupDialog"
    v-model="product.accountGroupId"
    v-model:visible="showAccountGroupDialog"
    @update="onUpdateAccountGroups"
  />
</template>

<script setup lang="ts">
import { useToast } from "primevue/usetoast";
import { useCumulusToast, useUnsavedChanges } from "@cumulus/components";
import useValidate from "@vuelidate/core";
import { ShortcutAction, useShortcut } from "@cumulus/components";
import { NewProduct } from "@/repositories/product/model/NewProduct";
import { SupplierPrice } from "@/repositories/product/model/SupplierPrice";
import { Price as PriceModel } from "@/repositories/product/model/Price";
import { usePriceFunctions } from "../PriceFunctions";
import { useSupplier } from "@/repositories/supplier/SupplierService";
import { type Supplier } from "@/repositories/supplier/model/Supplier";
import { usePriceGroup } from "@/repositories/price-group/PriceGroupService";
import { useProductStore } from "@/repositories/product/ProductStore";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { ProductTax } from "@/repositories/product/model/ProductTax";
import { Currency } from "@/repositories/currency/model/Currency";
import { useCurrencyService } from "@/repositories/currency/CurrencyService";
import { Client as ClientModel } from "@/repositories/client/model/Client";
import { useClient } from "@/repositories/client/ClientService";
import { useAuth } from "@cumulus/event-bus";
import { ProductVariantsRequest } from "@/repositories/product/model/ProductVariantsRequest";
import { useVariantStore } from "@/stores/VariantStore";
import { Dimension } from "@/repositories/product/model/Dimension";
import { Weight } from "@/repositories/product/model/Weight";
import { SalesUnit } from "@multicase/portal-api/sales-unit";
import { Manufacturer } from "@multicase/portal-api/manufacturer";
import { AccountGroup } from "@multicase/portal-api/account-group";

const { salesUnits } = useSalesUnits();
const { manufacturers } = useManufacturers();
const { accountGroups } = useAccountGroups();

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const router = useRouter();

const product = ref<NewProduct>(new NewProduct());
const suppliers = ref<Supplier[]>([]);
const currencies = ref<Currency[]>([]);
const userClient = ref<ClientModel>(new ClientModel());
const mainSupplierCostPrice = ref<number>(0);
const showSalesUnitDialog = ref(false);
const showManufacturerDialog = ref(false);
const showAccountGroupDialog = ref(false);

const initialProduct = ref<NewProduct | null>(null);
const { defaultOutgoingVatPercentage } = usePriceFunctions();
const { getAllSuppliers } = useSupplier();
const { getDefaultPriceGroupByUsersClient } = usePriceGroup();
const { createProduct, createProductVariants } = useProductStore();
const { getAllCurrencies } = useCurrencyService();
const { variants } = storeToRefs(useVariantStore());
const { getClient } = useClient();
const { getAuthHeaders } = useAuth();
const confirmedDiscard = ref(false);

const routeToProductList = async (confirmed: boolean) => {
  confirmedDiscard.value = confirmed;
  if (window.history.state.back === null || window.history.state.back.indexOf("/product/search") === -1) {
    router.push({ name: "product-search", query: { search: "" } });
  } else {
    router.back();
  }
};

// Nested validation
const validate = useValidate();

const saveProduct = async () => {
  validate.value.$touch();

  if (!(await validate.value.$validate())) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  if (variants.value.length > 0) {
    const request = new ProductVariantsRequest(product.value, variants.value);

    await createProductVariants(request);
  } else {
    await createProduct(product.value);
  }

  routeToProductList(false);
};

const addSupplierPrice = async (supplierPrice: SupplierPrice) => {
  if (product.value.supplierPrices.length === 0) {
    supplierPrice.isDefaultSupplier = true;
  }
  product.value.supplierPrices.push(supplierPrice);
  const index = product.value.supplierPrices.length - 1;
  await nextTick();
  const productNumberInput = document.getElementById(`supplier-product-number-${index}`) as HTMLInputElement;
  if (productNumberInput) {
    productNumberInput.focus();
  }
};

const deleteSupplierPrice = (supplierPrice: SupplierPrice) => {
  const index = product.value.supplierPrices.findIndex((s) => s.id === supplierPrice.id);
  if (index !== -1) product.value.supplierPrices.splice(index, 1);
  if (supplierPrice.isDefaultSupplier === true && product.value.supplierPrices.length > 0)
    product.value.supplierPrices[0].isDefaultSupplier = true;
};

const addClient = (clientId: string) => {
  product.value.clientIds.push(clientId);
};

const deleteClient = (clientId: string) => {
  const index = product.value.clientIds.findIndex((c) => c === clientId);
  if (index !== -1) product.value.clientIds.splice(index, 1);
};

const addWarehouse = (warehouseId: string[]) => {
  product.value.warehouseIds = [...product.value.warehouseIds, ...warehouseId];
};

const deleteWarehouse = (warehouseId: string) => {
  const index = product.value.warehouseIds.findIndex((c) => c === warehouseId);
  if (index !== -1) product.value.warehouseIds.splice(index, 1);
};

const updatePrice = (price: PriceModel) => {
  const priceToUpdate = product.value.prices.find((s) => s.priceGroupId === price.priceGroupId);
  if (priceToUpdate === undefined) return;
  priceToUpdate.listPrice = price.listPrice;
};

const updateTaxes = (taxes: ProductTax[]) => {
  product.value.taxes = taxes;
};

const updateSupplierPrices = (supplierPrices: SupplierPrice[]) => {
  product.value.supplierPrices = supplierPrices;
};

const getCurrencies = async () => {
  currencies.value = await getAllCurrencies();
};

const getUserClient = async () => {
  const authHeaders = await getAuthHeaders();
  const clientId = authHeaders.clientId;
  userClient.value = await getClient(clientId);
};

const getPrice = async () => {
  const defaultPrice = await getDefaultPriceGroupByUsersClient();

  const price = new PriceModel();
  price.priceGroupId = defaultPrice.id;
  price.priceGroupName = defaultPrice.name;
  price.currencyIso = defaultPrice.currencyIso;

  product.value.prices.push(price);
};

onMounted(async () => {
  await Promise.all([getSuppliers(), getCurrencies(), getPrice(), getUserClient()]);
  nextTick(() => {
    // Wait for auto-select first option in dropdowns etc
    initialProduct.value = cloneDeep(product.value);
  });
});

const getSuppliers = async () => {
  suppliers.value = await getAllSuppliers();
};

const hasUpdates = computed(() => {
  if (initialProduct.value === null) return false;
  return !isEqual(product.value, initialProduct.value);
});

useShortcut(ShortcutAction.save, saveProduct);

const panelCollapsedWarehouseClient = ref(false);
const panelCollapsedSupplier = ref(false);
const panelCollapsedPricesAndFees = ref(false);
const panelCollapsedAttribues = ref(false);
const panelCollapsedVariants = ref(false);

const panelCollapsed = computed(() => {
  return (
    panelCollapsedWarehouseClient.value &&
    panelCollapsedSupplier.value &&
    panelCollapsedPricesAndFees.value &&
    panelCollapsedAttribues.value &&
    panelCollapsedVariants.value
  );
});

const onToggleAllPanels = () => {
  const newState = !panelCollapsed.value;
  panelCollapsedWarehouseClient.value = newState;
  panelCollapsedSupplier.value = newState;
  panelCollapsedPricesAndFees.value = newState;
  panelCollapsedAttribues.value = newState;
  panelCollapsedVariants.value = newState;
};

const showDialog = ref<boolean>(false);
const addToProductFromAIAssistant = (
  valueProductInfo: string,
  valueProductDescription: string,
  valueProductName: string,
  valueWeight: Weight,
  valueDimenstion: Dimension,
) => {
  product.value.productInfo = valueProductInfo;
  product.value.description = valueProductDescription;
  product.value.name = valueProductName;
  product.value.weight = valueWeight;
  product.value.dimension = valueDimenstion;

  showDialog.value = false;
};

const onUpdateSalesUnits = async (items: SalesUnit[]) => {
  salesUnits.value = items;
};

const onUpdateManufacturers = async (items: Manufacturer[]) => {
  manufacturers.value = items;
};

const onUpdateAccountGroups = async (items: AccountGroup[]) => {
  accountGroups.value = items;
};

useUnsavedChanges(hasUpdates);
</script>

<style scoped lang="scss">
:deep(.p-galleria .p-galleria-thumbnail-container) {
  background: var(--maskbg);
  padding: 0.25rem;
  border-bottom-left-radius: var(--card-border-radius);

  @media (max-width: 767px) {
    border-radius: 0;
  }
}

:deep(.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev),
:deep(.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next) {
  color: #fff;
  margin: 0.1rem;
}
:deep(.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus) {
  box-shadow: none;
}
</style>
