<template>
  <div class="place-items-center">
    <span for="lock-ai-prompt" class="text-sm">{{ t("product.ai-search.lock") }}</span>
    <div>
      <i :class="iconClass" style="font-size: 2rem"></i>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AIStandardPrompt } from "@/models/product-info/AIStandardPrompt";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const name = defineModel<string>("name");

const iconClass = computed(() => {
  if (name.value === AIStandardPrompt.DetailedPrompt || name.value === AIStandardPrompt.SimplePrompt)
    return "pi pi-lock";
  return "pi pi-lock-open";
});
</script>
