<template>
  <div class="flex flex-col-reverse sm:flex-row sm:justify-between items-center gap-4 px-4 py-3">
    <Button
      v-if="isEditing"
      :label="t('common.delete')"
      :disabled="disabled"
      data-testid="delete-btn"
      class="w-full sm:w-auto mt-2 sm:mt-0"
      severity="danger"
      @click="emit('deleteClicked', $event)"
    />
    <Button
      :label="t(`common.cancel`)"
      :disabled="disabled"
      data-testid="cancel-btn"
      class="w-full sm:w-auto mt-2 sm:mt-0"
      severity="cancel"
      variant="text"
      icon="pi pi-times"
      @click="emit('cancelClicked')"
    />
    <Button
      :label="isEditing ? t('common.save') : t('common.add')"
      :disabled="disabled"
      data-testid="btn-commit"
      icon="pi pi-check"
      :loading="isSaving"
      @click="emit('saveClicked')"
    />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();

defineProps<{
  isEditing: boolean;
  isSaving: boolean;
  disabled: boolean;
}>();

const emit = defineEmits<{
  (e: "saveClicked"): void;
  (e: "cancelClicked"): void;
  (e: "deleteClicked", value: Event): void;
}>();
</script>
