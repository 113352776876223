<template>
  <Select
    id="select-attribute"
    :model-value="selectedAttributeId"
    :options="availableAttributes"
    option-label="name"
    option-value="id"
    :placeholder="t('placeholder.select', { property: t('product.attribute.attribute').toLowerCase() })"
    class="w-full"
    data-testid="select-attribute"
    @update:model-value="emit('update:selectedAttributeId', $event)"
  />
  <small v-if="val.selectedAttributeId.$error" class="p-error" data-testid="product-attribute-error">
    {{ val.selectedAttributeId.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import useVuelidate from "@vuelidate/core";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  selectedAttributeId: string;
  availableAttributes: AttributeByLanguageIso[];
}>();

const emit = defineEmits<{
  (e: "update:selectedAttributeId", value: string): void;
}>();

const rules = {
  selectedAttributeId: {
    required: required,
  },
};

const val = useVuelidate(rules, props);
</script>
