import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import type { ProductAttributeRow } from "@/models/attribute/ProductAttributeRow";
import { useI18n } from "vue-i18n";
import { isAttributeValid } from "./useAttributeUtils";

export function useProductAttributeValidation() {
  const { t } = useI18n();

  const rules = {
    attributeId: {
      required: helpers.withMessage(t("product.attribute.error.attribute-required"), required),
    },
    attributeValueIds: {
      required: helpers.withMessage(
        t("product.attribute.error.values-required"),
        (value: string[]) => value.length > 0,
      ),
    },
  };

  const validateAttribute = async (data: { attributeId: string; attributeValueIds: string[] }) => {
    const validate = useVuelidate(rules, { attributeId: data.attributeId, attributeValueIds: data.attributeValueIds });
    await validate.value.$validate();

    const errors = {
      attributeId:
        typeof validate.value.attributeId.$errors[0]?.$message === "string"
          ? validate.value.attributeId.$errors[0].$message
          : validate.value.attributeId.$errors[0]?.$message?.value || "",
      attributeValueIds:
        typeof validate.value.attributeValueIds.$errors[0]?.$message === "string"
          ? validate.value.attributeValueIds.$errors[0].$message
          : validate.value.attributeValueIds.$errors[0]?.$message?.value || "",
    };

    return {
      valid: !validate.value.$invalid,
      errors,
    };
  };

  const getSaveButtonTooltip = (productAttributes: ProductAttributeRow[]): string => {
    if (productAttributes.some((attr) => attr.isEditing)) {
      return t("product.attribute.validation.complete-editing-row");
    } else if (productAttributes.some((attr) => !isAttributeValid(attr))) {
      return t("product.attribute.validation.failed");
    }
    return "";
  };

  return {
    validateAttribute,
    getSaveButtonTooltip,
  };
}
