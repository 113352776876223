import { storeToRefs } from "pinia";
import { useProductImportOptionsStore } from "@/product/stores/ProductImportOptionsStore";
import type { DefaultValueMapping } from "@/product/models/ImportOptions";

export function useProductImportFields() {
  const optionsStore = useProductImportOptionsStore();
  const { importOptions } = storeToRefs(optionsStore);

  const defaultValueFieldMap: Record<string, keyof DefaultValueMapping> = {
    name: "name",
    productType: "productType",
    productStatus: "productStatus",
    salesUnitName: "salesUnit",
    productGroupNameLevel1: "productGroupLevel1",
    supplierNames: "mainSupplier",
    priceGroupName: "priceGroup",
    vat: "taxOutgoing",
    warehouse: "warehouses",
    clients: "clients",
  };

  const hasDefaultValue = (fieldName: string): boolean => {
    const defaultField = defaultValueFieldMap[fieldName];
    if (!defaultField) return false;

    const value = importOptions.value.defaultValues[defaultField];

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (value && typeof value === "object") {
      return true;
    }

    return !!value;
  };

  const isFieldMapped = (columnMappings: Array<{ systemField: string }>, fieldName: string): boolean => {
    return columnMappings.some((mapping) => mapping.systemField === fieldName);
  };

  return {
    hasDefaultValue,
    isFieldMapped,
    defaultValueFieldMap,
  };
}
