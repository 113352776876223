import { useProductLanguageFunctions } from "@/utils/productLanguageFunctions";
import { useProductLanguageStore } from "@/stores/ProductLanguageStore";
import { useAuth } from "@cumulus/event-bus";
import { AttributeValueByLanguage } from "@/models/attribute/AttributeValueByLanguage";
import { useLanguageApi, type LanguageTranslation, type LanguageTranslations } from "@multicase/portal-api/language";
import { LanguageText } from "@/models/language/LanguageText";
import { Attribute } from "@/models/attribute/Attribute";

export const useAiAttributeTranslation = () => {
  const { getAuthHeaders } = useAuth();
  const languageApi = useLanguageApi();
  const { languages } = useProductLanguageFunctions();

  const productLanguageStore = useProductLanguageStore();
  const { defaultLanguage } = storeToRefs(productLanguageStore);

  const translateAll = async (
    attributeNamesByLanguage: Record<string, string> | undefined | null,
    attributeValuesByLanguage: Record<string, AttributeValueByLanguage[]>,
    languageModified: Record<string, boolean>,
    attribute: Attribute,
    setAttributeName:
      | ((language: string, value: string, shouldCopy?: boolean, setModified?: boolean) => void)
      | undefined
      | null,
    setAttributeValue: (
      language: string,
      newValue: AttributeValueByLanguage,
      shouldCopy?: boolean,
      setModified?: boolean,
    ) => void,
  ) => {
    const defaultLanguageExists = languages.find(({ code }) => code === defaultLanguage.value);
    if (!defaultLanguageExists) return;

    const translateTo = languages.filter(({ code }) => !languageModified[code]).map(({ code }) => code);
    const authHeaders = await getAuthHeaders();

    const textToTranslate = [];
    if (attributeNamesByLanguage) {
      const attributeName = attributeNamesByLanguage[defaultLanguage.value];
      if (attributeName && attributeName !== "") {
        textToTranslate.push(attributeName);
      }
    }

    attributeValuesByLanguage[defaultLanguage.value].forEach(({ value, description }) => {
      if (value && value !== "") textToTranslate.push(value);
      if (description && description !== "") textToTranslate.push(description);
    });

    const translatedText = (await languageApi.getTranslations(
      authHeaders,
      defaultLanguage.value,
      textToTranslate,
      translateTo,
    )) as LanguageTranslations[];

    if (attributeNamesByLanguage) {
      const attributeName = attributeNamesByLanguage[defaultLanguage.value];
      if (attributeName && attributeName !== "") {
        const attribNameTranslations = translatedText.shift();
        if (attribNameTranslations) {
          attribNameTranslations?.translations?.forEach((languageTranslation: LanguageTranslation) => {
            const attribName = attribute.names.find(
              (languageText: LanguageText) =>
                languageText.languageIso === languageTranslation.languageIso ||
                languageText.languageIso.startsWith(languageTranslation.languageIso),
            );
            if (attribName)
              setAttributeName?.(attribName.languageIso, languageTranslation.translatedText, false, false);
          });
        }
      }
    }

    attributeValuesByLanguage[defaultLanguage.value].forEach((attrib) => {
      let attribValueTranslations: LanguageTranslations | undefined,
        attribDescriptionTranslations: LanguageTranslations | undefined;
      if (attrib.value && attrib.value !== "") {
        attribValueTranslations = translatedText.shift();
      }

      if (attrib.description && attrib.description !== "") {
        attribDescriptionTranslations = translatedText.shift();
      }

      Object.keys(attributeValuesByLanguage).forEach((langIso: string) => {
        const targetValue: LanguageTranslation | undefined = attribValueTranslations?.translations.find(
          ({ languageIso }: { languageIso: string }) => langIso.startsWith(languageIso),
        );
        const targetDescription: LanguageTranslation | undefined = attribDescriptionTranslations?.translations.find(
          ({ languageIso }: { languageIso: string }) => langIso.startsWith(languageIso),
        );
        const existingAttrib: AttributeValueByLanguage =
          attributeValuesByLanguage[langIso]?.find((v: AttributeValueByLanguage) => v.id === attrib.id) ?? attrib;

        setAttributeValue(
          langIso,
          {
            ...existingAttrib,
            value: targetValue?.translatedText ?? existingAttrib.value,
            description: targetDescription?.translatedText ?? existingAttrib.description,
          },
          false,
          false,
        );
      });
    });
  };

  return {
    translateAll,
  };
};
