import { ref, type Ref } from "vue";
import { Product } from "@/repositories/product/model/Product";
import { useProductStore } from "@/repositories/product/ProductStore";
import { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import { useAttribute } from "@/repositories/attribute/AttributeService";
import cloneDeep from "lodash.clonedeep";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { ProductAttributeRowFactory } from "./useAttributeUtils";
import type { ProductAttributeRow } from "@/models/attribute/ProductAttributeRow";

export function useProductAttributeManagement() {
  const loading = ref(false);
  const isSaving = ref(false);
  const availableAttributes = ref<AttributeByLanguageIso[]>([]);
  const originalAttributes = ref<ProductAttributeRow[]>([]);

  const toast = useToast();
  const { t, locale } = useI18n();
  const productService = useProductStore();
  const attributeService = useAttribute();

  const setLoading = (state: boolean) => (loading.value = state);

  const loadAttributes = async () => {
    setLoading(true);
    try {
      if (availableAttributes.value.length === 0) {
        availableAttributes.value = await attributeService.getAttributes(locale.value);
      }
    } finally {
      setLoading(false);
    }
  };

  const loadProductAttributes = async (currentProduct: Product, productAttributes: Ref<ProductAttributeRow[]>) => {
    if (!currentProduct) return;

    setLoading(true);
    try {
      const updatedProduct = await productService.getProduct(currentProduct.id);
      if (updatedProduct) Object.assign(currentProduct, updatedProduct);

      await loadAttributes();

      const newAttributes = currentProduct.attributes
        .map((pa) => {
          const attribute = availableAttributes.value.find((a) => a.id === pa.attributeId);
          if (!attribute) return null;

          return ProductAttributeRowFactory.createFromAttribute(attribute, pa.attributeValueIds, locale.value);
        })
        .filter(Boolean) as ProductAttributeRow[];

      productAttributes.value = newAttributes;
      originalAttributes.value = cloneDeep(newAttributes);
    } finally {
      setLoading(false);
    }
  };

  const saveAttributes = async (currentProduct: Product, productAttributes: ProductAttributeRow[]) => {
    if (!currentProduct) return;

    isSaving.value = true;
    try {
      currentProduct.attributes = productAttributes.map((attr) => ({
        attributeId: attr.attributeId,
        attributeValueIds: attr.attributeValueIds,
      }));

      await productService.updateProduct(currentProduct, []);

      originalAttributes.value = cloneDeep(productAttributes);

      toast.add({
        severity: "success",
        summary: t("product.attribute.toast.success-update.summary"),
        detail: t("product.attribute.toast.success-update.detail", { name: currentProduct.name }),
        life: 3000,
      });
    } finally {
      isSaving.value = false;
    }
  };

  return {
    loading,
    isSaving,
    availableAttributes,
    originalAttributes,
    loadProductAttributes,
    loadAttributes,
    saveAttributes,
  };
}
