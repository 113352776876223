import { defineStore } from "pinia";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/repositories/ErrorHandler";

import { companyApi } from "@/product/api/company/CompanyApi";
import { productApi } from "@/repositories/product/ProductApi";
import { LanguageViewModel } from "@/product/models/LanguageViewModel";
import { ProductWithLanguage, Language } from "@/product/models/ProductWithLanguage";

export const useProductLanguageStore = defineStore("productLanguageStore", () => {
  const { t } = useI18n();
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const loading = ref(false);
  const saving = ref(false);

  const defaultLanguage = ref("");
  const allProductIds = ref<string[]>([]);
  const languages = ref<LanguageViewModel[]>([]);

  const productsLanguages = ref<ProductWithLanguage[]>([]);
  let productsLanguagesUnchanged: ProductWithLanguage[] = [];

  const getAvailableLanguages = async (): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      const company = await companyApi.get(authHeaders);

      defaultLanguage.value = company.defaultLanguageIso;

      for (const languageIso of company.activeLanguageIsos) {
        const language = new LanguageViewModel();
        language.name = t(`common.language-iso.${languageIso.toLowerCase()}`);
        language.code = languageIso;

        if (languageIso === defaultLanguage.value) {
          language.name += ` (${t("product-info-dialog.default-language")})`;
        }

        languages.value.push(language);
      }
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const getProductWithLanguage = (currentIndex: number): ProductWithLanguage => {
    return productsLanguages.value[currentIndex];
  };

  const resetStore = () => {
    allProductIds.value = [];
    productsLanguages.value = [];
    productsLanguagesUnchanged = [];
    languages.value = [];
  };

  const loadProductByIndex = async (index: number) => {
    if (index >= 0 && index < allProductIds.value.length) {
      const productId = allProductIds.value[index];

      const alreadyLoaded = productsLanguages.value.some((p) => p.productId === productId);
      if (!alreadyLoaded) {
        await getProductLanguages(productId);
      }
    }
  };

  const getProductLanguages = async (productId: string): Promise<ProductWithLanguage | null> => {
    try {
      const existingProduct = productsLanguages.value.find((p) => p.productId === productId);
      if (existingProduct) {
        return existingProduct;
      }

      loading.value = true;
      const authHeaders = await getAuthHeaders();
      const productLanguages = await productApi.getProductLanguages(authHeaders, productId);

      const productWithLanguage = new ProductWithLanguage();
      productWithLanguage.productId = productId;
      productWithLanguage.languages = productLanguages.map((productLanguage) => {
        const language = new Language();
        language.languageIso = productLanguage.languageIso;
        language.name = productLanguage.name;
        language.description = productLanguage.description;
        language.productInfo = productLanguage.productInfo;
        return language;
      });

      productsLanguages.value.push(productWithLanguage);
      productsLanguagesUnchanged.push(structuredClone(productWithLanguage));

      return productWithLanguage;
    } catch (error) {
      await handleError(error);
      return null;
    } finally {
      loading.value = false;
    }
  };

  const getChangedProductsLanguages = (): ProductWithLanguage[] => {
    return productsLanguages.value.filter((newObj) => {
      const originalObj = productsLanguagesUnchanged.find((origObj) => origObj.productId === newObj.productId);
      return originalObj && JSON.stringify(originalObj) !== JSON.stringify(newObj);
    });
  };

  const saveChanges = async (): Promise<void> => {
    try {
      saving.value = true;
      const changedProductsLanguages = getChangedProductsLanguages();
      const authHeaders = await getAuthHeaders();
      await productApi.updateProductsLanguages(authHeaders, changedProductsLanguages);
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  return {
    resetStore,
    getAvailableLanguages,
    getProductLanguages,
    getChangedProductsLanguages,
    saveChanges,
    loadProductByIndex,
    getProductWithLanguage,
    languages,
    productsLanguages,
    defaultLanguage,
    loading,
    saving,
  };
});
