<template>
  <ProductHeader
    :edit-mode="true"
    :has-updates="hasUpdates"
    :collapsed-panels="false"
    :show-dialog="false"
    @on-save="saveProduct"
    @on-delete="onDeleteProduct"
    @on-toggle-all-panels="onToggleAllPanels()"
  />

  <Message v-if="loadFailed" closable severity="error" class="mx-5 my-3" pt:text:class="w-full">
    <div class="w-full">
      {{ t("common.loading-failed") }} {{ errorReason }}
      <Button
        :label="t('common.reload')"
        severity="danger"
        variant="text"
        icon="pi pi-refresh"
        icon-pos="right"
        pt:label:class="underline"
        class="ml-2"
        @click="reload"
      ></Button>
    </div>
  </Message>

  <div class="c-page-content mt-3 ml-5 pr-1 flex-grow" data-testid="edit-product">
    <div class="grid grid-cols-3 gap-4 mb-4">
      <Panel id="warehouseCollapsed" :header="t('product.warehouse.title')" class="col-span-2 mb-4">
        <Warehouse
          :warehouse-ids="product.warehouseIds"
          :product-id="product.id"
          @add-warehouse="addWarehouse"
          @delete-warehouse="deleteWarehouse"
        />
      </Panel>
      <Panel id="clientCollapsed" :header="t('product.client-and-web')" class="mb-4">
        <div class="col-span-12 lg:col-span-4">
          <Client :clients="product.clientIds" @add-client="addClient" @delete-client="deleteClient" />

          <WebshopActive v-model:active-on-webshop="product.activeOnWebshop" />
        </div>
      </Panel>
    </div>

    <div class="grid grid-cols-1 gap-4 mb-4">
      <Panel
        id="suppliersCollapsed"
        class="mb-4"
        toggleable
        :header="t('product.supplier.label')"
        :collapsed="panelCollapsedSupplier"
        :pt:header:on-click="() => (panelCollapsedSupplier = !panelCollapsedSupplier)"
      >
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12">
            <SupplierPrices
              :supplier-prices="product.supplierPrices"
              :suppliers="suppliers"
              :client-currency-iso="userClient.currencyIso"
              :currencies="currencies"
              @main-supplier-cost-price-updated="($event) => (mainSupplierCostPrice = $event)"
              @update:supplier-prices="updateSupplierPrices"
              @add-supplier-price="addSupplierPrice"
              @delete-supplier-price="deleteSupplierPrice"
            />
          </div>
        </div>
      </Panel>
    </div>

    <div class="grid grid-cols-1 gap-4 mb-4">
      <Panel
        id="prices"
        toggleable
        :header="t('product.prices')"
        :collapsed="panelCollapsedPrices"
        :pt:header:on-click="() => (panelCollapsedPrices = !panelCollapsedPrices)"
        class="mb-4"
      >
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12">
            <Price
              :main-supplier-cost-price="mainSupplierCostPrice"
              :client-currency-iso="userClient.currencyIso"
              :prices="product.prices"
              :vat-percentage="defaultOutgoingVatPercentage(product)"
              @update-price="updatePrice($event.price)"
            />
          </div>
        </div>
      </Panel>
    </div>

    <div class="grid grid-cols-1 gap-4 mb-4">
      <Panel
        id="taxRatesCollapsed"
        :header="t('product.tax-rate')"
        toggleable
        class="mb-4"
        :collapsed="panelCollapsedTaxRates"
        :pt:header:on-click="() => (panelCollapsedTaxRates = !panelCollapsedTaxRates)"
      >
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12">
            <Tax :product-taxes="product.taxes" @update:product-taxes="updateTaxes" />
            <div>
              <ExciseDuties />
            </div>
          </div>
        </div>
      </Panel>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProductManager } from "@/product/composables/useProductManager";
import { ProductTax } from "@/repositories/product/model/ProductTax";
import { Price as PriceModel } from "@/repositories/product/model/Price";
import { SupplierPrice } from "@/repositories/product/model/SupplierPrice";

const { t } = useI18n();
const {
  reload,
  onDeleteProduct,
  defaultOutgoingVatPercentage,
  errorReason,
  loadFailed,
  product,
  saveProduct,
  hasUpdates,
  suppliers,
  userClient,
  currencies,
} = useProductManager();

const mainSupplierCostPrice = ref<number>(0);

const addClient = async (clientId: string) => {
  product.value.clientIds.push(clientId);
  await nextTick();
  document.getElementById("add-client-button")?.focus();
};

const deleteClient = (clientId: string) => {
  const index = product.value.clientIds.findIndex((c) => c === clientId);
  if (index !== -1) product.value.clientIds.splice(index, 1);
};
const addWarehouse = async (warehouseId: string[]) => {
  product.value.warehouseIds = [...product.value.warehouseIds, ...warehouseId];
  await nextTick();
  document.getElementById("add-warehouse-button")?.focus();
};

const deleteWarehouse = async (warehouseId: string) => {
  const index = product.value.warehouseIds.findIndex((c) => c === warehouseId);
  if (index !== -1) product.value.warehouseIds.splice(index, 1);
  await nextTick();
  document.getElementById("add-warehouse-button")?.focus();
};

const updatePrice = (price: PriceModel) => {
  const priceToUpdate = product.value.prices.find((s) => s.priceGroupId === price.priceGroupId);
  if (priceToUpdate === undefined) return;
  priceToUpdate.listPrice = price.listPrice;
};

const updateTaxes = (taxes: ProductTax[]) => {
  product.value.taxes = taxes;
};

const updateSupplierPrices = (supplierPrices: SupplierPrice[]) => {
  product.value.supplierPrices = supplierPrices;
};

const addSupplierPrice = async (supplierPrice: SupplierPrice) => {
  if (product.value.supplierPrices.length === 0) {
    supplierPrice.isDefaultSupplier = true;
  }
  product.value.supplierPrices.push(supplierPrice);

  const index = product.value.supplierPrices.length - 1;
  await nextTick();
  const productNumberInput = document.getElementById(`supplier-product-number-${index}`) as HTMLInputElement;
  if (productNumberInput) {
    productNumberInput.focus();
  }
};

const deleteSupplierPrice = (supplierPrice: SupplierPrice) => {
  const index = product.value.supplierPrices.findIndex((s) => s.id === supplierPrice.id);
  if (index !== -1) {
    product.value.supplierPrices.splice(index, 1);
  }

  if (supplierPrice.isDefaultSupplier === true && product.value.supplierPrices.length > 0)
    product.value.supplierPrices[0].isDefaultSupplier = true;
};

const panelCollapsedSupplier = ref(false);
const panelCollapsedPrices = ref(false);
const panelCollapsedTaxRates = ref(false);

const panelCollapsed = computed(() => {
  return panelCollapsedSupplier.value && panelCollapsedPrices.value && panelCollapsedTaxRates.value;
});

const onToggleAllPanels = () => {
  const newState = !panelCollapsed.value;
  panelCollapsedSupplier.value = newState;
  panelCollapsedPrices.value = newState;
  panelCollapsedTaxRates.value = newState;
};
</script>
