<template>
  <ProductHeader
    :edit-mode="true"
    :has-updates="hasUpdates"
    :show-dialog="false"
    :show-ai-assisstance-button="false"
    :collapsed-panels="true"
    @on-save="saveProduct"
    @on-delete="onDeleteProduct"
    @update:show-dialog="false"
  />

  <Message v-if="loadFailed" closable severity="error" class="mx-5 my-3" pt:text:class="w-full">
    <div class="w-full">
      {{ t("common.loading-failed") }} {{ errorReason }}
      <Button
        :label="t('common.reload')"
        severity="danger"
        variant="text"
        icon="pi pi-refresh"
        icon-pos="right"
        pt:label:class="underline"
        class="ml-2"
        @click="reload"
      ></Button>
    </div>
  </Message>

  <div class="c-page-content mt-3 ml-5 pr-1 flex-grow" data-testid="edit-attributes">
    <Panel id="attributesCollapsed" :header="t('product.tabs.attributes')" class="mb-4">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-4">
          <ProductAttributes v-model:product-attributes="product.attributes" />
        </div>
      </div>
    </Panel>
  </div>
</template>

<script setup lang="ts">
import { useProductManager } from "@/product/composables/useProductManager";

const { t } = useI18n();
const { reload, onDeleteProduct, errorReason, loadFailed, product, saveProduct, hasUpdates } = useProductManager();
</script>
