import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { Attribute } from "@/models/attribute/Attribute";
import { AttributeValueByLanguage } from "@/models/attribute/AttributeValueByLanguage";
import { LanguageText } from "@/models/language/LanguageText";
import { AttributeValue } from "@/models/attribute/AttributeValue";
import isEqual from "lodash.isequal";
import { useProductLanguageFunctions } from "../../utils/productLanguageFunctions";
import { useProductLanguageStore } from "@/stores/ProductLanguageStore";
import { useAiAttributeTranslation } from "../composables/useAiAttributeTranslation";

export const useEditAttributeStore = defineStore("editAttributeStore", () => {
  const attribute = ref<Attribute>(new Attribute());
  const initialAttribute = ref<Attribute>(attribute.value);
  const { languages } = useProductLanguageFunctions();

  const productLanguageStore = useProductLanguageStore();
  const { defaultLanguage } = storeToRefs(productLanguageStore);

  const aiAttributeTranslation = useAiAttributeTranslation();
  const { translateAll } = aiAttributeTranslation;

  const attributeValuesByLanguage = ref<Record<string, AttributeValueByLanguage[]>>({});

  const languageModified = ref<Record<string, boolean>>({});

  const updateRecordKeys = (key: string) => {
    if (attributeValuesByLanguage.value[key] === undefined || attributeValuesByLanguage.value[key] === null)
      attributeValuesByLanguage.value[key] = [];
    if (languageModified.value[key] === undefined || languageModified.value[key] === null)
      languageModified.value[key] = false;
  };

  const isAttributeModified = computed(() => {
    return !isEqual(attribute.value, initialAttribute.value);
  });

  const attributeNamesByLanguageComputed = computed(() => {
    return languages.reduce(
      (acc, lang) => {
        acc[lang.code] = attribute.value.names.find((name) => name.languageIso === lang.code)?.text || "";
        return acc;
      },
      {} as Record<string, string>,
    );
  });

  const attributeValuesByLanguageComputed = computed(() => {
    return languages.reduce(
      (acc, lang) => {
        acc[lang.code] = attribute.value.values.map((value) => ({
          id: value.id,
          value: value.getValueForLanguage(lang.code),
          description: value.getDescriptionForLanguage(lang.code),
          additionalProperties: value.additionalProperties,
        }));
        return acc;
      },
      {} as Record<string, AttributeValueByLanguage[]>,
    );
  });

  const setAttributeName = (language: string, value: string) => {
    const index = attribute.value.names.findIndex((n) => n.languageIso === language);
    if (index !== -1) {
      attribute.value.names[index].text = value;
    } else {
      attribute.value.names.push(new LanguageText(language, value));
    }
    languageModified.value[language] = true;
  };

  const setAttributeValue = (
    language: string,
    newValue: AttributeValueByLanguage,
    shouldCopy: boolean = true,
    shouldStateAsModified: boolean = true,
  ) => {
    if (!attributeValuesByLanguage.value[language]) {
      attributeValuesByLanguage.value[language] = [];
    }

    const values = attributeValuesByLanguage.value[language];
    const index = values.findIndex((v) => v.id === newValue.id);
    if (index !== -1) {
      values[index] = newValue;
    } else {
      values.push(newValue);
    }

    let attributeValue = attribute.value.values.find((v) => v.id === newValue.id);
    if (!attributeValue) {
      attributeValue = new AttributeValue();
      attributeValue.id = newValue.id;
      attribute.value.values.push(attributeValue);
    }

    attributeValue.setValueForLanguage(language, newValue.value);
    attributeValue.setDescriptionForLanguage(language, newValue.description);
    attributeValue.additionalProperties = newValue.additionalProperties;
    if (shouldStateAsModified) languageModified.value[language] = true;
    if (shouldCopy) copyLanguageValues(language, attributeValue.id);
  };

  const addNewAttributeValue = () => {
    const newValue = new AttributeValue();
    attribute.value.values.push(newValue);
    languages.forEach((lang) => {
      if (!attributeValuesByLanguage.value[lang.code]) {
        attributeValuesByLanguage.value[lang.code] = [];
      }

      attributeValuesByLanguage.value[lang.code].push({
        id: newValue.id,
        value: "",
        description: "",
        additionalProperties: {},
      });
    });
  };

  const deleteValue = (valueId: string) => {
    attribute.value.values = attribute.value.values.filter((v) => v.id !== valueId);
  };

  const copyLanguageValues = (sourceLanguage: string, attributeId: string | undefined | null) => {
    if (sourceLanguage === defaultLanguage.value || !languageModified.value[defaultLanguage.value]) {
      if (!attributeValuesByLanguage.value[sourceLanguage]) {
        attributeValuesByLanguage.value[sourceLanguage] = [];
      }

      languages.forEach(({ code: targetLanguage }) => {
        if (targetLanguage === sourceLanguage) return;

        if (!attributeValuesByLanguage.value[targetLanguage]) {
          attributeValuesByLanguage.value[targetLanguage] = [];
        }

        if (!languageModified.value[targetLanguage]) {
          let attributeValues = attributeValuesByLanguage.value[sourceLanguage];
          if (attributeId) {
            const currentAttributeValue = attributeValues.find(({ id }) => id === attributeId);
            attributeValues = currentAttributeValue ? [currentAttributeValue] : attributeValues;
          }

          attributeValues.forEach((sourceValue) => {
            if (!!initialAttribute.value.values.find((targetValue) => targetValue.id === sourceValue.id)) return;

            const targetValue = attributeValuesByLanguage.value[targetLanguage].find((v) => v.id === sourceValue.id);
            if (targetValue) {
              targetValue.value = sourceValue.value;
              targetValue.description = sourceValue.description;
            } else {
              attributeValuesByLanguage.value[targetLanguage].push({ ...sourceValue });
            }

            const attributeValue = attribute.value.values.find((v) => v.id === sourceValue.id);
            if (attributeValue) {
              attributeValue.setValueForLanguage(targetLanguage, sourceValue.value);
              attributeValue.setDescriptionForLanguage(targetLanguage, sourceValue.description);
            }
          });
        }
      });
    }
  };

  const translate = async () => {
    languages.forEach(({ code }) => updateRecordKeys(code));

    await translateAll(
      null,
      attributeValuesByLanguage.value,
      languageModified.value,
      attribute.value,
      null,
      setAttributeValue,
    );
  };

  const resetStore = () => {
    attribute.value = new Attribute();
  };

  return {
    attribute,
    attributeNamesByLanguageComputed,
    attributeValuesByLanguageComputed,
    isAttributeModified,
    initialAttribute,
    setAttributeName,
    setAttributeValue,
    addNewAttributeValue,
    deleteValue,
    resetStore,
    translate,
  };
});
