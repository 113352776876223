<template>
  <div class="mt-4 mr-10">
    <FloatLabel variant="on">
      <Select
        id="ai-language"
        v-model="aiProductLanguage"
        :options="dropdownOptions"
        data-testid="ai-language"
        option-label="name"
        option-value="value"
        class="inputfield w-full md:w-80"
      />
      <label for="aai-language">{{ t("product.ai-search.language") }}</label>
    </FloatLabel>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const aiProductLanguage = defineModel<string>("aiProductLanguage");

const dropdownOptions = ref([
  { name: "Norwegian", value: "Norwegian" },
  { name: "English", value: "English" },
]);
</script>
