<template>
  <Dialog
    v-model:visible="visible"
    :modal="true"
    :header="t('product.video.add')"
    :style="{ width: '50vw', maxWidth: '600px' }"
    :content-style="{ padding: '0' }"
    @keydown.escape.stop="visible = false"
  >
    <div class="grid">
      <div class="col-12 md:col-6 p-4">
        <div class="mb-4">
          <FloatLabelInput
            id="video-name"
            v-model:value="productVideo.name"
            :label="t('product.media.title')"
            class="w-full"
            :class="{ 'p-invalid': validate.name.$error }"
            data-testid="add-video-name"
          />
          <small v-if="validate.name.$error" class="p-error" data-testid="video-name-error">
            {{ validate.name.$errors[0].$message }}
          </small>
        </div>
        <div class="mb-4">
          <FloatLabelTextArea
            id="video-description"
            v-model:value="productVideo.description"
            :label="t('product.media.description')"
            class="w-full"
            data-testid="add-video-description"
          />
        </div>
        <div class="mb-4">
          <FloatLabelInput
            id="video-uri"
            v-model:value="productVideo.uri"
            :label="t('product.media.uri')"
            class="w-full"
            :class="{ 'p-invalid': validate.uri.$error }"
            data-testid="add-video-uri"
          />
          <small v-if="validate.uri.$error" class="p-error" data-testid="video-uri-error">
            {{ validate.uri.$errors[0].$message }}
          </small>
        </div>
      </div>
      <div class="col-12 md:col-6 p-4 px-8">
        <h3 class="text-xl font-semibold mb-4">{{ t("product.video.preview") }}</h3>
        <div v-if="videoIdComputed" class="aspect-video mb-4">
          <iframe
            :src="`https://www.youtube.com/embed/${videoIdComputed}`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            class="w-full h-full"
          ></iframe>
        </div>
        <div v-else class="aspect-w-16 aspect-h-9 bg-gray-300 flex items-center justify-center">
          <span class="text-gray-500">{{ t("product.video.no-preview-available") }}</span>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-between">
        <div>
          <Button text icon="pi pi-times" :label="t('common.cancel')" class="mr-2" @click="visible = false" />
          <Button icon="pi pi-check" :label="t('common.save')" data-testid="add-video" @click="handleSave" />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { Media } from "@/repositories/product/model/Media";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();

const props = defineProps<{
  showDialog: boolean;
  index: number;
}>();

const productVideo = ref<Media>(new Media());

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
  (e: "videoAdded", value: Media): void;
}>();

const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const handleSave = async () => {
  const isFormValid = await validate.value.$validate();
  if (!isFormValid) {
    return;
  }
  emit("videoAdded", productVideo.value);
  visible.value = false;
};

const rules = {
  name: { required },
  uri: { required },
};

const validate = useVuelidate(rules, productVideo);

const extractYouTubeId = (url: string): string | null => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

const videoIdComputed = computed(() => {
  return extractYouTubeId(productVideo.value.uri);
});
</script>
