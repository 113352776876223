<template>
  <div v-if="loading" class="flex justify-center py-8">
    <ProgressSpinner />
  </div>

  <div v-else class="flex flex-col space-y-4">
    <div class="flex items-center space-x-8 mb-4">
      <ImportOptions />

      <AiSuggestionButton :columns="columns" :preview-rows="rows" @suggestions-received="handleSuggestions" />
    </div>

    <Message
      v-if="hasSuggestedMappings"
      v-tooltip.bottom="{
        value: t('product.import.ai-mappings-explanation'),
        showDelay: 1000,
        hideDelay: 300,
        pt: {
          text: '!w-[300px]',
        },
      }"
      closable
      severity="info"
      icon="pi pi-star text-yellow-500"
      >{{ t("product.import.ai-mappings-applied") }}</Message
    >

    <div class="border rounded-lg bg-white shadow overflow-hidden">
      <DataTable
        v-model:filters="filters"
        :value="tableData"
        class="p-datatable-sm"
        striped-rows
        responsive-layout="scroll"
        :global-filter-fields="['column', 'systemField', 'previewRow1', 'previewRow2', 'previewRow3']"
        :row-class="rowClass"
        scrollable
        scroll-height="calc(100vh - 450px)"
      >
        <template #header>
          <div class="flex justify-between items-center p-3">
            <div class="flex items-center">
              <span class="mr-2">{{ t("common.search") }}:</span>
              <InputText
                v-model="filters['global'].value"
                :placeholder="t('product.import.column-mapping.search-column')"
                class="w-64"
              />
            </div>

            <div class="flex items-center space-x-4">
              <div class="flex items-center space-x-2">
                <Checkbox v-model="showUnmappedOnly" :binary="true" input-id="showUnmapped" />
                <label for="showUnmapped">{{ t("product.import.show-unmapped-only") }}</label>
              </div>
            </div>
          </div>
        </template>

        <Column field="column" :header="t('product.import.column')" style="width: 20%">
          <template #body="{ data }">
            <div class="flex items-center">
              <span class="truncate max-w-xs">{{ data.column }}</span>
              <span
                v-if="getConfidenceScore(data.column) > 0"
                v-tooltip.bottom="{
                  value: t('product.import.ai.confidence-level', { score: getConfidenceScore(data.column) }),
                  showDelay: 1000,
                  hideDelay: 300,
                }"
                class="ml-2 flex-shrink-0 px-1.5 py-0.5 text-xs rounded-full"
                :class="getConfidenceScoreClass(data.column)"
              >
                {{ getConfidenceScore(data.column) }}%
              </span>
            </div>
          </template>
        </Column>

        <Column field="systemField" :header="t('product.import.system-field')" style="width: 15%">
          <template #body="{ data, index }">
            <Select
              :id="`select-${index}`"
              v-model="getColumnMapping(data.column).systemField"
              :options="getFilteredSystemFields(data.column)"
              option-label="label"
              option-value="name"
              data-testid="column-mapping-select"
              :placeholder="t('product.import.column-mapping.select-field-placeholder')"
              class="w-full"
              :filter="true"
              :filter-placeholder="t('product.import.column-mapping.search-field')"
              append-to="body"
              show-clear
              @show="focusSearchInput"
            />
          </template>
        </Column>

        <Column field="previewRow1" :header="t('product.import.line-1')" style="width: 18.33%">
          <template #body="{ data }">
            <div class="truncate text-sm text-gray-700 max-w-xs">{{ data.previewRow1 }}</div>
          </template>
        </Column>

        <Column field="previewRow2" :header="t('product.import.line-2')" style="width: 18.33%">
          <template #body="{ data }">
            <div class="truncate text-sm text-gray-700 max-w-xs">{{ data.previewRow2 }}</div>
          </template>
        </Column>

        <Column field="previewRow3" :header="t('product.import.line-3')" style="width: 18.33%">
          <template #body="{ data }">
            <div class="truncate text-sm text-gray-700 max-w-xs">{{ data.previewRow3 }}</div>
          </template>
        </Column>

        <template #empty>{{ t("product.import.no-columns-to-map") }}</template>
      </DataTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from "vue";
import { useI18n } from "vue-i18n";
import AiSuggestionButton from "./AiSuggestionButton.vue";
import ImportOptions from "./ImportOptions.vue";
import { useProductImportFields } from "@/product/composables/productImportFields";
import { useProductImportSessionStore } from "@/product/stores/ProductImportSessionStore";
import type { ColumnMapping } from "@/product/models/ColumnMapping";
import { FilterMatchMode } from "@primevue/core/api";
import { storeToRefs } from "pinia";

interface SystemFieldOption {
  name: string;
  label: string;
  value: string;
}

interface DisplayDataItem {
  rowNumber: number;
  column: string;
  systemField: string;
  previewRow1: string;
  previewRow2: string;
  previewRow3: string;
  status: string;
}

defineProps<{
  loading?: boolean;
}>();

const { t } = useI18n();
const { hasDefaultValue } = useProductImportFields();
const sessionStore = useProductImportSessionStore();
const { columnMappings, suggestedMappings, columns, rows, systemFields, confidenceScores } = storeToRefs(sessionStore);

const showUnmappedOnly = ref(false);

const hasSuggestedMappings = computed(
  () => suggestedMappings.value.length > 0 && suggestedMappings.value.some((m) => m.systemField),
);

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const tableData = computed<DisplayDataItem[]>(() => {
  if (columns.value.length === 0 || rows.value.length === 0) return [];

  const data = columns.value.map((column, columnIndex) => {
    const mapping = getColumnMapping(column);
    return {
      rowNumber: columnIndex + 1,
      column,
      systemField: mapping.systemField,
      previewRow1: rows.value[0]?.values[columnIndex] || "",
      previewRow2: rows.value[1]?.values[columnIndex] || "",
      previewRow3: rows.value[2]?.values[columnIndex] || "",
      status: mapping.systemField ? "mapped" : "not-mapped",
    };
  });

  return showUnmappedOnly.value ? data.filter((item) => !item.systemField) : data;
});

const rowClass = (data: DisplayDataItem) => {
  const columnMapping = getColumnMapping(data.column);
  const isEven = data.rowNumber % 2 === 0;

  if (columnMapping.systemField) {
    return isEven ? "bg-emerald-50 border-l-4 border-green-500" : "bg-emerald-50/70 border-l-4 border-green-500";
  }

  return "border-l-4 border-gray-200";
};

const focusSearchInput = () => {
  nextTick(() => {
    const searchInput = document.querySelector(".p-select-filter");
    if (searchInput) {
      (searchInput as HTMLInputElement).focus();
    }
  });
};

const handleSuggestions = (mappings: ColumnMapping[], scores: Record<string, number>) => {
  sessionStore.setSuggestions(mappings, scores);
};

const getColumnMapping = (column: string) => {
  let mapping = columnMappings.value.find((m) => m.importColumn === column);
  if (!mapping) {
    mapping = { importColumn: column, systemField: "" };
    columnMappings.value.push(mapping);
  }
  return mapping;
};

const getFilteredSystemFields = (column: string): SystemFieldOption[] => {
  const selectedFields = columnMappings.value
    .filter((m) => m.systemField && m.importColumn)
    .map((mapping) => mapping.systemField);

  const currentColumnMapping = getColumnMapping(column);

  return systemFields.value
    .filter((field) => {
      if (field.name === currentColumnMapping.systemField) return true;
      if (selectedFields.includes(field.name)) return false;
      if (hasDefaultValue(field.name)) return false;

      return true;
    })
    .map((field) => ({
      name: field.name,
      label: t(`product.import.system-fields.${field.name.toLowerCase()}`),
      value: field.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

const getConfidenceScore = (column: string): number => {
  const mapping = getColumnMapping(column);
  const suggestion = suggestedMappings.value.find((m) => m.importColumn === column)?.systemField;
  if (mapping.systemField !== suggestion) return 0;
  return Math.round((confidenceScores.value[column] || 0) * 100);
};

const getConfidenceScoreClass = (column: string): string => {
  if (!confidenceScores.value || Object.keys(confidenceScores.value).length === 0) return "bg-gray-100 text-gray-800";

  const confidence = confidenceScores.value[column] || 0;

  if (confidence >= 0.8) return "bg-green-100 text-green-800";
  if (confidence >= 0.6) return "bg-yellow-100 text-yellow-800";
  return "bg-gray-100 text-gray-800";
};
</script>
