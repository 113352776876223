<template>
  <InputText
    ref="searchInput"
    v-model="query"
    v-debounce:200="search"
    data-testid="product-search"
    :placeholder="t('common.search')"
    @keydown.enter.prevent="emit('focusSearchResult')"
    @keydown.tab.prevent="emit('focusSearchResult')"
  />
</template>
<script setup lang="ts">
import { Product } from "@/repositories/product/model/Product";
import { ProductSearchRequest } from "@/repositories/search/model/ProductSearchRequest";
import { useSearch } from "@/repositories/search/SearchService";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const { productSearch } = useSearch();

const emit = defineEmits<{
  (e: "update:searchResults", products: Product[], totalHits: number, searchTerm: string): void;
  (e: "update:isSearching", value: boolean): void;
  (e: "focusSearchResult"): void;
}>();

const props = defineProps<{
  page: number;
  pageSize: number;
}>();

const query = ref("");
const searchInput = ref();
const isSearching = ref(false);

watch(isSearching, (value) => {
  emit("update:isSearching", value);
});

watch(
  () => props.page,
  () => {
    search();
  },
);

const search = async () => {
  if (query.value.length < 1) {
    emit("update:searchResults", [], 0, query.value);
    return;
  }
  isSearching.value = true;
  try {
    const request = new ProductSearchRequest(query.value ? query.value : "*");
    request.pageSize = props.pageSize;
    request.page = props.page;
    const response = await productSearch(request);
    emit("update:searchResults", response.products, response.totalHits, query.value);
  } finally {
    isSearching.value = false;
  }
};
</script>
