<template>
  <div>
    <DataTable
      v-model:expanded-row-groups="expandedRowGroups"
      :value="flattenedProductAttributes"
      scroll-height="flex"
      data-key="id"
      :rows="10"
      row-group-mode="rowspan"
      group-rows-by="name"
      class="c-datatable"
    >
      <Column field="name" :header="t(`product.attribute.name`)">
        <template #body="{ data, index }">
          <span :data-testid="`product-attribute-name-${index}`">{{ data.name }}</span>
        </template>
      </Column>
      <Column :header="t(`product.attribute.value`)">
        <template #body="{ data, index }">
          <span :data-testid="`product-attribute-value-${index}`">{{ data.value }}</span>
        </template>
      </Column>
      <Column :header="t(`product.attribute.description`)">
        <template #body="{ data, index }">
          <span :data-testid="`product-attribute-description-${index}`">{{ data.description }}</span>
        </template>
      </Column>
      <Column :pt="{ headerCell: 'w-28' }">
        <template #body="{ data }">
          <div v-if="data.attributeType === AttributeType.Color" class="flex justify-between">
            <ColorPicker v-model="data.additionalProperties.hexCode" :disabled="true" pt:preview:class="!opacity-100" />
            <span>{{ data.additionalProperties.hexCode }}</span>
          </div>
        </template>
      </Column>
      <template #empty>{{ t("product.attribute.no-attributes-found") }}</template>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import { AttributeType } from "@/models/attribute/AttributeType";

const props = defineProps<{
  productAttributes: AttributeByLanguageIso[];
}>();

const { t } = useI18n();

const expandedRowGroups = ref();

const flattenedProductAttributes = computed(() => {
  return props.productAttributes.flatMap((attribute) =>
    attribute.values.map((value) => ({
      id: `${attribute.id}-${value.id}`,
      name: attribute.name,
      attributeType: attribute.attributeType,
      value: value.value,
      description: value.description,
      additionalProperties: value.additionalProperties,
    })),
  );
});
</script>
