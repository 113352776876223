import { type AxiosResponse } from "axios";
import { httpClient, type AuthHeaders } from "@multicase/portal-api";
import { type PriceGroup } from "./model/PriceGroup";

class PriceGroupApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != "undefined" && import.meta.env.VITE_APP_API_PRODUCT
        ? `${import.meta.env.VITE_APP_API_PRODUCT as string}/price-groups`
        : `${import.meta.env.VITE_APP_API_URL as string}/price-groups`;
  }

  public async getDefault(authHeaders: AuthHeaders): Promise<PriceGroup> {
    return await httpClient(this.uri, authHeaders)
      .get("/default")
      .then(({ data }: AxiosResponse) => data);
  }
}
const priceGroupApi = new PriceGroupApi();
export { priceGroupApi };
