<template>
  <div class="flex flex-col space-y-6 max-w-full mx-auto p-6">
    <div class="flex gap-4 mb-4 max-w-3xl mx-auto">
      <div class="bg-white rounded-lg p-4 shadow-sm w-48">
        <div class="flex flex-col">
          <div class="text-sm text-gray-600 mb-1">{{ t("product.import.validation.total") }}</div>
          <div class="text-2xl font-bold">{{ totalProducts }}</div>
        </div>
      </div>
      <div class="bg-white rounded-lg p-4 shadow-sm w-48">
        <div class="flex flex-col">
          <div class="text-sm text-gray-600 mb-1">{{ t("product.import.validation.valid") }}</div>
          <div class="text-2xl font-bold text-green-600">{{ validCount }}</div>
        </div>
      </div>
      <div class="bg-white rounded-lg p-4 shadow-sm w-48">
        <div class="flex flex-col">
          <div class="text-sm text-gray-600 mb-1">{{ t("product.import.validation.errors") }}</div>
          <div class="text-2xl font-bold text-red-600">{{ errorCount }}</div>
        </div>
      </div>
    </div>

    <div class="flex gap-6">
      <div v-if="errorCount > 0" class="w-1/3">
        <div class="bg-white rounded-lg shadow-sm">
          <div class="p-4 border-b">
            <h3 class="font-medium">{{ t("product.import.validation.error-summary") }}</h3>
          </div>
          <div class="overflow-y-auto" style="max-height: 65vh">
            <div v-for="error in errorSummary" :key="error.message + error.field" class="p-4 border-b last:border-b-0">
              <div class="flex items-center gap-2 mb-2">
                <i class="pi pi-exclamation-circle text-red-500"></i>
                <div class="font-medium">{{ error.message }}</div>
              </div>
              <div v-if="error.field" class="ml-6 mb-2">
                <Tag severity="warning">{{ error.field }}</Tag>
              </div>
              <div class="ml-6 flex flex-col gap-1">
                <div class="text-sm text-gray-600">
                  {{ error.count }} {{ error.count === 1 ? "occurrence" : "occurrences" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div :class="errorCount > 0 ? 'w-2/3' : 'w-full'">
        <div class="bg-white rounded-lg shadow-sm">
          <div class="p-4 border-b">
            <h2 class="text-xl font-semibold">{{ t("product.import.validation.preview") }}</h2>
          </div>
          <DataTable
            :value="products"
            :paginator="true"
            :rows="pageSize"
            :total-records="totalProducts"
            :loading="loading"
            class="p-datatable-sm"
            :row-class="getRowClass"
            :lazy="true"
            :current-page-report-template="
              t('common.current-page-template', {
                first: '{first}',
                last: '{last}',
                totalRecords: '{totalRecords}',
              })
            "
            paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            scrollable
            scroll-height="70vh"
            @page="onPageChange"
            @row-dblclick="onRowDoubleClick"
          >
            <Column
              v-for="field in displayFields"
              :key="field"
              :field="'product.' + field"
              :header="t('product.import.' + field.toLowerCase())"
              :sortable="true"
            >
              <template #body="{ data }">
                <div :class="{ 'text-red-600': data.hasErrors }">
                  {{ data.product[field] }}
                </div>
              </template>
            </Column>
            <Column header="Status" :sortable="false">
              <template #body="{ data }">
                <div v-if="data.hasErrors" class="text-red-600">
                  <i class="pi pi-exclamation-circle mr-2"></i>
                  {{ t("product.import.validation.has-errors") }}
                </div>
                <div v-else class="text-green-600">
                  <i class="pi pi-check-circle mr-2"></i>
                  {{ t("product.import.validation.valid") }}
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>

    <div class="flex justify-end gap-4">
      <Button
        :label="t('product.import.back')"
        icon="pi pi-arrow-left"
        class="p-button-secondary"
        @click="emit('back')"
      />
      <Button
        v-if="errorCount > 0"
        v-tooltip.bottom="{
          value: t('product.import.start-import-warning'),
          showDelay: 500,
          hideDelay: 300,
        }"
        :label="t('product.import.validation.start')"
        icon="pi pi-exclamation-triangle"
      />
      <Button
        v-else
        :label="t('product.import.validation.start')"
        icon="pi pi-check"
        data-testid="start-import-button"
        @click="emit('startImport')"
      />
    </div>

    <EditProductModal
      v-if="editProductModalVisible"
      v-model:visible="editProductModalVisible"
      :product="selectedProduct"
      :products="products"
      :import-id="importId"
      :errors="allErrors"
      @navigate="navigateProduct"
      @product-updated="onProductUpdated"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import EditProductModal from "./edit-product/EditProductModal.vue";
import { useProductValidationNavigation } from "@/product/composables/useProductValidationNavigation";
import { useProductImportSessionStore } from "@/product/stores/ProductImportSessionStore";

import type { MappedImportProduct } from "@/product/models/MappedImportProduct";
import type { ProductValidationViewModel } from "@/product/models/ProductValidationViewModel";
import type { ValidationError } from "@/product/models/ValidationError";
import type { DataTablePageEvent, DataTableRowDoubleClickEvent } from "primevue";
import { storeToRefs } from "pinia";

const props = defineProps<{
  ready: boolean;
}>();

const emit = defineEmits<{
  (e: "back"): void;
  (e: "startImport"): void;
}>();

const { t } = useI18n();
const sessionStore = useProductImportSessionStore();
const { getValidationResult } = sessionStore;
const { importId } = storeToRefs(sessionStore);

const products = ref<ProductValidationViewModel[]>([]);
const allErrors = ref<ValidationError[]>([]);
const totalProducts = ref(0);
const validCount = ref(0);
const errorCount = ref(0);
const loading = ref(false);
const currentPage = ref(1);
const pageSize = ref(25);

const selectedProduct = ref<ProductValidationViewModel | null>(null);
const editProductModalVisible = ref(false);

const displayFields = ["rowNumber", "productNumber", "name", "description", "productType", "productStatus"];
const totalErrors = computed(() => groupedErrors.value.reduce((sum, group) => sum + group.count, 0));

const { navigateProduct } = useProductValidationNavigation(products, selectedProduct);

const errorSummary = computed(() =>
  groupedErrors.value.map((error) => ({
    ...error,
    percentage: (error.count / totalErrors.value) * 100,
  })),
);

const fetchPage = async () => {
  if (!importId.value || !props.ready) return;

  loading.value = true;
  try {
    const response = await getValidationResult(importId.value, currentPage.value, pageSize.value);

    products.value = response.products;
    allErrors.value = response.allErrors;
    totalProducts.value = response.totalCount;
    validCount.value = response.validCount;
    errorCount.value = response.errorCount;
  } finally {
    loading.value = false;
  }
};

const groupedErrors = computed(() => {
  const errorGroups = allErrors.value.reduce(
    (groups, error) => {
      const key = `${error.message}-${error.field || ""}`;
      if (!groups[key]) {
        groups[key] = {
          message: error.message,
          field: error.field,
          count: 0,
          rows: new Set<number>(),
        };
      }
      groups[key].count++;
      if (error.rowNumber) {
        groups[key].rows.add(error.rowNumber);
      }
      return groups;
    },
    {} as Record<string, { message: string; field?: string; count: number; rows: Set<number> }>,
  );

  return Object.values(errorGroups).sort((a, b) => b.count - a.count);
});

const onPageChange = (event: DataTablePageEvent) => {
  currentPage.value = event.page + 1;
  fetchPage();
};

const getRowClass = (data: ProductValidationViewModel) => {
  return {
    "bg-red-50": data.hasErrors,
    "bg-green-50": !data.hasErrors,
    "cursor-pointer": true,
  };
};

const onRowDoubleClick = (event: DataTableRowDoubleClickEvent) => {
  selectedProduct.value = event.data;
  editProductModalVisible.value = true;
};

const onProductUpdated = async (updatedProduct: MappedImportProduct, navigationDirection?: "next" | "previous") => {
  await fetchPage();

  const updatedViewModel = products.value.find(
    (p: ProductValidationViewModel) => p.product.rowNumber === updatedProduct.rowNumber,
  );

  if (updatedViewModel) {
    selectedProduct.value = updatedViewModel;

    if (navigationDirection) {
      navigateProduct(navigationDirection);
    }
  }
};

watch(
  () => props.ready,
  (isReady) => {
    if (isReady) {
      currentPage.value = 1;
      fetchPage();
    }
  },
  { immediate: true },
);

onMounted(() => {
  if (props.ready) {
    fetchPage();
  }
});
</script>
