<template>
  <div class="c-layout-content flex flex-col c-layout-margin" data-testid="layout-content">
    <ConfirmDialog group="confirm-dialog" data-testid="confirm-dialog"></ConfirmDialog>
    <ConfirmPopup group="confirm-popup" data-testid="confirm-popup"></ConfirmPopup>
    <CustomToast />
    <PrimeVueLocaleHandler />
    <router-view v-slot="{ Component }">
      <KeepAlive include="ProductSearch">
        <component :is="Component" />
      </KeepAlive>
    </router-view>
    <DevOverlay v-if="isStandalone && !isRunningTests" />
  </div>
</template>

<script setup lang="ts">
import { PrimeVueLocaleHandler } from "@cumulus/locale";
import { onMounted, ref } from "vue";
import { CustomToast } from "@cumulus/components";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useAuth } from "@cumulus/event-bus";
import { DevOverlay } from "@cumulus/components";

const { t } = useI18n();
const router = useRouter();
const isStandalone = ref(import.meta.env.VITE_APP_STANDALONE === "true");
const isRunningTests = ref(import.meta.env.VITE_APP_TESTS === "true");

router.beforeEach((to, _, next) => {
  if (to.meta?.title) {
    document.title = t(to.meta?.title as string);
  }
  next();
});

if (isStandalone.value) {
  const { getUser, isAuthenticated } = useAuth();
  onMounted(async () => (document.body.className = (await getUser()).getBodyCssClass(isAuthenticated.value)));
}
</script>
