<template>
  <teleport v-if="isMounted" to="#teleport-product-toolbar">
    <div class="inline-block">
      <Button
        id="btn-commit"
        class="c-circular-button mr-4"
        data-testid="btn-commit"
        style="border-color: var(--p-button-outlined-secondary-border-color)"
        @click="emit('onSave')"
      >
        <i class="pi pi-check c-success-button c-circular-icon"></i>
        <span class="px-4 font-normal">{{ t("common.save") }}</span>
      </Button>
    </div>
  </teleport>
</template>

<script setup lang="ts">
const { t } = useI18n();

const emit = defineEmits<{
  (e: "onSave"): void;
}>();

const isMounted = ref(false);
onMounted(() => {
  isMounted.value = true;
});
</script>

<style scoped lang="scss">
.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
