<template>
  <FloatLabel variant="on">
    <InputText
      :id="`product-title-tag-${props.languageIso.toLowerCase()}`"
      :data-testid="`product-title-tag-${props.languageIso.toLowerCase()}`"
      v-model="titleTagComputed"
      type="text"
      class="w-full"
      :invalid="val.titleTag.$error"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('product-info-dialog.title-tag').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />
    <label :for="`product-title-tag-${props.languageIso.toLowerCase()}`">{{
      t("product-info-dialog.title-tag")
    }}</label>
  </FloatLabel>
  <small
    class="p-error"
    v-if="val.titleTag.$error"
    :data-testid="`product-title-tag-error-${props.languageIso.toLowerCase()}`"
  >
    {{ val.titleTag.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { required } from "@/locales/i18n-validators";
import { LanguageIsoType } from "@/models/language/LanguageIsoType";

const props = defineProps<{
  titleTag: string;
  languageIso: LanguageIsoType;
}>();

const emit = defineEmits<{
  (e: "update:titleTag", value: string): void;
}>();

const titleTagComputed = computed<string>({
  get: () => {
    return props.titleTag ?? "";
  },
  set: (value) => {
    emit("update:titleTag", value);
  },
});

const { t } = useI18n();

const rules = {
  titleTag: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
