<template>
  <FloatLabel variant="on">
    <InputText
      id="product-name"
      v-model="productNameComputed"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('product.name').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      data-testid="product-name"
      class="w-full"
      type="text"
      maxlength="8000"
    />
    <label for="product-name" class="c-required">{{ t("product.name") }}</label>
  </FloatLabel>
  <small v-if="val.name.$error" class="p-error" data-testid="product-name-error">
    {{ val.name.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();

const props = defineProps<{
  name: string;
}>();

const emit = defineEmits<{
  (e: "update:name", value: string): void;
}>();

const rules = {
  name: {
    required: required,
  },
};

const val = useVuelidate(rules, props);

const productNameComputed = computed<string>({
  get: () => {
    return props.name ? props.name : "";
  },
  set: (value) => {
    emit("update:name", value);
  },
});
</script>
