<template>
  <Dialog
    :visible="visibleDialog"
    :header="t('attribute.add-attribute')"
    :modal="true"
    data-testid="attribute-form-dialog"
    class="max-w-3xl mx-auto"
    @update:visible="onCloseDialog"
  >
    <div class="px-4">
      <ProgressBar v-if="isTranslating" mode="indeterminate" class="my-1 h-1"></ProgressBar>
      <div class="flex w-full">
        <Button
          type="button"
          class="p-button-text p-button-sm w-fit ml-auto mr-2 my-1"
          data-testid="add-attribute-btn"
          :disabled="isTranslating"
          @click="onTranslate"
        >
          <i class="pi pi-pencil c-default-button c-circular-icon"></i>
          <span>{{ t("common.auto-translate") }}</span>
        </Button>
      </div>
      <Tabs value="0" class="mb-6">
        <TabList>
          <Tab
            v-for="(lang, index) in languages"
            :key="index"
            :disabled="isTranslating"
            :value="index.toString()"
            :pt:root:data-testid="`attribute-form-tab-${lang}`"
          >
            {{ t(`common.languages.${lang.code.toLowerCase()}`) }}
          </Tab>
        </TabList>
        <TabPanel v-for="(lang, index) in languages" :key="index" :value="index.toString()">
          <div class="px-4 pt-4">
            <AttributeForm
              :disabled="isTranslating"
              :attribute="attribute"
              :attributeName="attributeNamesByLanguage[lang.code]"
              :attribute-values="attributeValuesByLanguage[lang.code]"
              :language-iso="lang.code"
              @set-attribute-name="setAttributeName"
              @set-attribute-value="setAttributeValue"
              @add-new-attribute-value="addNewAttributeValue"
              @delete-value="deleteValue"
            />
          </div>
        </TabPanel>
      </Tabs>
    </div>

    <template #footer>
      <AttributeFooter
        :disabled="isTranslating"
        :is-editing="false"
        :is-saving="isSaving"
        @save-clicked="onSave"
        @cancel-clicked="onCloseDialog"
      />
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { useToast } from "primevue/usetoast";
import { useCumulusToast, useUnsavedChanges } from "@cumulus/components";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { useAttribute } from "@/repositories/attribute/AttributeService";
import AttributeForm from "./AttributeForm.vue";
import { storeToRefs } from "pinia";
import { useAddAttributeStore } from "../stores/useAddAttributeStore";
import AttributeFooter from "./AttributeFooter.vue";
import { useProductLanguageFunctions } from "../../utils/productLanguageFunctions";

const visibleDialog = defineModel<boolean>("visibleDialog", {
  required: true,
});

const { t } = useI18n();
const val = useValidate();
const toast = useCumulusToast(useToast());
const isSaving = ref(false);
const isTranslating = ref(false);

const addAttributeStore = useAddAttributeStore();
const { attribute, attributeNamesByLanguage, attributeValuesByLanguage, isAttributeModified } =
  storeToRefs(addAttributeStore);
const { setAttributeName, setAttributeValue, addNewAttributeValue, deleteValue, resetStore, translate } =
  addAttributeStore;
const { languages } = useProductLanguageFunctions();

const { createAttribute } = useAttribute();

const onCloseDialog = () => {
  if (confirmClose()) {
    resetStore();
    visibleDialog.value = false;
  }
};

const onTranslate = async () => {
  isTranslating.value = true;
  try {
    await translate();
  } catch (e) {
    console.error(e);
  }
  isTranslating.value = false;
};

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  try {
    isSaving.value = true;
    await createAttribute(attribute.value);

    toast.add({
      severity: "success",
      summary: t("attribute.toast.add-success.summary"),
      closable: true,
    });

    resetStore();
    visibleDialog.value = false;
  } finally {
    isSaving.value = false;
  }
};

const { confirmClose } = useUnsavedChanges(isAttributeModified);
</script>
