<template>
  <Dialog
    v-model:visible="visible"
    content-class="h-full"
    modal
    closable
    :style="{ maxWidth: '1600px' }"
    @hide="$emit('update:visible', false)"
    @keydown.esc.stop="$emit('update:visible', false)"
  >
    <template #header>
      <div class="flex items-center justify-between">
        <h2 class="text-xl font-bold">{{ t("product.variants.set-new-images") }}</h2>
      </div>
    </template>

    <div class="grid grid-cols-1 gap-6 p-4">
      <div class="border rounded p-4">
        <h3 class="text-lg font-medium mb-4">
          {{ t("product.variants.selected-variants", { count: selectedVariants.length }) }}
        </h3>
        <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
          <div v-for="variant in selectedVariants" :key="variant.id" class="flex flex-col items-center">
            <span class="text-sm text-center mb-2">{{ variant.name }}</span>
            <Galleria
              v-if="variant.productImages.length > 0"
              :value="variant.productImages"
              :num-visible="5"
              container-style="width: 10rem"
              :show-thumbnails="false"
              :show-indicators="true"
            >
              <template #item="slotProps">
                <div class="relative w-full h-[100px]">
                  <img :src="slotProps.item.url" :alt="slotProps.item.name" class="w-full h-full object-contain" />
                </div>
              </template>
            </Galleria>
            <div v-else class="flex flex-col items-center justify-center w-40 h-[100px] bg-gray-100 rounded-lg">
              <i class="pi pi-image text-2xl text-gray-400 mb-2"></i>
              <span class="text-sm text-gray-400">{{ t("product.image.no-images-found") }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="border rounded p-4">
        <h3 class="text-lg font-medium mb-4">{{ t("product.variants.set-new-images") }}</h3>
        <div
          :class="
            newImages.length === 0
              ? 'grid grid-cols-1'
              : 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-6'
          "
        >
          <FileUploadCard @add-image="handleAddImage" />
          <TransitionGroup name="image-list" tag="div" class="contents">
            <div
              v-for="(image, index) in newImages"
              :key="image.url"
              class="relative"
              :class="{ 'z-10': draggedIndex === index }"
              draggable="true"
              @dragstart="startDrag($event, index)"
              @dragend="endDrag"
              @dragover.prevent="dragOver($event, index)"
              @dragenter.prevent="dragEnter(index)"
              @dragleave.prevent="dragLeave($event)"
              @drop.prevent="drop(index)"
            >
              <ImageCard
                :image="image"
                :index="index"
                :dragged-index="draggedIndex"
                :drag-over-index="dragOverIndex"
                @delete-image="handleDeleteImage(image.url)"
                @update-image="handleUpdateImage(index, $event)"
              />
            </div>
          </TransitionGroup>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button :label="t('common.cancel')" class="p-button-text" @click="$emit('update:visible', false)" />
        <Button :label="t('common.apply')" :disabled="newImages.length === 0" @click="applyImages" />
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { VariantProduct } from "@/repositories/product/model/VariantProduct";
import { ProductImage } from "@/repositories/product/model/ProductImage";
import ImageCard from "@/product/components/product-images/ImageCard.vue";
import FileUploadCard from "@/product/components/product-images/FileUploadCard.vue";

const props = defineProps<{
  selectedVariants: VariantProduct[];
}>();

const visible = defineModel<boolean>("visible", { required: true });

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
  (e: "updateVariants", variants: VariantProduct[]): void;
}>();

const { t } = useI18n();
const newImages = ref<ProductImage[]>([]);
const draggedIndex = ref<number | null>(null);
const dragOverIndex = ref<number | null>(null);

const handleAddImage = (image: ProductImage) => {
  newImages.value.push(image);
};

const handleDeleteImage = (imageUrl: string) => {
  newImages.value = newImages.value.filter((img) => img.url !== imageUrl);
};

const handleUpdateImage = (index: number, updatedImage: ProductImage) => {
  newImages.value[index] = updatedImage;
};

const applyImages = () => {
  const updatedVariants = props.selectedVariants.map((variant) => ({
    ...variant,
    productImages: [...newImages.value],
  }));

  emit("updateVariants", updatedVariants);
  emit("update:visible", false);
  newImages.value = [];
};

const startDrag = (event: DragEvent, index: number) => {
  if (event.dataTransfer) {
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/plain", index.toString());
  }
  draggedIndex.value = index;
};

const endDrag = () => {
  draggedIndex.value = null;
  dragOverIndex.value = null;
};

const dragEnter = (index: number) => {
  if (index !== draggedIndex.value) {
    dragOverIndex.value = index;
  }
};

const dragOver = (event: DragEvent, index: number) => {
  event.preventDefault();
  if (index !== draggedIndex.value) {
    dragOverIndex.value = index;
  }
};

const dragLeave = (event: DragEvent) => {
  if (event.target === event.currentTarget) {
    dragOverIndex.value = null;
  }
};

const drop = (index: number) => {
  if (draggedIndex.value !== null && index !== draggedIndex.value) {
    const newOrder = [...newImages.value];
    const [removed] = newOrder.splice(draggedIndex.value, 1);
    newOrder.splice(index, 0, removed);
    newImages.value = newOrder;
  }
  endDrag();
};
</script>
