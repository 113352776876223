<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button
        class="c-circular-button"
        data-testid="btn-import-product-hierarchy"
        disabled
        @click="emit('importProductHierarchyClicked')"
      >
        <i class="pi pi-file-import c-default-btn-bg c-circular-icon"></i>
        <span class="px-4">{{ t("product-hierarchy-toolbar.import-product-hierarchy") }}</span>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const emit = defineEmits<{
  (e: "importProductHierarchyClicked"): void;
}>();

const { t } = useI18n();
</script>

<style scoped lang="scss">
.c-default-btn-bg {
  background-color: var(--default-btn-bg);
}
</style>
