<template>
  <div class="c-page-content h-auto !overflow-hidden pb-1">
    <div class="grid grid-cols-12 mt-5 ml-5">
      <div class="c-transition" :class="showSidebar && !isMobile ? 'col-span-9 2xl:col-span-10' : 'col-span-12'">
        <Card>
          <template #title>
            <ProductToolbar
              v-model:selectedIcon="selectedIcon"
              :isSortingVisible="sortField !== ''"
              :isFilterVisible="showSidebar"
              @openSortList="openSortList"
              @refreshList="onRefreshList"
              @openColumnChooser="openColumnChooser"
              @onToggleFilterSidebar="onToggleFilterSidebar"
              @openProductInfoDialog="loadProductInfoDialog"
            />
          </template>

          <template #content>
            <div class="mx-4 my-0">
              <div class="flex items-left gap-2 mt-3">
                <FilterOverview
                  v-model:filters="filtersComputed"
                  :manufacturers="manufacturers"
                  :groupLevels="productGroupNames"
                />
              </div>

              <Suspense>
                <ProductList
                  ref="productListRef"
                  :products="products"
                  :loading="loading"
                  :totalHits="totalHits"
                  :pageSize="pageSize"
                  :page="page"
                  :sortField="sortField"
                  :sortOrder="sortOrder"
                  @update:sortOrder="onUpdateSortOrder"
                  @update:sortField="onUpdateSortField"
                  @update:page="onUpdatePage"
                  @update:pageSize="onUpdatePageSize"
                  @selectedIcon="setSelectedIcon"
                />
              </Suspense>
            </div>
          </template>
        </Card>
      </div>

      <div v-if="isMobile">
        <Drawer v-model:visible="showSidebar" :header="t('product.search-filters.filter')" position="right">
          <ProductFilterSidebar
            v-model:filters="filtersComputed"
            :manufacturers="manufacturers"
            :loading="loading"
            :groupLevels="productGroupNames"
            :productHierarchies="productHierarchies"
          />
        </Drawer>
      </div>

      <div
        v-else
        class="transition-all duration-300 ease-in-out"
        :class="showSidebar ? 'col-span-3 2xl:col-span-2' : 'w-0 hidden'"
      >
        <Card class="ml-5 min-h-full">
          <template #content>
            <div class="c-filter-header-container">
              <i class="pi pi-sliders-h"></i>
              <span class="ml-4 font-semibold text-xl">{{ t("product.search-filters.filter") }}</span>
            </div>

            <ProductFilterSidebar
              v-model:filters="filtersComputed"
              :manufacturers="manufacturers"
              :loading="loading"
              :groupLevels="productGroupNames"
              :productHierarchies="productHierarchies"
            />
          </template>
        </Card>
      </div>
    </div>

    <ProductInfoDialog v-model:visible="showProductInfoDialog" :products="selectedProducts" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onMounted, ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { type LocationQuery, useRoute, useRouter } from "vue-router";
import { Product } from "@/repositories/product/model/Product";
import { ProductSearchRequest } from "@/repositories/search/model/ProductSearchRequest";
import { useSearch } from "@/repositories/search/SearchService";
import { ProductSearchFilters } from "@/repositories/search/model/ProductSearchFilters";
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";
import { useManufacturer } from "@/repositories/manufacturer/ManufacturerService";
import { ProductStatus } from "@/repositories/product/model/ProductStatus";
import { useProductHierarchyStore } from "@/product-hierarchy/api/ProductHierarchyStore";
import { useMobile } from "@/utils/MobileService";
import ProductToolbar from "../search/ProductToolbar.vue";
import ProductList from "../components/ProductList.vue";
import ProductFilterSidebar from "../search/ProductFilterSidebar.vue";
import FilterOverview from "../search/FilterOverview.vue";
import ProductInfoDialog from "../components/product-info-dialog/ProductInfoDialog.vue";

const productHierarchyStore = useProductHierarchyStore();
const { productHierarchies, productGroupNames } = storeToRefs(useProductHierarchyStore());

const route = useRoute();
const { productSearch } = useSearch();

const showSidebar = ref(true);
const pageSize = ref<number>(50);
const loading = ref(false);
const query = ref<string | null>(null);
const products = ref<Product[]>([]);
const totalHits = ref(0);
const page = ref(1);
const sortField = ref("");
const sortOrder = ref(-1);

const selectedIcon = ref("");
const setSelectedIcon = (value: string) => {
  selectedIcon.value = value;
};

const filters = ref<ProductSearchFilters | null>(null);
const manufacturers = ref<Manufacturer[]>([]);

const { t } = useI18n();

const { isMobile } = useMobile();
watch(isMobile, (value) => {
  if (value == true) showSidebar.value = false;
});

const { getManufacturers } = useManufacturer();

watch(
  () => route.query,
  async (newQuery) => {
    if (newQuery.search !== undefined) {
      await loadSearchParams(newQuery);
    }
  },
);

onMounted(async () => {
  loading.value = true;

  loadSearchParams(route.query);
});

const loadSearchParams = async (locationQuery: LocationQuery) => {
  const q = decodeURIComponent((locationQuery.search as string) ?? "");
  const filter = setFiltersFromUrlParams(
    locationQuery.manufacturers as string,
    locationQuery.productStatuses as string,
    locationQuery.productNumbers as string,
    locationQuery.productNames as string,
    locationQuery.groupLevels as string,
  );

  if (q !== query.value || JSON.stringify(filter) !== JSON.stringify(filters.value)) {
    query.value = q;
    filters.value = filter;
    await search();
  }
};

const setFiltersFromUrlParams = (
  manufacturers: string,
  productStatuses: string,
  productNumbers: string,
  productNames: string,
  groupLevels: string,
): ProductSearchFilters => {
  const filters = new ProductSearchFilters();
  if (manufacturers) {
    filters.manufacturers = decodeURIComponent(manufacturers).split(";");
  }

  if (productStatuses) {
    filters.productStatuses = decodeURIComponent(productStatuses).split(";") as ProductStatus[];
  }

  if (productNumbers) {
    filters.productNumbers = decodeURIComponent(productNumbers).split(";");
  }

  if (productNames) {
    filters.productNames = decodeURIComponent(productNames).split(";");
  }
  if (groupLevels) {
    filters.groupLevels = decodeURIComponent(groupLevels).split(";");
  }
  return filters;
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  search();
};

const onUpdatePageSize = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  search();
};

const onUpdateSortOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const search = async () => {
  try {
    manufacturers.value = await getManufacturers();

    const request = new ProductSearchRequest(query.value ?? "");
    request.page = page.value;
    request.pageSize = pageSize.value;
    if (sortField.value === "") {
      sortOrder.value = -1;
    }
    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";

    request.filters = filters.value ?? new ProductSearchFilters();

    const response = await productSearch(request);
    products.value = response.products;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};

const onToggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const onRefreshList = async () => {
  loading.value = true;
  await search();
};

const filtersComputed = computed<ProductSearchFilters>({
  get: () => filters.value ?? new ProductSearchFilters(),
  set: async (value) => await onFilterUpdate(value),
});

const router = useRouter();

const onFilterUpdate = async (value: ProductSearchFilters) => {
  filters.value = value;
  const queries = JSON.parse(JSON.stringify(route.query));

  if (filters.value.manufacturers.length > 0) {
    queries.manufacturers = encodeURIComponent(filters.value.manufacturers.join(";"));
  } else {
    delete queries.manufacturers;
  }

  if (filters.value.productStatuses.length > 0) {
    queries.productStatuses = encodeURIComponent(filters.value.productStatuses.join(";"));
  } else {
    delete queries.productStatuses;
  }

  if (filters.value.productNumbers.length > 0) {
    queries.productNumbers = encodeURIComponent(filters.value.productNumbers.join(";"));
  } else {
    delete queries.productNumbers;
  }

  if (filters.value.productNames.length > 0) {
    queries.productNames = encodeURIComponent(filters.value.productNames.join(";"));
  } else {
    delete queries.productNames;
  }

  if (filters.value.groupLevels.length > 0) {
    queries.groupLevels = encodeURIComponent(filters.value.groupLevels.join(";"));
  } else {
    delete queries.groupLevels;
  }
  //Reload page with new filters from Url
  router.replace({ query: queries });
  await search();
};

onMounted(async () => await productHierarchyStore.getProductGroupNames());
onMounted(async () => await productHierarchyStore.getProductHierarchies());

const productListRef = ref();
const openColumnChooser = (event: Event) => {
  productListRef.value.openColumnChooser(event);
};

const openSortList = (event: Event) => {
  productListRef.value.openSortList(event);
};

const showProductInfoDialog = ref(false);
const selectedProducts = ref<Product[]>([]);
const loadProductInfoDialog = () => {
  selectedProducts.value =
    productListRef.value.selectedProducts.length > 0 ? productListRef.value.selectedProducts : products.value;
  showProductInfoDialog.value = true;
};
</script>

<style scoped lang="scss">
.c-product-search {
  margin: var(--default-content-margin);
  @media (min-width: 992px) {
    margin-bottom: 2rem;
  }
}
.max-height {
  max-height: 90vh;
}
</style>
