<template>
  <Stepper v-model:value="currentStep">
    <StepList>
      <Step value="1">{{ t("product.import.upload") }}</Step>
      <Step value="2">{{ t("product.import.map") }}</Step>
      <Step value="3">{{ t("product.import.review") }}</Step>
    </StepList>
    <StepPanels>
      <StepPanel value="1">
        <div class="max-w-6xl mx-auto p-6">
          <ProductImportUploader @file-uploaded="handleFileUploaded" />
          <RecentImports @open-import-session="handleOpenImportSession" />
        </div>
      </StepPanel>
      <StepPanel value="2">
        <div v-if="status !== ImportStatus.Idle">
          <ImportProgress :status="status" :progress="progressPercentage" :error="error" />
        </div>
        <div v-else class="h-[calc(100vh-8rem)] flex">
          <SystemFieldsPanel v-model:column-mappings="sessionStore.columnMappings" />
          <div class="flex-1 overflow-auto">
            <div class="p-6 min-w-[800px]">
              <MapColumns :loading="loading.importSessionPreview" />
              <div class="sticky bottom-0 left-0 right-0 bg-white pt-6 pb-4 border-t">
                <div class="flex justify-between">
                  <Button
                    :label="t('product.import.back')"
                    severity="secondary"
                    icon="pi pi-arrow-left"
                    @click="navigateToStep('1')"
                  />
                  <Button
                    :label="t('product.import.start-mapping')"
                    icon="pi pi-arrow-right"
                    icon-pos="right"
                    data-testid="start-mapping-button"
                    @click="startProcessing"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StepPanel>
      <StepPanel value="3">
        <div v-if="status === ImportStatus.Importing">
          <ImportProgress :status="status" :progress="progressPercentage" :error="error" />
        </div>
        <ValidationResults
          v-else
          :ready="currentStep === '3'"
          @back="navigateToStep('2')"
          @start-import="handleStartImport"
        />
      </StepPanel>
    </StepPanels>
  </Stepper>

  <ImportResultsDialog v-model:visible="showImportResults" :session="productImportSession" />
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useCumulusToast } from "@cumulus/components";
import { useToast } from "primevue";
import ProductImportUploader from "../components/import/ProductImportUploader.vue";
import MapColumns from "../components/import/MapColumns.vue";
import ImportProgress from "../components/import/ImportProgress.vue";
import ValidationResults from "../components/import/ValidationResults.vue";
import RecentImports from "../components/import/RecentImports.vue";
import SystemFieldsPanel from "../components/import/system-fields/SystemFieldsPanel.vue";
import ImportResultsDialog from "../components/import/ImportResultsDialog.vue";
import { ImportStatus, useProductImportProgress } from "../composables/productImportProgress";
import { useProductImportSessionStore } from "../stores/ProductImportSessionStore";
import { useProductImportOptionsStore } from "../stores/ProductImportOptionsStore";
import type { ProductImportMapRequest } from "../models/ProductImportMapRequest";
import type { ProductImportSession } from "../models/ProductImportSession";
import { useProductImportFields } from "../composables/productImportFields";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const toast = useCumulusToast(useToast());
const { status, progressPercentage, error } = useProductImportProgress();
const sessionStore = useProductImportSessionStore();
const optionsStore = useProductImportOptionsStore();
const { hasDefaultValue } = useProductImportFields();
const { importOptions } = storeToRefs(optionsStore);
const { loading } = storeToRefs(sessionStore);

const currentStep = ref<string>("1");
const showImportResults = ref(false);
const productImportSession = ref<ProductImportSession | null>(null);

defineProps<{
  id?: string;
  step?: string;
}>();

const isAllFieldsHandled = computed(() => {
  return sessionStore.systemFields
    .filter((field) => field.required)
    .every((field) => {
      return (
        sessionStore.columnMappings.some((mapping) => mapping.systemField === field.name) || hasDefaultValue(field.name)
      );
    });
});

const handleFileUploaded = () => {
  navigateToStep("2");
};

const handleOpenImportSession = async (id: string) => {
  const session = await sessionStore.getImportSession(id);
  productImportSession.value = session;
  showImportResults.value = true;
};

const startProcessing = async () => {
  if (!isAllFieldsHandled.value) {
    toast.add({
      severity: "warn",
      summary: t("product.import.required-mapping-missing-summary"),
      detail: t("product.import.required-mapping-missing-detail"),
      closable: true,
    });
    return;
  }

  status.value = ImportStatus.Mapping;
  progressPercentage.value = 0;

  try {
    const request: ProductImportMapRequest = {
      id: sessionStore.importId,
      columnMappings: sessionStore.columnMappings,
      options: importOptions.value,
    };

    await sessionStore.startMapping(request);

    const checkStatus = setInterval(() => {
      if (status.value === ImportStatus.ValidationComplete) {
        clearInterval(checkStatus);
        navigateToStep("3");
      } else if (status.value === ImportStatus.Failed) {
        clearInterval(checkStatus);
      }
    }, 1000);
  } catch (error) {
    console.error("Failed to start processing:", error);
  }
};

const handleStartImport = async () => {
  status.value = ImportStatus.Importing;
  progressPercentage.value = 0;

  try {
    await sessionStore.startImport(sessionStore.importId);

    const checkStatus = setInterval(async () => {
      if (status.value === ImportStatus.ImportComplete || status.value === ImportStatus.Failed) {
        clearInterval(checkStatus);
        navigateToStep("1");
        status.value = ImportStatus.Idle;
        await handleOpenImportSession(sessionStore.importId);
      }
    }, 1000);
  } catch {
    status.value = ImportStatus.Failed;
  }
};

const navigateToStep = (step: string) => {
  if (step === "2" && currentStep.value === "3") {
    status.value = ImportStatus.Idle;
  }

  if (!route.params.id && step === "2" && sessionStore.importId) {
    router.push({
      name: "product-import",
      params: {
        id: sessionStore.importId,
        step: "2",
      },
    });
    return;
  }

  router.push({
    name: "product-import",
    params: {
      ...(sessionStore.importId && step !== "1" ? { id: sessionStore.importId } : {}),
      ...(step !== "1" ? { step } : {}),
    },
  });
};

onMounted(async () => {
  await sessionStore.getSystemFields();
});

watch(
  () => [route.params.id, route.params.step],
  async ([newId, newStep]) => {
    let idChanged = false;

    if (newId && newId !== sessionStore.importId) {
      sessionStore.importId = newId as string;
      idChanged = true;

      sessionStore.resetState();
    }

    if (newStep) {
      currentStep.value = newStep as string;

      if (newStep === "2" && status.value !== ImportStatus.Mapping) {
        status.value = ImportStatus.Idle;
      }

      if (newStep === "2" && (idChanged || sessionStore.columns.length === 0)) {
        await sessionStore.getImportSessionPreview(sessionStore.importId);
      }
    } else {
      currentStep.value = "1";
    }
  },
  { immediate: true },
);
</script>

<style scoped>
:deep(.p-dropdown) {
  border: 1px solid #d1d5db;
}

:deep(.p-datatable-scrollable) {
  overflow-x: auto;
}

:deep(.p-steplist) {
  max-width: 50rem;
  width: 100%;
  margin: auto;
}
</style>
