import { ProductRelationType } from "@/repositories/product/model/ProductRelationType";
import { ProductRelation } from "@/repositories/product/model/ProductRelation";

export function useProductRelationManagement(options: {
  relations: Ref<ProductRelation[]>;
  fetchRelatedProducts: () => Promise<void>;
}) {
  const { relations, fetchRelatedProducts } = options;

  const addRelations = async (newRelations: ProductRelation[]) => {
    if (newRelations.length) {
      relations.value = relations.value.concat(newRelations);
      await fetchRelatedProducts();
    }
  };

  const removeRelation = (relationId: string) => {
    relations.value = relations.value.filter((r) => r.id !== relationId);
  };

  const setRelationType = (relationId: string, relationType: ProductRelationType) => {
    relations.value.forEach((r) => {
      if (r.id === relationId) r.relationType = relationType;
    });
  };

  return {
    addRelations,
    removeRelation,
    setRelationType,
  };
}
