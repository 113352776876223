import { computed } from "vue";
import { ProductRelationType } from "@/repositories/product/model/ProductRelationType";
import { ProductRelation } from "@/repositories/product/model/ProductRelation";
import { Product } from "@/repositories/product/model/Product";
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";

export function useProductPopupRelationManagement(
  selectedProducts: Ref<Product[]>,
  productRelationTypes: Ref<Map<string, ProductRelationType>>,
  searchResults: Ref<Product[]>,
  emit: (event: "addRelations" | "update:visible", value: ProductRelation[] | boolean) => void,
) {
  const { t } = useI18n();

  const rules = {
    products: {
      required: helpers.withMessage(t("product.relations.no-products-selected"), required),
      minLength: helpers.withMessage(t("product.relations.no-products-selected"), minLength(1)),
      hasRelationTypes: helpers.withMessage(t("product.relations.no-relation-type-selected"), (products: Product[]) =>
        products.every(
          (product) =>
            productRelationTypes.value.has(product.id) &&
            productRelationTypes.value.get(product.id) !== ProductRelationType.None,
        ),
      ),
    },
  };

  const validation = useVuelidate(rules, { products: selectedProducts });

  const canSave = computed(() => !validation.value.$invalid);

  const tooltipComputed = computed(() => {
    if (validation.value.products.$errors.length > 0) {
      return validation.value.products.$errors[0].$message;
    }
    return "";
  });

  const getProductRelationType = (product: Product) => {
    if (!productRelationTypes.value.has(product.id)) {
      productRelationTypes.value.set(product.id, ProductRelationType.AlternativeCrossSell);
    }
    return productRelationTypes.value.get(product.id);
  };

  const setProductRelationType = (product: Product, type: ProductRelationType) => {
    productRelationTypes.value.set(product.id, type);
  };

  const save = () => {
    const newRelations = selectedProducts.value.map(
      (product) =>
        new ProductRelation(
          product.id,
          productRelationTypes.value.get(product.id) || ProductRelationType.AlternativeCrossSell,
          uuidv4(),
        ),
    );

    emit("addRelations", newRelations);
    emit("update:visible", false);
    resetData();
  };

  const resetData = () => {
    selectedProducts.value = [];
    productRelationTypes.value.clear();
    searchResults.value = [];
  };

  return {
    canSave,
    tooltipComputed,
    getProductRelationType,
    setProductRelationType,
    resetData,
    save,
  };
}
