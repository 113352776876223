<template>
  <div class="flex items-center -mt-2 pb-2 border-b">
    <div class="flex-1 text-color font-semibold leading-6">
      <Button class="mr-8 text-sm" variant="text" size="small" @click="router.push({ name: 'product-add' })">
        <i class="pi pi-plus c-success-button c-circular-icon"></i>
        <span class="px-4">{{ t("product.title-add") }}</span>
      </Button>
      <Button
        class="mr-8 text-sm"
        aria-haspopup="true"
        aria-controls="overlay_menu"
        variant="text"
        size="small"
        @click="toggleActionMenu"
      >
        <i class="pi pi-cog c-default-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.options") }}</span>
      </Button>
      <Menu id="overlay_menu" ref="actionMenu" :model="actionMenuItems" :popup="true" />
    </div>

    <div>
      <Button
        v-tooltip.bottom="{
          value: t('common.toolbar.sorting'),
          showDelay: 1000,
          hideDelay: 100,
        }"
        data-testid="btn-selected-icon"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': props.isSortingVisible }"
        @click="emit('openSortList', $event)"
      >
        <i :class="selectedIcon"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        v-tooltip.bottom="{
          value: t('common.toolbar.refresh'),
          showDelay: 1000,
          hideDelay: 300,
        }"
        data-testid="c-refresh-button"
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('refreshList')"
      >
        <i class="pi pi-refresh"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        v-tooltip.bottom="{
          value: t('common.toolbar.column-chooser'),
          showDelay: 1000,
          hideDelay: 300,
        }"
        data-testid="btn-column-chooser"
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('openColumnChooser', $event)"
      >
        <i class="pi pi-list"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        v-tooltip.bottom="{
          value: t('common.toolbar.filter'),
          showDelay: 1000,
          hideDelay: 300,
        }"
        data-testid="filter-button"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': props.isFilterVisible }"
        @click="emit('onToggleFilterSidebar')"
      >
        <i class="pi pi-filter"></i>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { ProductHierarchyRoutes } from "@/product-hierarchy/routes";

const router = useRouter();
const { t } = useI18n();

const props = defineProps<{
  isSortingVisible: boolean;
  isFilterVisible: boolean;
  isProductSelected: boolean;
}>();

const toggleActionMenu = (event: Event) => {
  actionMenu.value.toggle(event);
};

const actionMenu = ref();
const actionMenuItems = ref([
  {
    label: t("attribute.label"),
    command: () => {
      if (props.isProductSelected) {
        emit("openAttributeDialog");
      } else {
        router.push({ name: "attributes" });
      }
    },
  },
  {
    label: t("product-hierarchy-toolbar.product-hierarchy"),
    command: () => {
      router.push({ name: ProductHierarchyRoutes.productHierarchies });
    },
  },
  {
    label: t("product-info-dialog.menu-title"),
    command: () => {
      emit("openProductInfoDialog");
    },
  },
  {
    label: t("product.image.menu-title"),
    command: () => {
      emit("openImageDialog");
    },
  },
  {
    label: t("product.relations.menu-title"),
    command: () => {
      emit("openRelationDialog");
    },
  },
  {
    label: t("product.import.title"),
    command: () => {
      router.push({ name: "product-import" });
    },
  },
]);

const emit = defineEmits<{
  (e: "openSortList", value: Event): void;
  (e: "refreshList"): void;
  (e: "openColumnChooser", value: Event): void;
  (e: "onToggleFilterSidebar"): void;
  (e: "openProductInfoDialog"): void;
  (e: "openImageDialog"): void;
  (e: "openRelationDialog"): void;
  (e: "openAttributeDialog"): void;
}>();

const selectedIcon = defineModel<string>("selectedIcon", { required: true, default: "pi pi-sort-amount-up" });
</script>
