<template>
  <ProductHeader
    :edit-mode="true"
    :has-updates="hasUpdates"
    :collapsed-panels="panelCollapsed"
    :show-dialog="showDialog"
    :show-ai-assisstance-button="true"
    @on-save="saveProduct"
    @on-delete="onDeleteProduct"
    @on-toggle-all-panels="onToggleAllPanels()"
    @update:show-dialog="showDialog = $event"
  />

  <Message v-if="loadFailed" closable severity="error" class="mx-5 my-3" pt:text:class="w-full">
    <div class="w-full">
      {{ t("common.loading-failed") }} {{ errorReason }}
      <Button
        :label="t('common.reload')"
        severity="danger"
        variant="text"
        icon="pi pi-refresh"
        icon-pos="right"
        pt:label:class="underline"
        class="ml-2"
        @click="reload"
      ></Button>
    </div>
  </Message>

  <div class="c-page-content mt-3 ml-5 pr-1 flex-grow" data-testid="edit-logistics-and-sales">
    <div class="grid grid-cols-12 gap-4 mb-4">
      <Card class="col-span-12 lg:col-span-8">
        <template #content>
          <div id="general-info" class="grid grid-cols-12 gap-4">
            <div class="col-span-12 lg:col-span-4">
              <ProductNumber v-model:product-number="product.productNumber" :focus="true" :is-editing="true" />
            </div>

            <div class="col-span-12 lg:col-span-4">
              <CumulusInputText v-model="product.gtin" test-id="product-gti-number" :label="t('product.gti-number')" />
            </div>

            <div class="col-span-12 lg:col-span-4">
              <CumulusInputText
                v-model="product.altProductNumber"
                test-id="alt-product-number"
                :label="t('product.alt-number')"
              />
            </div>

            <div class="col-span-12 lg:col-span-8">
              <ProductHierarchySearchInput v-model:product-hierarchy-id="product.productHierarchyId" />
            </div>
            <div class="col-span-12 lg:col-span-4">
              <CumulusSelect
                v-model="product.manufacturerId"
                test-id="manufacturer"
                :options="manufacturers"
                :label="t('product.manufacturer.label')"
                :show-button="true"
                :required="true"
                :default-select-first="true"
                @button-click="showManufacturerDialog = true"
              />
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-6">
              <ProductType v-model:product-type="product.productType" />
            </div>
            <div class="col-span-6">
              <CumulusSelect
                v-model="product.salesUnitId"
                test-id="sales-unit"
                :options="salesUnits"
                :label="t('product.sales-unit.label')"
                :show-button="true"
                :required="true"
                :default-select-first="true"
                @button-click="showSalesUnitDialog = true"
              />
            </div>
            <div class="col-span-6">
              <ProductStatus v-model:product-status="product.productStatus" :focus-dropdown="setFocusProductStatus" />
            </div>
            <div class="col-span-6">
              <CumulusSelect
                v-model="product.accountGroupId"
                test-id="account-group"
                :options="accountGroups"
                :label="t('product.account-group.label')"
                :show-button="true"
                :required="true"
                :default-select-first="true"
                @button-click="showAccountGroupDialog = true"
              />
            </div>
          </div>
        </template>
      </Card>
    </div>

    <div class="grid grid-cols-12 gap-4 mb-4">
      <Card class="col-span-12 lg:col-span-8">
        <template #title>
          <div class="w-full text-center">{{ t("product.product-info") }}</div>
        </template>
        <template #content>
          <div class="grid grid-cols-12 gap-y-4 mb-2">
            <div class="col-span-12">
              <Name v-model:name="product.name" />
            </div>
            <div class="col-span-12">
              <Description v-model:description="product.description" />
            </div>
            <div class="col-span-12">
              <ProductInfo v-model:product-info="product.productInfo" />
            </div>
          </div>
        </template>
      </Card>

      <div class="col-span-12 lg:col-span-4">
        <Card>
          <template #title>
            <div class="w-full text-center">{{ t("product.media-and-files") }}</div>
          </template>
          <template #content>
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12">
                <MediaTabs
                  v-model:product-images="product.productImages"
                  v-model:product-videos="product.media"
                  v-model:product-documents="product.documents"
                />
              </div>
            </div>
          </template>
        </Card>

        <Card class="mt-4">
          <template #title>
            <div class="w-full text-center">{{ t("product.dimension.label") }}</div>
          </template>
          <template #content>
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12">
                <DimensionAndWeight v-model:dimension="product.dimension" v-model:weight="product.weight" />
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>

  <AIProductInfoDialog
    v-if="showDialog"
    v-model:visible="showDialog"
    v-model:product="product"
    data-testid="description-dialog"
    @add-to-product-from-a-i-assistant="addToProductFromAIAssistant"
  />

  <SalesUnitDialog
    v-if="showSalesUnitDialog"
    v-model="product.salesUnitId"
    v-model:visible="showSalesUnitDialog"
    @update="onUpdateSalesUnits"
  />

  <ManufacturerDialog
    v-if="showManufacturerDialog"
    v-model="product.manufacturerId"
    v-model:visible="showManufacturerDialog"
    @update="onUpdateManufacturers"
  />

  <AccountGroupDialog
    v-if="showAccountGroupDialog"
    v-model="product.accountGroupId"
    v-model:visible="showAccountGroupDialog"
    @update="onUpdateAccountGroups"
  />
</template>

<script setup lang="ts">
import { useProductManager } from "@/product/composables/useProductManager";
import { Dimension } from "@/repositories/product/model/Dimension";
import { Weight } from "@/repositories/product/model/Weight";
import { SalesUnit } from "@multicase/portal-api/sales-unit";
import { Manufacturer } from "@multicase/portal-api/manufacturer";
import { AccountGroup } from "@multicase/portal-api/account-group";

const { t } = useI18n();
const { salesUnits } = useSalesUnits();
const { manufacturers } = useManufacturers();
const { accountGroups } = useAccountGroups();
const { reload, setFocusProductStatus, onDeleteProduct, errorReason, loadFailed, product, saveProduct, hasUpdates } =
  useProductManager();

const showSalesUnitDialog = ref(false);
const showManufacturerDialog = ref(false);
const showAccountGroupDialog = ref(false);

const onUpdateSalesUnits = async (items: SalesUnit[]) => {
  salesUnits.value = items;
};

const onUpdateManufacturers = async (items: Manufacturer[]) => {
  manufacturers.value = items;
};

const onUpdateAccountGroups = async (items: AccountGroup[]) => {
  accountGroups.value = items;
};

const showDialog = ref<boolean>(false);
const addToProductFromAIAssistant = (
  valueProductInfo: string,
  valueProductDescription: string,
  valueProductName: string,
  valueWeight: Weight,
  valueDimenstion: Dimension,
) => {
  product.value.productInfo = valueProductInfo;
  product.value.description = valueProductDescription;
  product.value.name = valueProductName;
  product.value.weight = valueWeight;
  product.value.dimension = valueDimenstion;

  showDialog.value = false;
};

const panelCollapsedWarehouseClient = ref(false);
const panelCollapsedSupplier = ref(false);
const panelCollapsedPricesAndFees = ref(false);
const panelCollapsedAttribues = ref(false);
const panelCollapsedVariants = ref(false);

const panelCollapsed = computed(() => {
  return (
    panelCollapsedWarehouseClient.value &&
    panelCollapsedSupplier.value &&
    panelCollapsedPricesAndFees.value &&
    panelCollapsedAttribues.value &&
    panelCollapsedVariants.value
  );
});

const onToggleAllPanels = () => {
  const newState = !panelCollapsed.value;
  panelCollapsedWarehouseClient.value = newState;
  panelCollapsedSupplier.value = newState;
  panelCollapsedPricesAndFees.value = newState;
  panelCollapsedAttribues.value = newState;
  panelCollapsedVariants.value = newState;
};
</script>
