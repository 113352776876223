<template>
  <div class="flex">
    <FloatLabel variant="on" class="w-full">
      <AutoComplete
        v-model="selectedProductHierarchy"
        v-tooltip.focus.bottom="{
          value: t('placeholder.type', { property: t('product.hierarchy.placeholder').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        class="w-full"
        :class="{ 'p-invalid': val.productHierarchyId.$error }"
        optionLabel="name"
        :suggestions="filteredProductHierarchies"
        dropdown
        forceSelection
        data-testid="product-hierarchy-input"
        pt:list:data-testid="product-hierarchy-input-list"
        @complete="search"
      >
        <template #option="slotProps">
          <div class="flex align-options-center">
            <div class="text-xs md:text-base w-3/12 md:w-48 whitespace-nowrap overflow-hidden text-ellipsis">
              {{ slotProps.option.level1 }}
            </div>
            <div
              v-if="slotProps.option.level2"
              class="text-xs md:text-base w-3/12 md:w-48 whitespace-nowrap overflow-hidden text-ellipsis"
            >
              <i class="pi pi-angle-double-right mx-2 text-xs md:text-base" style="color: var(--primary-color)"></i>
              {{ slotProps.option.level2 }}
            </div>
            <div
              v-if="slotProps.option.level3"
              class="text-xs md:text-base w-3/12 md:w-48 whitespace-nowrap overflow-hidden text-ellipsis"
            >
              <i class="pi pi-angle-double-right mx-2 text-xs md:text-base" style="color: var(--primary-color)"></i>
              {{ slotProps.option.level3 }}
            </div>
            <div
              v-if="slotProps.option.level4"
              class="text-xs md:text-base w-3/12 md:w-48 whitespace-nowrap overflow-hidden text-ellipsis"
            >
              <i class="pi pi-angle-double-right mx-2 text-xs md:text-base" style="color: var(--primary-color)"></i>
              {{ slotProps.option.level4 }}
            </div>
            <div
              v-if="slotProps.option.level5"
              class="text-xs md:text-base w-3/12 md:w-48 whitespace-nowrap overflow-hidden text-ellipsis"
            >
              <i class="pi pi-angle-double-right mx-2 text-xs md:text-base" style="color: var(--primary-color)"></i>
              {{ slotProps.option.level5 }}
            </div>
          </div>
        </template>
      </AutoComplete>
      <label
        for="product-hierarchy-input"
        :class="{ 'c-focused': isInputFocused, 'c-floating-label': selectedProductHierarchy !== null }"
      >
        {{ t(`product.hierarchy.label`) }}
      </label>
    </FloatLabel>

    <PrimeButton
      icon="pi pi-cog"
      class="p-button-rounded"
      data-testid="btn-hierarchy-config"
      tabindex="-1"
      @click="routeToProductHierarchyConfig"
    />
  </div>

  <small v-if="val.productHierarchyId.$error" class="p-error" data-testid="product-hierarchy-input-error">
    {{ val.productHierarchyId.$errors[0].$message }}
  </small>
</template>
<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { type AutoCompleteCompleteEvent } from "primevue/autocomplete";
import { useAuth } from "@cumulus/event-bus";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { ProductHierarchySearchRequest } from "@/repositories/search/model/ProductHierarchySearchRequest";
import { ProductHierarchyByName } from "@/product-hierarchy/model/ProductHierarchyByName";
import { useSearch } from "@/repositories/search/SearchService";
import { useProductHierarchyStore } from "@/product-hierarchy/api/ProductHierarchyStore";
import { ProductHierarchyRoutes } from "@/product-hierarchy/routes";
import { LanguageIsoType } from "@/models/language/LanguageIsoType";

const { t } = useI18n();
const router = useRouter();
const isInputFocused = ref(false);

const routeToProductHierarchyConfig = () => {
  window.open(router.resolve({ name: ProductHierarchyRoutes.productHierarchies }).href, "_blank");
};

const emit = defineEmits<{
  (e: "update:productHierarchyId", value: string): void;
}>();

const props = defineProps<{
  productHierarchyId: string;
}>();

const { getUser } = useAuth();
const languageIso = ref<LanguageIsoType>(LanguageIsoType.English);

onMounted(async () => {
  languageIso.value =
    ((await (await getUser()).getEmployee()?.languageCode) as LanguageIsoType) ?? LanguageIsoType.English;
});

const { productHierarchySearch } = useSearch();

const filteredProductHierarchies = ref<ProductHierarchyByName[]>([]);
const search = async (event: AutoCompleteCompleteEvent) => {
  const request = new ProductHierarchySearchRequest(event.query ? event.query : "*");
  request.pageSize = 100;
  request.page = 1;
  const response = await productHierarchySearch(request);
  filteredProductHierarchies.value = response.productHierarchies.map((productHierarchy) =>
    ProductHierarchyByName.create(productHierarchy, languageIso.value),
  );
};

const productHierarchyStore = useProductHierarchyStore();
const selectedProductHierarchy = ref<ProductHierarchyByName | null>(null);

watch(
  () => props.productHierarchyId,
  async (id) => {
    if (id == null || id === "") {
      selectedProductHierarchy.value = null;
      return;
    }

    const result = await productHierarchyStore.getProductHierarchy(id);
    if (!result) {
      selectedProductHierarchy.value = null;
      return;
    }
    selectedProductHierarchy.value = ProductHierarchyByName.create(result, languageIso.value);
  },
);

watch(
  () => selectedProductHierarchy.value,
  async (selected) => {
    if (selected == undefined) {
      emit("update:productHierarchyId", "");
    }

    if (selected instanceof ProductHierarchyByName) {
      emit("update:productHierarchyId", selected.id);
      return;
    }
  },
);

const rules = {
  productHierarchyId: {
    required: required,
  },
};

const val = useVuelidate(rules, props);
</script>
<style scoped lang="scss">
:deep(.p-button:focus) {
  box-shadow: none;
}
:deep(.p-button.p-button-icon-only) {
  width: 2rem;
}
:deep(.p-button.p-button-icon-only.p-button-rounded) {
  height: 2rem;
}
</style>
