<template>
  <div class="flex items-center mb-4">
    <div class="flex items-center gap-1 mr-3">
      <Button icon="pi pi-arrow-left" class="p-button-text" :disabled="isFirstProduct" @click="onPrevious" />
      <span v-if="currentProduct" class="mx-1 text-sm text-gray-600">{{ currentIndex + 1 }} / {{ totalProducts }}</span>
      <Button icon="pi pi-arrow-right" class="p-button-text" :disabled="isLastProduct" @click="onNext" />
    </div>

    <div v-if="currentProduct" class="flex items-center">
      <div class="h-5 w-[1px] bg-gray-300 mr-3"></div>
      <p class="text-base font-medium truncate max-w-lg">
        {{ currentProduct.productNumber }} - {{ currentProduct.name }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Product } from "@/repositories/product/model/Product";

const props = defineProps<{
  currentProduct: Product | null;
  currentIndex: number;
  totalProducts: number;
}>();

const emit = defineEmits<{
  (e: "previous"): void;
  (e: "next"): void;
}>();

const isFirstProduct = computed(() => props.currentIndex === 0);
const isLastProduct = computed(() => props.currentIndex >= props.totalProducts - 1);

const onPrevious = () => emit("previous");
const onNext = () => emit("next");
</script>
