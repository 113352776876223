<template>
  <div class="space-y-6">
    <div class="overflow-hidden">
      <Card class="product-variants-card">
        <template #content>
          <div class="flex items-center justify-between">
            <div class="flex items-center space-x-4">
              <div class="p-3 bg-indigo-50 rounded-xl">
                <i class="pi pi-box text-xl text-indigo-600"></i>
              </div>
              <div>
                <h3 class="text-xl font-semibold">{{ t("product.variants.product-variants.header") }}</h3>
                <p class="text-sm mt-1">{{ t("product.variants.product-variants.sub-header") }}</p>
              </div>
            </div>
          </div>

          <div v-if="product.variantAttributes?.length" class="mt-6">
            <h4 class="text-sm font-medium mb-2">{{ t("product.variants.product-variants.variant-attributes") }}</h4>
            <div class="flex flex-wrap gap-2">
              <Chip
                v-for="attr in product.variantAttributes"
                :key="attr.attributeId"
                :label="getAttributeName(attr.attributeId)"
                class="variant-attribute-chip"
              />
            </div>
          </div>
        </template>
      </Card>

      <DataTable
        v-if="childProducts.length"
        :value="childProducts"
        class="p-datatable-sm"
        :rowHover="true"
        responsiveLayout="stack"
        stripedRows
      >
        <Column field="productNumber" :header="t('product.variants.product-variants.header')">
          <template #body="{ data }">
            <div
              class="flex items-center gap-4 py-2 cursor-pointer transition-colors"
              @click="navigateToProduct(data.id)"
            >
              <div class="w-14 h-14 bg-gray-100 rounded-lg overflow-hidden flex items-center justify-center">
                <img
                  v-if="data.productImages?.[0]?.url"
                  :src="data.productImages[0].url"
                  class="w-full h-full object-cover"
                  :alt="data.name"
                />
                <i v-else class="pi pi-image text-gray-400 text-xl"></i>
              </div>
              <div>
                <div class="font-medium">{{ data.name }}</div>
                <div class="text-sm">{{ data.productNumber }}</div>
              </div>
            </div>
          </template>
        </Column>

        <Column :header="t('product.variants.product-variants.variant-attributes')">
          <template #body="{ data }">
            <div class="flex flex-wrap gap-1.5">
              <span
                v-for="attr in data.attributes"
                :key="attr.attributeId"
                class="inline-flex items-center px-2 py-1 rounded-full text-xs bg-blue-50 text-blue-700 border border-blue-100"
              >
                {{ getAttributeValueLabel(attr) }}
              </span>
            </div>
          </template>
        </Column>

        <Column field="productStatus" :header="t('product.variants.product-variants.status')">
          <template #body="{ data }">
            <Tag :severity="getStatusSeverity(data.productStatus)" class="rounded-full px-3 py-1">
              {{ data.productStatus }}
            </Tag>
          </template>
        </Column>

        <Column style="width: 80px">
          <template #body="{ data }">
            <div class="flex justify-end">
              <PrimeButton
                icon="pi pi-chevron-right"
                text
                rounded
                class="text-gray-400 hover:text-gray-600"
                @click="navigateToProduct(data.id)"
              />
            </div>
          </template>
        </Column>
      </DataTable>

      <div v-else class="p-6 text-center text-gray-500">
        {{ t("product.variants.product-variants.no-variants") }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { Product } from "@/repositories/product/model/Product";
import { ProductStatus } from "@/repositories/product/model/ProductStatus";
import { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import { ProductAttribute } from "@/repositories/product/model/ProductAttribute";
import { useI18n } from "vue-i18n";

const router = useRouter();
const { t } = useI18n();

const props = defineProps<{
  product: Product;
  childProducts: Product[];
  attributes: AttributeByLanguageIso[];
}>();

const navigateToProduct = (id: string) => {
  const url = router.resolve({ path: `/product/edit/${id}` }).href;
  window.open(url, "_blank");
};

const getStatusSeverity = (status: ProductStatus) => {
  const severityMap = {
    [ProductStatus.Active]: "success",
    [ProductStatus.Inactive]: "warning",
    [ProductStatus.Discontinued]: "danger",
  };
  return severityMap[status] ?? "info";
};

const getAttributeName = (attributeId: string) => {
  const attribute = props.attributes.find((attr) => attr.id === attributeId);
  return attribute?.name ?? t("product.variants.unknown-attribute");
};

const getAttributeValueLabel = (attr: ProductAttribute) => {
  const attribute = props.attributes.find((a) => a.id === attr.attributeId);
  const value = attribute?.values.find((v) => attr.attributeValueIds.includes(v.id));
  return `${attribute?.name}: ${value?.value ?? t("common.unknown")}`;
};
</script>
