<template>
  <div
    v-for="level in numberOfLevelsUsed"
    :key="level"
    class="col-span-12"
    :data-testid="`c-product-group-level-${level}`"
  >
    <SelectGroupLevel
      :label="t(`product.product-group.level-${level}`)"
      :selected-level-id="getGroupLevelIdForSelectedLevel(level)"
      :product-group-names="getOptionsForLevel(level, languageIso)"
      :level="level"
      :group-levels="groupLevels"
      :selected-group-level-info="selectedGroupLevelInfo"
      :language-iso="languageIso"
      @product-group-selected="setGroupForLevel(level, $event)"
      @update:selected-group-level-info="updateSelectedGroupLevelInfo"
    />
  </div>
</template>

<script setup lang="ts">
import SelectGroupLevel from "./SelectGroupLevel.vue";
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import { useProductGroupFunctions } from "@/utils/productGroupFunctions";
import { useAuth } from "@cumulus/event-bus";
import { GroupLevelInfo } from "@/product-hierarchy/model/GroupLevelInfo";
import { ProductGroupName } from "@/product-hierarchy/model/ProductGroupName";
import { ProductHierarchy } from "@/product-hierarchy/model/ProductHierarchy";
import { useProductLanguageFunctions } from "@/utils/productLanguageFunctions";

const { t } = useI18n();
const { getUser } = useAuth();
const languageIso = ref<string>("en-GB");

const productHierarchies = defineModel<ProductHierarchy[]>("productHierarchies");

const groupLevelInfo = ref<GroupLevelInfo>(new GroupLevelInfo());
const { numberOfLevelsUsed, setGroupForLevel, getGroupLevelIdForSelectedLevel, getOptionsForLevel } =
  useProductGroupFunctions(productHierarchies, groupLevelInfo);
const { transformLanguageCode } = useProductLanguageFunctions();

const props = defineProps<{
  selectedGroupLevelInfo: string[];
  groupLevels: ProductGroupName[];
}>();

const emit = defineEmits<{
  (event: "update:selectedGroupLevelInfo", value: string[]): void;
}>();

const updateSelectedGroupLevelInfo = (newVal: string[]) => {
  const updatedInfo = [...props.selectedGroupLevelInfo, ...newVal];
  emit("update:selectedGroupLevelInfo", updatedInfo);
};

onMounted(async () => {
  const userLanguage = (await getUser()).getEmployee()?.languageCode ?? "en-GB";
  languageIso.value = transformLanguageCode(userLanguage);
});
</script>
