<template>
  <Dialog
    v-model:visible="visible"
    :modal="true"
    :style="{
      width: showSplitView ? `110vw` : `50vw`,
      maxWidth: `90%`,
      height: `63vh`,
      maxHeight: `95%`,
    }"
    :breakpoints="{ '1199px': '85vw', '575px': '90vw' }"
    :closable="!isSaving"
    :close-on-escape="!isSaving"
    class="c-form-dialog"
    pt:content:class="h-full"
    @keydown.escape.stop="visible = false"
  >
    <template #header>
      <div class="flex justify-center w-full">
        <div class="font-bold whitespace-nowrap">
          {{ t("product-info-dialog.title") }} :: {{ currentProduct?.productNumber }} - {{ currentProduct?.name }}
        </div>
      </div>
    </template>

    <div class="grid grid-cols-12 gap-6 pt-1 h-full">
      <div class="col-span-12" :class="!showSplitView ? 'lg:col-span-12' : 'lg:col-span-6'">
        <ProductInfoPanel
          v-model:product-language="activeProductLanguages"
          v-model:selected-language="firstPanelSelectedLanguage"
          :languages="languages"
          :has-split-view="true"
          :show-split-view="showSplitView"
          :product-index="currentIndex"
          :total-products="totalCount"
          @previous-product="previousProduct"
          @next-product="next"
          @toggle-split-view="showSplitView = !showSplitView"
        />
      </div>

      <div v-if="showSplitView" class="col-span-12 lg:col-span-6">
        <ProductInfoPanel
          v-model:product-language="activeProductLanguages"
          v-model:selected-language="secondPanelSelectedLanguage"
          :languages="languages"
          :show-split-view="showSplitView"
          :has-split-view="false"
          :product-index="currentIndex"
          :total-products="totalCount"
          set-selected-language="en-gb"
          @previous-product="previousProduct"
          @next-product="nextProduct"
          @toggle-split-view="showSplitView = !showSplitView"
        />
      </div>
    </div>

    <template #footer>
      <div class="flex justify-center gap-4 pt-2">
        <Button
          type="button"
          data-testid="ai-btn-ok"
          class="c-default-button"
          :disabled="isSaving"
          :label="totalCount > 1 ? t('common.save-all') : t('common.save')"
          @click="save"
        >
        </Button>
        <Button
          data-testid="ai-btn-cancel"
          severity="cancel"
          text
          :disabled="isSaving"
          :label="t('common.cancel')"
          @click="visible = false"
        >
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import { useProductStore } from "@/repositories/product/ProductStore";
import { useProductLanguageStore } from "@/stores/ProductLanguageStore";
import { useProductSearchNavigation } from "@/product/composables/useProductSearchNavigation";
import { Product } from "@/repositories/product/model/Product";
import { Language, ProductWithLanguage } from "@/product/models/ProductWithLanguage";
import ProductInfoPanel from "./ProductInfoPanel.vue";

const { t } = useI18n();

const visible = defineModel<boolean>("visible", { required: true, default: false });
const showSplitView = ref(false);
const firstPanelSelectedLanguage = ref("nb-NO");
const secondPanelSelectedLanguage = ref("en-GB");

const props = defineProps<{
  products: Product[];
  selectedProducts: Product[];
}>();

const productLanguageStore = useProductLanguageStore();
const { languages, defaultLanguage } = storeToRefs(productLanguageStore);
const { updateProductsDefaultLanguage } = useProductStore();

const toast = useCumulusToast(useToast());
const isSaving = ref(false);

const allProductsRef = computed(() => props.products);
const selectedProductsRef = computed(() => props.selectedProducts);

const { currentIndex, currentProduct, totalCount, next, previous, resetCurrentIndex } = useProductSearchNavigation(
  allProductsRef,
  selectedProductsRef,
);

const activeProductLanguages = ref(new ProductWithLanguage());

const loadProductData = async (productId: string) => {
  if (!productId) return;

  const productData = await productLanguageStore.getProductLanguages(productId);
  if (productData) {
    activeProductLanguages.value = productData;
    ensureRequiredLanguages();
  }
};

watch(visible, async (newValue) => {
  if (newValue) {
    resetCurrentIndex();
    productLanguageStore.resetStore();
    await productLanguageStore.getAvailableLanguages();
    await nextTick();

    if (currentProduct.value) {
      await loadProductData(currentProduct.value.id);
    } else {
      activeProductLanguages.value = new ProductWithLanguage();
      activeProductLanguages.value.languages = [];
    }
  }
});

watch(currentProduct, async (product) => {
  if (!product || !visible.value) return;
  await loadProductData(product.id);
});

const save = async () => {
  try {
    isSaving.value = true;
    const changedProductsLanguages = productLanguageStore.getChangedProductsLanguages();
    await productLanguageStore.saveChanges();
    await updateProductsDefaultLanguage(changedProductsLanguages, defaultLanguage.value);

    toast.add({
      severity: "success",
      summary: t("product-info-dialog.toast.success.summary"),
      detail: t("product-info-dialog.toast.success.detail"),
      closable: true,
    });

    visible.value = false;
  } finally {
    isSaving.value = false;
  }
};

const ensureRequiredLanguages = () => {
  if (!activeProductLanguages.value || !Array.isArray(activeProductLanguages.value.languages)) {
    activeProductLanguages.value = new ProductWithLanguage();
    activeProductLanguages.value.languages = [];
  }

  languages.value.forEach((language) => {
    if (activeProductLanguages.value.languages.some((l) => l.languageIso === language.code)) return;
    addLanguage(language.code);
  });
};

const nextProduct = async () => {
  const nextProd = next();
  if (nextProd) await loadProductData(nextProd.id);
};

const previousProduct = async () => {
  const prevProd = previous();
  if (prevProd) await loadProductData(prevProd.id);
};

const addLanguage = (languageIso: string) => {
  if (!activeProductLanguages.value.languages) {
    activeProductLanguages.value.languages = [];
  }

  if (activeProductLanguages.value.languages.some((l) => l.languageIso === languageIso)) return;

  const language = new Language();
  language.languageIso = languageIso;

  if (languageIso === defaultLanguage.value && currentProduct.value) {
    language.name = currentProduct.value.name || "";
    language.description = currentProduct.value.description || "";
    language.productInfo = currentProduct.value.productInfo || "";
  } else {
    language.name = "";
    language.description = "";
    language.productInfo = "";
  }

  activeProductLanguages.value.languages.push(language);
};
</script>
