<template>
  <Dialog
    v-model:visible="visible"
    :header="t('product.attribute.menu-title')"
    :modal="true"
    :closable="true"
    class="p-fluid w-[90vw] max-w-[1200px]"
  >
    <div class="flex flex-col h-full">
      <ProductRelationNavigation
        v-tooltip="getSaveButtonTooltip(productAttributes)"
        :current-product="currentProduct"
        :current-index="currentIndex"
        :total-products="selectedProducts.length"
        :has-updates="enableSaveButton"
        :disabled="isSaving"
        @previous="previous"
        @next="next"
        @close="close"
      />
      <div v-if="isSaving" class="flex justify-center items-center py-4 text-gray-600">
        <i class="pi pi-spin pi-spinner text-primary text-2xl mr-2"></i>
        <span>{{ t("common.saving") }}...</span>
      </div>
      <div v-if="true" class="flex flex-col gap-4">
        <div class="flex justify-between items-center mb-4">
          <Button
            v-tooltip="getAddButtonTooltip()"
            class="flex items-center justify-center rounded-full border border-gray-300 hover:bg-primary-50"
            data-testid="btn-add-attribute"
            :disabled="!hasAvailableAttributesToAdd || hasEmptyEditingRow"
            @click="addNewAttribute"
          >
            <i class="pi pi-plus text-green-600 mr-2"></i>
            <span class="px-4 font-normal">{{ t("common.add") }}</span>
          </Button>
        </div>
        <ProductAttributeTable
          :attributes="productAttributes"
          :available-attributes="availableAttributes"
          :validate-attribute="validateAttribute"
          :get-attribute-values="getAttributeValues"
          :get-available-attributes-for-selection="getAvailableAttributesForSelection"
          :on-attribute-selected="onAttributeSelected"
          :edit-attribute="editAttribute"
          :handle-save-attribute-row="handleSaveAttributeRow"
          :on-confirm-delete="onConfirmDelete"
          :is-attribute-valid="isAttributeValid"
        />
      </div>
      <div v-else class="flex justify-center items-center py-8">
        <div class="text-center">
          <i v-if="loading" class="pi pi-spin pi-spinner text-primary text-4xl mb-3"></i>
          <template v-else>
            <i class="pi pi-exclamation-triangle text-yellow-500 text-4xl mb-3"></i>
            <p class="text-gray-600">{{ t("product.no-products-available") }}</p>
          </template>
        </div>
      </div>
      <div class="flex justify-end gap-2 mt-4 pt-4 border-t">
        <Button :label="t('common.close')" icon="pi pi-times" class="p-button-outlined" @click="close" />
        <Button
          :label="t('common.save')"
          icon="pi pi-save"
          :disabled="!enableSaveButton"
          @click="handleSaveAttributes"
        />
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, ref, watch, onMounted } from "vue";
import { Product } from "@/repositories/product/model/Product";
import ProductRelationNavigation from "../ProductPopupDialogNavigation.vue";
import ProductAttributeTable from "./ProductAttributeTable.vue";
import { useProductSearchNavigation } from "../../composables/useProductSearchNavigation";
import { useUnsavedChanges } from "@cumulus/components";
import { useProductAttributeManagement } from "../../composables/product-attributes/useProductAttributeManagement";
import { useProductAttributeValidation } from "../../composables/product-attributes/useProductAttributeValidation";
import isEqual from "lodash.isequal";
import { useProductAttributeTable } from "../../composables/product-attributes/useProductAttributeTable";
import type { ProductAttributeRow } from "@/models/attribute/ProductAttributeRow";

const props = defineProps<{
  selectedProducts: Product[];
}>();

const { t } = useI18n();
const visible = defineModel<boolean>("visible", { required: true });

const allProductsRef = computed(() => props.selectedProducts);
const selectedProductsRef = ref<Product[]>([]);

const {
  currentIndex,
  currentProduct,
  next: nextProduct,
  previous: previousProduct,
  resetCurrentIndex,
} = useProductSearchNavigation(allProductsRef, selectedProductsRef);

const {
  loading,
  isSaving,
  availableAttributes,
  originalAttributes,
  loadProductAttributes,
  loadAttributes,
  saveAttributes,
} = useProductAttributeManagement();

const { validateAttribute, getSaveButtonTooltip } = useProductAttributeValidation();

const {
  productAttributes,
  hasAvailableAttributesToAdd,
  hasEmptyEditingRow,
  addNewAttribute,
  resetEditingState,
  getAttributeValues,
  getAvailableAttributesForSelection,
  onAttributeSelected,
  editAttribute,
  handleSaveAttributeRow,
  onConfirmDelete,
  isAttributeValid,
  getAddButtonTooltip,
} = useProductAttributeTable(availableAttributes, validateAttribute);

const compareAttributesWithoutErrors = (a: ProductAttributeRow[], b: ProductAttributeRow[]) => {
  const cleanA = a.map(({ ...rest }) => {
    delete rest.errors;
    return rest;
  });
  const cleanB = b.map(({ ...rest }) => {
    delete rest.errors;
    return rest;
  });
  return isEqual(cleanA, cleanB);
};

const hasUpdates = computed(() => !compareAttributesWithoutErrors(productAttributes.value, originalAttributes.value));

const enableSaveButton = computed(() => {
  if (!hasUpdates.value || isSaving.value) {
    return false;
  }

  if (productAttributes.value.some((attr) => attr.isEditing)) {
    return false;
  }

  if (productAttributes.value.some((attr) => !attr.attributeId || attr.attributeValueIds.length === 0)) {
    return false;
  }

  return true;
});

const { confirmClose } = useUnsavedChanges(hasUpdates);

const next = () => {
  if (confirmClose()) {
    resetEditingState();
    nextProduct();
  }
};

const previous = () => {
  if (confirmClose()) {
    resetEditingState();
    previousProduct();
  }
};

const close = async () => {
  if (confirmClose()) {
    visible.value = false;
  }
};

const handleSaveAttributes = async () => {
  if (currentProduct.value) {
    const validationResults = await Promise.all(
      productAttributes.value.map((attr) =>
        validateAttribute({
          attributeId: attr.attributeId,
          attributeValueIds: attr.attributeValueIds,
        }),
      ),
    );

    const isValid = validationResults.some((result) => result.valid);
    if (isValid) {
      await saveAttributes(currentProduct.value, productAttributes.value);
    }
  }
};

watch(
  () => currentProduct.value,
  (newProduct) => {
    if (newProduct) {
      loadProductAttributes(newProduct, productAttributes);
    }
  },
);
watch(visible, async (newValue) => {
  if (!newValue) {
    resetEditingState();
    resetCurrentIndex();
    productAttributes.value = [];
  } else if (currentProduct.value) {
    await loadProductAttributes(currentProduct.value, productAttributes);
  }
});

onMounted(async () => {
  await loadAttributes();
  if (currentProduct.value) {
    await loadProductAttributes(currentProduct.value, productAttributes);
  }
});
</script>
