import { type AxiosResponse } from "axios";
import { httpClient, type AuthHeaders } from "@multicase/portal-api";
import { ProductHierarchy } from "../model/ProductHierarchy";
import { NewProductGroupName } from "../model/NewProductGroupName";
import { ProductGroupName } from "../model/ProductGroupName";

class ProductHierarchyApi {
  private uri = "";

  constructor() {
    this.uri = import.meta.env.VITE_APP_API_PRODUCT
      ? import.meta.env.VITE_APP_API_PRODUCT
      : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async getProductHierarchies(authHeaders: AuthHeaders): Promise<ProductHierarchy[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/product-hierarchies")
      .then(({ data }: AxiosResponse) => data);
  }

  public async addProductHierarchy(authHeaders: AuthHeaders, productHierarchy: ProductHierarchy): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("/product-hierarchies", productHierarchy);
  }

  public async updateProductHierarchy(authHeaders: AuthHeaders, productHierarchy: ProductHierarchy): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/product-hierarchies", productHierarchy);
  }

  public async deleteProductHierarchy(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete("/product-hierarchies/" + id);
  }

  public async getProductGroupNames(authHeaders: AuthHeaders): Promise<ProductGroupName[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/product-group-names")
      .then(({ data }: AxiosResponse) => data);
  }

  public async addProductGroupName(authHeaders: AuthHeaders, productGroupName: NewProductGroupName): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("/product-group-names", productGroupName);
  }

  public async updateProductGroupName(authHeaders: AuthHeaders, productGroupName: ProductGroupName): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/product-group-names", productGroupName);
  }

  public async deleteProductGroupName(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete("/product-group-names/" + id);
  }
}

const productHierarchyApi = new ProductHierarchyApi();
export { productHierarchyApi };
