<template>
  <div class="place-items-center mr-10">
    <div class="w-full">
      <FloatLabel variant="on">
        <PrimeTextarea
          v-model="selectedReferance"
          id="ai-product-referance"
          data-testid="ai-product-referance"
          :autoResize="true"
          rows="1"
          cols="30"
          type="text"
          class="inputfield w-full md:w-80 py-2"
          :maxlength="1000"
          :isRequired="true"
          autofocus="true"
          v-tooltip.focus.bottom="{
            value: placeholderText,
            showDelay: 1000,
            hideDelay: 300,
          }"
        />
        <label for="ai-product-referance">{{ t("product.ai-search.referanse.label") }}</label>
      </FloatLabel>
    </div>
  </div>
  <small class="p-error ml-8" v-if="val.referance.$error" data-testid="ai-product-referance-error">
    {{ val.referance.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

const { t } = useI18n();
const selectedReferance = defineModel<string>("referance", {
  required: true,
});

const placeholderText = computed(() => {
  return t("product.ai-search.referanse.product-link");
});

const rules = computed(() => {
  return {
    referance: {
      required: required,
    },
  };
});

const val = useVuelidate(rules, { referance: selectedReferance });
</script>
