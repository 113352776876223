<template>
  <div class="pt-4 mx-4">
    <InputText
      ref="groupLevelSearchInput"
      v-model="filters['global'].value"
      :placeholder="t('product.product-group.filter')"
      class="w-full r2ounded-none"
      :data-testid="`c-product-group-filter-${level}`"
      @keydown.enter.stop="focusFirstRow"
    />
    <span class="p-button bg-transparent border-none z-10 -ml-12"><i class="pi pi-filter text-zinc-500" /></span>
  </div>
  <DataTable
    ref="searchResult"
    v-model:filters="filters"
    :value="productGroupNames"
    data-key="id"
    class="c-datatable"
    :loading="loading"
    :striped-rows="true"
    selection-mode="single"
    tabindex="0"
    responsive-layout="scroll"
    :global-filter-fields="['global', 'name']"
    scrollable
    scroll-height="400px"
    :data-testid="`c-purchase-product-group-list-${level}`"
    @row-select="onRowSelected"
  >
    <Column field="name" :header="t('product.product-group.label')" filter-field="name" sortable>
      <template #body="{ data, field, index }">
        <span :data-testid="`product-group-${field}-row-${index}`">
          {{ data[field as keyof typeof data] }}
        </span>
      </template>
    </Column>

    <template #empty>
      {{ t("product.product-group.no-found") }}
    </template>
  </DataTable>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { FilterMatchMode } from "@primevue/core/api";
import { ProductGroupNameByLanguage } from "@/product-hierarchy/model/ProductGroupNameByLanguage";
import { type DataTableRowSelectEvent } from "primevue/datatable";
import { ProductHierarchy } from "@/product-hierarchy/model/ProductHierarchy";

defineProps<{
  productGroupNames: ProductGroupNameByLanguage[];
  productHierarchies: ProductHierarchy[];
  loading: boolean;
  level: number;
}>();

const emit = defineEmits<{
  (event: "productGroupSelected", data: ProductGroupNameByLanguage): void;
}>();

const { t } = useI18n();
const groupLevelSearchInput = ref();

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
});

const onRowSelected = (event: DataTableRowSelectEvent) => {
  emit("productGroupSelected", event.data);
};

const focusSearchInput = () => {
  groupLevelSearchInput.value?.$el.focus();
};

const searchResult = ref();
const focusFirstRow = () => {
  searchResult.value.$el.querySelector("tbody tr:first-of-type")?.focus();
};

defineExpose({
  focusSearchInput,
});
</script>
