<template>
  <PrimeDialog
    v-model:visible="visible"
    @keydown.escape.stop="visible = false"
    :modal="true"
    :header="
      visibleProperty ? t('product.ai-search.header.edit-ai-prompt') : t('product.ai-search.header.ai-assistant')
    "
    :style="{ width: '110vw', maxWidth: '1100px', height: 'auto', maxHeight: '90%' }"
    :contentStyle="{ padding: '0', overflow: 'auto' }"
    :autoZIndex="false"
    bazeZIndex="1000"
    :closable="false"
  >
    <div class="grid grid-cols-12 gap-4 mt-2 !text-lg mx-6">
      <div class="col-span-12 lg:col-span-4">
        <div>
          <div class="mb-8 mr-5">
            <SelectPrompt
              v-model:promptRef="promptRef"
              v-model:prompts="prompts"
              v-model:visibleProperty="visibleProperty"
              @editPrompt="editPrompt($event)"
            ></SelectPrompt>
          </div>

          <div v-if="!visibleProperty">
            <div class="mb-6 mr-10">
              <ProductReference v-model:referance="selectedAiProductReferance"></ProductReference>
            </div>
            <div class="mb-8 mr-10">
              <Language v-model:aiProductLanguage="selectedAiProductLanguage"></Language>
            </div>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="w-4/5">
            <div v-if="chooseVersion && !visibleProperty">
              <Version v-model:version="selectedAiVersion"></Version>
            </div>
            <div>
              <PrimeButton
                type="button"
                @click="handleOk"
                :loading="loading"
                data-testid="btn-generate-text"
                class="px-6"
                :class="['c-success-button', { 'no-click': visibleProperty }]"
                :label="t('product.ai-search.generate-text')"
                icon="pi pi-search"
              >
              </PrimeButton>
            </div>
          </div>
          <div class="w-1/5" v-if="!visibleProperty">
            <PrimeButton
              severity="secondary"
              data-testid="btn-ai-chooser"
              aria-haspopup="true"
              aria-controls="overlay_menu"
              @click="chooseVersion = !chooseVersion"
              outlined
              :pt:root:class="`bg-white ml-6 p-3.5`"
            >
              <i class="pi pi-cog"></i>
            </PrimeButton>
          </div>
        </div>
      </div>
      <div v-if="visibleProperty" class="col-span-12 lg:col-span-8">
        <AiPromptForm
          v-model:prompt="promptRef"
          v-model:visibleProperty="visibleProperty"
          @refreshPrompts="getPrompts"
        ></AiPromptForm>
      </div>
      <div :class="['col-span-12 lg:col-span-8', { 'no-click': isDisabled }]" v-if="!visibleProperty">
        <div>
          <AiResultName v-model:name="aiNameResult" v-model:transferName="transferName"></AiResultName>
        </div>
        <div>
          <AiResultDescription
            v-model:description="aiProductDesc"
            v-model:transferDescription="transferDescription"
          ></AiResultDescription>
        </div>
        <div class="my-8">
          <AiResultInfo v-model:info="productInfo" v-model:transferInfo="transferInfo"></AiResultInfo>
        </div>
        <div class="col-span-12 lg:col-span-7">
          <label class="col-span-12 c-faded-label mx-1">{{ t("product.ai-search.netto") }}:</label>
          <AiResultUnit
            v-model:dimension="aiWeight.value"
            v-model:transfer="transferWeight"
            :label="t('product.dimension.weight')"
            unit="kg"
          ></AiResultUnit>
          <AiResultUnit
            v-model:dimension="aiDimension.length"
            v-model:transfer="transferLength"
            :label="t('product.dimension.length')"
            unit="cm"
          ></AiResultUnit>
          <AiResultUnit
            v-model:dimension="aiDimension.width"
            v-model:transfer="transferWidth"
            :label="t('product.dimension.width')"
            unit="cm"
          ></AiResultUnit>
          <AiResultUnit
            v-model:dimension="aiDimension.height"
            v-model:transfer="transferHeight"
            :label="t('product.dimension.height')"
            unit="cm"
          ></AiResultUnit>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-center gap-4 mt-10" v-if="!visibleProperty">
        <PrimeButton @click="visible = false" data-testid="ai-btn-cancel" severity="cancel" text>
          <i class="pi pi-times"></i>
          <span class="px-4 font-bold">{{ t("common.cancel") }}</span>
        </PrimeButton>
        <PrimeButton
          type="button"
          data-testid="ai-btn-ok"
          @click="onAddToProductInfo"
          class="c-success-button mr-8"
          :disabled="!hasText"
        >
          <i class="pi pi-check"></i>
          <span class="px-4">{{ t("common.ok") }}</span>
        </PrimeButton>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useAiProduct } from "@/product-hierarchy/api/ai-product-info/AiProductService";
import { AIVersions } from "@/models/product-info/AIVersions";
import { useToast } from "primevue/usetoast";
import useVuelidate from "@vuelidate/core";
import { useCumulusToast } from "@cumulus/toast";
import Version from "./Version.vue";
import SelectPrompt from "./SelectPrompt.vue";
import Language from "./Language.vue";
import ProductReference from "./ProductReference.vue";
import AiResultName from "./AiResultName.vue";
import AiResultDescription from "./AiResultDescription.vue";
import { Dimension } from "@/repositories/product/model/Dimension";
import { UnitOfSize } from "@/repositories/product/model/UnitOfSize";
import { Weight } from "@/repositories/product/model/Weight";
import { UnitOfWeight } from "@/repositories/product/model/UnitOfWeight";
import AiResultInfo from "./AiResultInfo.vue";
import AiResultUnit from "./AiResultUnit.vue";
import { AIPrompt } from "@/models/product-info/AIPrompts";
import AiPromptForm from "./AiPromptForm.vue";

const toast = useCumulusToast(useToast());
const { t } = useI18n();
const prompts = ref<AIPrompt[]>([]);

const { generateProductDescription, getAllPromps } = useAiProduct();

const props = defineProps<{
  showDialog: boolean;
  product: {
    productNumber: string;
    productInfo: string;
    description: string;
    name: string;
    weight: Weight;
    dimension: Dimension;
    accountGroupId: string;
  };
}>();

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
  (
    e: "addToProductFromAIAssistant",
    valueInfo: string,
    valueDesc: string,
    valueName: string,
    valueWeight: Weight,
    valueDimenstion: Dimension,
  ): void;
}>();

const visible = computed({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const isDisabled = computed(() => productInfo.value.length === 0);

const selectedAiVersion = ref(AIVersions.OpenAIGPT4o_mini);
const selectedAiProductLanguage = ref("Norwegian");
const hasText = computed(() => productInfo.value.length > 0);
const selectedAiProductInfo = ref(t("product.ai-search.prod-info-text"));
const productInfo = ref("");
const selectedAiProductReferance = ref("");
const aiNameResult = ref("");
const aiProductDesc = ref("");
const loading = ref(false);
const visibleProperty = ref(false);
const chooseVersion = ref(false);
const promptRef = ref<AIPrompt>(new AIPrompt());

const defaultDimensionUnit = UnitOfSize.M;
const aiDimension = ref({
  unitOfSize: defaultDimensionUnit,
  length: 0,
  width: 0,
  height: 0,
} as Dimension);

const defaultWeightUnit = UnitOfWeight.Kg;
const aiWeight = ref({
  unitOfWeight: defaultWeightUnit,
  value: 0,
} as Weight);

const val = useVuelidate();

const handleOk = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  try {
    loading.value = true;

    const res = await generateProductDescription(
      selectedAiVersion.value,
      selectedAiProductReferance.value,
      selectedAiProductInfo.value,
      selectedAiProductLanguage.value,
    );
    aiNameResult.value = res.name;
    aiProductDesc.value = res.description;
    productInfo.value = res.information;
    aiWeight.value = {
      unitOfWeight: UnitOfWeight.Kg,
      value: parseFloat(res.weight),
    };
    aiDimension.value = {
      unitOfSize: UnitOfSize.Cm,
      length: parseFloat(res.length),
      width: parseFloat(res.width),
      height: parseFloat(res.height),
    };
  } finally {
    loading.value = false;
  }
};
const transferName = ref(props.product.name !== "" ? false : true);
const transferDescription = ref(props.product.description !== "" ? false : true);
const transferInfo = ref(props.product.productInfo !== "" ? false : true);
const transferWeight = ref(props.product.weight.value > 0 ? false : true);
const transferLength = ref(props.product.dimension.length > 0 ? false : true);
const transferWidth = ref(props.product.dimension.width > 0 ? false : true);
const transferHeight = ref(props.product.dimension.height > 0 ? false : true);

const onAddToProductInfo = () => {
  const dimension = {
    unitOfSize: UnitOfSize.Cm,
    length: transferLength.value ? aiDimension.value.length : props.product.dimension.length,
    width: transferWidth.value ? aiDimension.value.width : props.product.dimension.width,
    height: transferHeight.value ? aiDimension.value.height : props.product.dimension.height,
  };
  emit(
    "addToProductFromAIAssistant",
    transferInfo.value ? productInfo.value : props.product.productInfo,
    transferDescription.value ? aiProductDesc.value : props.product.description,
    transferName.value ? aiNameResult.value : props.product.name,
    transferWeight.value ? aiWeight.value : { unitOfWeight: UnitOfWeight.Kg, value: props.product.weight.value },
    dimension,
  );
  visible.value = false;
};

const editPrompt = async (editPrompt: AIPrompt) => {
  promptRef.value = editPrompt;
  visibleProperty.value = true;
};

const getPrompts = async () => {
  prompts.value = await getAllPromps();
};

onMounted(async () => {
  await Promise.all([getPrompts()]);
});
</script>

<style scoped>
.c-float-wrapper input {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.no-click {
  pointer-events: none;
  opacity: 0.6;
}
</style>
