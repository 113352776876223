<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button id="btn-cancel" data-testid="btn-cancel" size="small" variant="text" @click="routeToProductList">
        <i class="pi pi-arrow-left"></i>
        <span class="px-2">{{ product.productNumber }} - {{ product.name }}</span>
      </Button>
    </div>

    <CumulusTabNavigation
      v-model="changeTabTo"
      i18-prefix="product.tabs"
      :tab-options="tabOptions"
      :tab-controls="true"
    />
  </div>

  <ProgressBar v-if="isProcessing" style="top: 0; height: 5px" class="w-full my-2" mode="indeterminate" />

  <template v-if="selectedTab === 'product-information'">
    <ProductInformation />
  </template>

  <template v-if="selectedTab === 'logistics-and-sales'">
    <ProductLogisticsAndSales />
  </template>

  <template v-if="selectedTab === 'product-relations'">
    <ProductRelations />
  </template>

  <template v-if="selectedTab === 'attributes'">
    <ProductAttributesTab />
  </template>

  <template v-if="selectedTab === 'variants'">
    <ProductVariants />
  </template>
</template>

<script setup lang="ts">
import { Product } from "@/repositories/product/model/Product";
import { useProductStore } from "@/repositories/product/ProductStore";
import { useTabNavigation } from "@cumulus/components";

const tabOptions = ref(["product-information", "logistics-and-sales", "product-relations", "attributes", "variants"]);

const route = useRoute();
const router = useRouter();

const { getProduct } = useProductStore();
const product = ref(new Product());
const { isProcessing, selectedTab, changeTabTo } = useTabNavigation(tabOptions.value[0]);

const fetchProductData = async () => {
  const productId = route.params.id as string;
  product.value = await getProduct(productId);
};

onMounted(async () => {
  await fetchProductData();
});

const routeToProductList = () => {
  if (window.history.state.back === null || window.history.state.back.indexOf("/product/search") === -1) {
    router.push({ name: "product-search", query: { search: "" } });
  } else {
    const url = new URL(window.location.origin + window.history.state.back);
    url.searchParams.set("focusResult", product.value.id);
    router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
  }
};

const paramsToObject = (entries: URLSearchParams) => {
  const result = {} as Record<string, string>;
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};
</script>
