import type { Product } from "@/repositories/product/model/Product";

export function useProductSearchNavigation(allProducts: Ref<Product[]>, selectedProducts: Ref<Product[]>) {
  const currentIndex = ref(0);

  const resetCurrentIndex = () => {
    currentIndex.value = 0;
  };

  const navigationProducts = computed(() => {
    return selectedProducts.value.length > 0 ? selectedProducts.value : allProducts.value;
  });

  const currentProduct = computed(() => {
    if (navigationProducts.value.length === 0) return null;

    const safeIndex = Math.min(Math.max(0, currentIndex.value), navigationProducts.value.length - 1);
    return navigationProducts.value[safeIndex];
  });

  const totalCount = computed(() => {
    return navigationProducts.value.length;
  });

  const next = () => {
    if (navigationProducts.value.length === 0) return null;

    currentIndex.value = currentIndex.value < navigationProducts.value.length - 1 ? currentIndex.value + 1 : 0; // Circular navigation

    return currentProduct.value;
  };

  const previous = () => {
    if (navigationProducts.value.length === 0) return null;

    currentIndex.value = currentIndex.value > 0 ? currentIndex.value - 1 : navigationProducts.value.length - 1; // Circular navigation

    return currentProduct.value;
  };

  return {
    currentIndex,
    currentProduct,
    totalCount,
    next,
    previous,
    resetCurrentIndex,
  };
}
