import type { AttributeValueByLanguageIso } from "@/models/attribute/AttributeValueByLanguageIso";
import type { ProductAttributeRow } from "@/models/attribute/ProductAttributeRow";
import type { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";

export const getValueForLanguage = (value: AttributeValueByLanguageIso, languageIso: string) => {
  return value.additionalProperties[languageIso] || value.value;
};

export const isAttributeValid = ({ attributeId, attributeValueIds }: ProductAttributeRow): boolean =>
  Boolean(attributeId && attributeValueIds.length);

export class ProductAttributeRowFactory {
  static createEmpty(): ProductAttributeRow {
    return {
      attributeId: "",
      attributeName: "",
      attributeValueIds: [],
      value: "",
      isEditing: true,
    };
  }

  static createFromAttribute(
    attribute: AttributeByLanguageIso,
    valueIds: string[] = [],
    locale: string,
  ): ProductAttributeRow {
    const selectedValues = attribute.values.filter((v: AttributeValueByLanguageIso) => valueIds.includes(v.id));
    return {
      attributeId: attribute.id,
      attributeName: attribute.name,
      attributeValueIds: valueIds,
      value: selectedValues.map((v: AttributeValueByLanguageIso) => getValueForLanguage(v, locale)).join(", "),
      isEditing: false,
    };
  }
}
