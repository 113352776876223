import { ref, computed, type Ref } from "vue";
import { useConfirm } from "primevue/useconfirm";
import { useI18n } from "vue-i18n";
import type { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import { ProductAttributeRowFactory } from "./useAttributeUtils";
import type { ProductAttributeRow } from "@/models/attribute/ProductAttributeRow";
import { getValueForLanguage, isAttributeValid } from "./useAttributeUtils";

export function useProductAttributeTable(
  availableAttributes: Ref<AttributeByLanguageIso[]>,
  validateAttribute: (data: {
    attributeId: string;
    attributeValueIds: string[];
  }) => Promise<{ valid: boolean; errors: { attributeId: string; attributeValueIds: string } }>,
) {
  const { t, locale } = useI18n();
  const confirm = useConfirm();
  const productAttributes = ref<ProductAttributeRow[]>([]);

  const getNameForLanguage = (attribute: AttributeByLanguageIso) => {
    return attribute.name;
  };

  const selectedAttributeIds = computed(
    () => new Set(productAttributes.value.map((attr) => attr.attributeId).filter(Boolean)),
  );

  const hasAvailableAttributesToAdd = computed(() =>
    availableAttributes.value.some((attr) => !selectedAttributeIds.value.has(attr.id)),
  );

  const hasEmptyEditingRow = computed(() =>
    productAttributes.value.some((attr) => attr.isEditing && !attr.attributeId),
  );

  const addNewAttribute = () => {
    productAttributes.value.push(ProductAttributeRowFactory.createEmpty());
  };

  const resetEditingState = () => {
    productAttributes.value.forEach((attr) => (attr.isEditing = false));
  };

  const getAttributeValues = (attributeId: string) =>
    availableAttributes.value.find((attr) => attr.id === attributeId)?.values ?? [];

  const getAvailableAttributesForSelection = () => {
    const selectedAttributeIds = new Set(
      productAttributes.value.filter((attr) => attr.attributeId && !attr.isEditing).map((attr) => attr.attributeId),
    );

    return availableAttributes.value.filter((attr) => !selectedAttributeIds.has(attr.id));
  };

  const onAttributeSelected = (attribute: ProductAttributeRow) => {
    const selectedAttribute = availableAttributes.value.find((attr) => attr.id === attribute.attributeId);
    if (selectedAttribute) {
      attribute.attributeName = getNameForLanguage(selectedAttribute);
      attribute.attributeValueIds = [];
      attribute.value = "";
    }
  };

  const editAttribute = (attribute: ProductAttributeRow) => {
    attribute.isEditing = true;
  };

  const handleSaveAttributeRow = async (attribute: ProductAttributeRow) => {
    const { valid, errors } = await validateAttribute({
      attributeId: attribute.attributeId,
      attributeValueIds: attribute.attributeValueIds,
    });

    attribute.errors = errors;
    if (!valid) {
      return;
    }

    const selectedAttribute = availableAttributes.value.find((attr) => attr.id === attribute.attributeId);
    if (selectedAttribute) {
      attribute.attributeName = getNameForLanguage(selectedAttribute);
      attribute.value = selectedAttribute.values
        .filter((val) => attribute.attributeValueIds.includes(val.id))
        .map((val) => getValueForLanguage(val, locale.value))
        .join(", ");
    }

    attribute.isEditing = false;
  };

  const onConfirmDelete = (event: Event, attribute: ProductAttributeRow) => {
    confirm.require({
      target: event.currentTarget as HTMLElement,
      group: "confirm-popup",
      message: t("common.delete-confirm"),
      icon: "pi pi-exclamation-triangle !text-2xl",
      acceptClass: "ml-4 p-button-danger",
      rejectClass: "p-button-text",
      acceptLabel: t("common.yes"),
      rejectLabel: t("common.no"),
      defaultFocus: "accept",
      accept: () => {
        productAttributes.value = productAttributes.value.filter((attr) => attr.attributeId !== attribute.attributeId);
      },
    });
  };

  const getAddButtonTooltip = () => {
    if (hasEmptyEditingRow.value) {
      return t("product.attribute.validation.complete-editing-row");
    } else if (!hasAvailableAttributesToAdd.value) {
      return t("product.attribute.validation.all-attributes-already-used");
    }
    return "";
  };

  return {
    productAttributes,
    hasAvailableAttributesToAdd,
    hasEmptyEditingRow,
    addNewAttribute,
    resetEditingState,
    getAttributeValues,
    getAvailableAttributesForSelection,
    onAttributeSelected,
    editAttribute,
    handleSaveAttributeRow,
    onConfirmDelete,
    isAttributeValid,
    getAddButtonTooltip,
  };
}
