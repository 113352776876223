import { useAccountGroupApi } from "@multicase/portal-api/account-group";
import { AddAccountGroupRequest, AccountGroup } from "@multicase/portal-api/account-group";
import { useVuelidate } from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import type { AuthHeaders } from "@multicase/portal-api";

export function useAccountGroups() {
  const accountGroup = ref<AccountGroup>(new AccountGroup());
  const accountGroups = ref<AccountGroup[]>([]);

  const accountGroupApi = useAccountGroupApi();
  const toast = useCumulusToast(useToast());
  const { getRequest, mutationRequest, loading, saving, error } = useRequests();

  const fetchAccountGroups = async () => {
    return await getRequest(async (authHeaders: AuthHeaders) => {
      accountGroups.value = await accountGroupApi.getAll(authHeaders);
    });
  };

  const fetchAccountGroupById = async (id: string) => {
    await getRequest(async (authHeaders: AuthHeaders) => {
      accountGroup.value = await accountGroupApi.get(authHeaders, id);
      accountGroupUnmodified.value = cloneDeep(accountGroup.value);
    });
  };

  const accountGroupUnmodified = ref<AccountGroup>(new AccountGroup());
  const isChanged = computed(() => {
    return !isEqual(accountGroup.value, accountGroupUnmodified.value);
  });

  const createAccountGroup = async (request: AddAccountGroupRequest): Promise<string> => {
    let newId = "";
    if (!(await isFormValid())) {
      return "";
    }

    await mutationRequest(async (authHeaders: AuthHeaders) => {
      newId = await accountGroupApi.create(authHeaders, request);
      const item = new AccountGroup();
      item.id = newId;
      item.name = request.name;
      accountGroups.value.push(item);
    });

    if (error.value) {
      toast.add({
        severity: "warn",
        summary: t("product.account-group.toast.error-create.summary"),
        detail: t("product.account-group.toast.error-create.detail"),
        closable: true,
      });
      console.error(error.value);
      return "";
    }

    toast.add({
      severity: "success",
      summary: t("product.account-group.toast.success-create.summary"),
      detail: t("product.account-group.toast.success-create.detail", { name: accountGroup.value.name }),
      closable: true,
    });

    return newId;
  };

  const updateAccountGroup = async (accountGroup: AccountGroup): Promise<boolean> => {
    if (!(await isFormValid())) {
      return false;
    }

    await mutationRequest(async (authHeaders: AuthHeaders) => {
      await accountGroupApi.update(authHeaders, accountGroup);

      const index = accountGroups.value.findIndex((su) => su.id === accountGroup.id);
      if (index !== -1) {
        accountGroups.value[index] = accountGroup;
        accountGroupUnmodified.value = cloneDeep(accountGroup);
      }
    });

    if (error.value) {
      toast.add({
        severity: "warn",
        summary: t("product.account-group.toast.error-update.summary"),
        detail: t("product.account-group.toast.error-update.detail"),
        closable: true,
      });
      return false;
    }

    toast.add({
      severity: "success",
      summary: t("product.account-group.toast.success-update.summary"),
      detail: t("product.account-group.toast.success-update.detail", { name: accountGroup.name }),
      closable: true,
    });
    return true;
  };

  const deleteAccountGroup = async (id: string): Promise<boolean> => {
    await mutationRequest(async (authHeaders: AuthHeaders) => {
      await accountGroupApi.delete(authHeaders, id);
    });

    const index = accountGroups.value.findIndex((su) => su.id === id);
    let name = "";
    if (index > -1) {
      name = accountGroups.value[index].name;
      accountGroups.value.splice(index, 1);
    }

    if (error.value) {
      toast.add({
        severity: "error",
        summary: t("product.account-group.toast.error-delete.summary"),
        closable: true,
      });
      return false;
    }

    toast.add({
      severity: "success",
      summary: t("product.account-group.toast.success-delete.summary"),
      detail: t("product.account-group.toast.success-delete.detail", { name: name }),
      closable: true,
    });
    return true;
  };

  const { t } = useI18n();
  const validate = useVuelidate();
  const isFormValid = async (): Promise<boolean> => {
    validate.value.$touch();

    if (!(await validate.value.$validate())) {
      toast.add({
        severity: "warn",
        summary: t("common.toast.warn.summary"),
        detail: t("common.toast.warn.detail"),
        closable: true,
      });
      return false;
    }
    return true;
  };

  onMounted(fetchAccountGroups);

  return {
    accountGroups,
    accountGroup,
    isChanged,
    fetchAccountGroupById,
    fetchAccountGroups,
    createAccountGroup,
    updateAccountGroup,
    deleteAccountGroup,
    loading,
    saving,
    error,
  };
}
