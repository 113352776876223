<template>
  <FloatLabel variant="on">
    <Select
      id="attribute-state"
      v-model="attributeState"
      class="inputfield w-full"
      :options="Object.keys(AttributeState)"
      :disabled="disabled"
      :data-testid="`attribute-state-${languageIso}`"
      :pt:list:data-testid="`attribute-state-list-${languageIso}`"
    />
    <label for="attribute-state">{{ t("attribute.state") }}</label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { AttributeState } from "@/models/attribute/AttributeState";

defineProps<{
  languageIso: string;
  disabled: boolean;
}>();

const attributeState = defineModel<AttributeState>("attributeState", {
  required: true,
});

const { t } = useI18n();
</script>
