<template>
  <div data-testid="ai-prompt-form">
    <div class="flex mb-3">
      <div class="flex-initial w-4/5">
        <PromptName v-model:name="promptRef.promptTitle"> </PromptName>
      </div>
      <div class="flex-initial w-1/5">
        <LockPrompt v-model:name="promptRef.promptTitle"></LockPrompt>
      </div>
    </div>
    <div class="my-10">
      <ProductInfo v-model:productInfo="promptRef" v-model:prompt="promptRef.prompt"></ProductInfo>
    </div>
    <div class="my-10 flex justify-end">
      <PrimeButton
        severity="danger"
        data-testid="ai-btn-delete"
        :label="t('product.ai-search.delete-style')"
        class="mr-8"
        :class="{
          hidden: isHidden,
        }"
        @click="confirmDeletePrompt($event)"
      >
      </PrimeButton>
      <PrimeButton
        data-testid="ai-btn-cancel"
        :label="t('common.cancel')"
        severity="cancel"
        class="mr-8"
        @click="visibleProperty = false"
      >
      </PrimeButton>
      <PrimeButton
        severity="info"
        data-testid="ai-btn-ok"
        :label="
          standardPrompt.includes(promptRef.promptTitle as AIStandardPrompt)
            ? t('product.ai-search.copy-style')
            : t('product.ai-search.save-new-style')
        "
        class="mr-8"
        :disabled="!noUpdates"
        @click="addNewPrompt"
      >
      </PrimeButton>
      <div v-tooltip.bottom="updateButtonTooltip">
        <PrimeButton
          severity="success"
          data-testid="ai-btn-update"
          :label="t('product.ai-search.save-changes')"
          class="mr-8"
          :class="{
            hidden: isHidden,
          }"
          :disabled="!noUpdates"
          @click="onUpdatePromt"
        >
        </PrimeButton>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { AIPrompt } from "@/models/product-info/AIPrompts";
import ProductInfo from "./ProductInfo.vue";
import LockPrompt from "./LockPrompt.vue";
import PromptName from "./PromptName.vue";
import { useI18n } from "vue-i18n";
import { computed, onMounted, ref, watch } from "vue";
import cloneDeep from "lodash.clonedeep";
import { useConfirm } from "primevue/useconfirm";
import { useAiProduct } from "@/product-hierarchy/api/ai-product-info/AiProductService";

import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { UpdateAIPrompt } from "@/models/product-info/UpdateAIPrompts";
import isEqual from "lodash.isequal";
import { AIStandardPrompt } from "@/models/product-info/AIStandardPrompt";

const { deletePrompt, createPrompt, updatePrompt } = useAiProduct();
const { t } = useI18n();
const confirm = useConfirm();
const toast = useCumulusToast(useToast());

const val = useValidate();

const props = defineProps<{
  prompt: AIPrompt;
}>();
const visibleProperty = defineModel<boolean>("visibleProperty");

const emit = defineEmits<{
  (e: "refreshPrompts"): void;
  (e: "update:prompt", value: AIPrompt): void;
}>();

const standardPrompt = Object.values(AIStandardPrompt);

const promptRef = ref(props.prompt);

const noUpdates = computed(() => {
  if (props.prompt === null) return false;
  if (standardPrompt.includes(promptRef.value.promptTitle as AIStandardPrompt)) return true;
  return !isEqual(props.prompt, promptRef.value);
});

const updateButtonTooltip = computed(() => {
  return noUpdates.value ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});

const isHidden = computed(() => {
  return standardPrompt.includes(promptRef.value.promptTitle as AIStandardPrompt);
});

onMounted(() => {
  promptRef.value = cloneDeep(props.prompt) as AIPrompt;
});

watch(
  () => props.prompt,
  (newPrompt) => {
    promptRef.value = cloneDeep(newPrompt) as AIPrompt;
  },
  { deep: true },
);

const confirmDeletePrompt = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: async () => {
      await deletePrompt(promptRef.value.id);
      toast.add({
        severity: "success",
        summary: t("product.ai-search.toast.success-deleted.summary"),
        detail: t("product.ai-search.toast.success-deleted.detail", { name: promptRef.value.promptTitle }),
        closable: true,
      });
      visibleProperty.value = false;
      emit("refreshPrompts");
    },
  });
};

const addNewPrompt = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
  }

  if (promptRef.value.promptTitle) {
    promptRef.value.promptTitle = promptRef.value.promptTitle + " - Copy";
  }

  const newPrompt = AIPrompt.createNewAiPromp(promptRef.value);
  await createPrompt(newPrompt);
  toast.add({
    severity: "success",
    summary: t("product.ai-search.toast.success-add.summary"),
    detail: t("product.ai-search.toast.success-add.detail", { name: newPrompt.promptTitle }),
    closable: true,
  });
  visibleProperty.value = false;
  emit("refreshPrompts");
};

const onUpdatePromt = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  const updateSelected = UpdateAIPrompt.createUpdateAiPrompt(promptRef.value);
  await updatePrompt(updateSelected);

  toast.add({
    severity: "success",
    summary: t("product.ai-search.toast.success-update.summary"),
    detail: t("product.ai-search.toast.success-update.detail", { name: promptRef.value.promptTitle }),
    closable: true,
  });
  visibleProperty.value = false;
  emit("refreshPrompts");
};
</script>
