import { useManufacturerApi } from "@multicase/portal-api/manufacturer";
import { Manufacturer, AddManufacturerRequest } from "@multicase/portal-api/manufacturer";
import { useVuelidate } from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import type { AuthHeaders } from "@multicase/portal-api";

export function useManufacturers() {
  const manufacturer = ref<Manufacturer>(new Manufacturer());
  const manufacturers = ref<Manufacturer[]>([]);

  const manufacturerApi = useManufacturerApi();
  const toast = useCumulusToast(useToast());
  const { getRequest, mutationRequest, loading, saving, error } = useRequests();

  const fetchManufacturers = async () => {
    return await getRequest(async (authHeaders: AuthHeaders) => {
      manufacturers.value = await manufacturerApi.getAll(authHeaders);
    });
  };

  const fetchManufacturerById = async (id: string) => {
    await getRequest(async (authHeaders: AuthHeaders) => {
      manufacturer.value = await manufacturerApi.get(authHeaders, id);
      manufacturerUnmodified.value = cloneDeep(manufacturer.value);
    });
  };

  const manufacturerUnmodified = ref<Manufacturer>(new Manufacturer());
  const isChanged = computed(() => {
    return !isEqual(manufacturer.value, manufacturerUnmodified.value);
  });

  const createManufacturer = async (request: AddManufacturerRequest): Promise<string> => {
    let newId = "";
    if (!(await isFormValid())) {
      return "";
    }

    await mutationRequest(async (authHeaders: AuthHeaders) => {
      newId = await manufacturerApi.create(authHeaders, request);
      const item = new Manufacturer();
      item.id = newId;
      item.name = request.name;
      item.description = request.description;
      manufacturers.value.push(item);
    });

    if (error.value) {
      toast.add({
        severity: "warn",
        summary: t("product.sales-unit.toast.error-create.summary"),
        detail: t("product.sales-unit.toast.error-create.detail"),
        closable: true,
      });
      console.error(error.value);
      return "";
    }

    toast.add({
      severity: "success",
      summary: t("product.sales-unit.toast.success-create.summary"),
      detail: t("product.sales-unit.toast.success-create.detail", { name: manufacturer.value.name }),
      closable: true,
    });

    return newId;
  };

  const updateManufacturer = async (manufacturer: Manufacturer): Promise<boolean> => {
    if (!(await isFormValid())) {
      return false;
    }

    await mutationRequest(async (authHeaders: AuthHeaders) => {
      await manufacturerApi.update(authHeaders, manufacturer);

      const index = manufacturers.value.findIndex((su) => su.id === manufacturer.id);
      if (index !== -1) {
        manufacturers.value[index] = manufacturer;
        manufacturerUnmodified.value = cloneDeep(manufacturer);
      }
    });

    if (error.value) {
      toast.add({
        severity: "warn",
        summary: t("product.sales-unit.toast.error-update.summary"),
        detail: t("product.sales-unit.toast.error-update.detail"),
        closable: true,
      });
      return false;
    }

    toast.add({
      severity: "success",
      summary: t("product.sales-unit.toast.success-update.summary"),
      detail: t("product.sales-unit.toast.success-update.detail", { name: manufacturer.name }),
      closable: true,
    });
    return true;
  };

  const deleteManufacturer = async (id: string): Promise<boolean> => {
    await mutationRequest(async (authHeaders: AuthHeaders) => {
      await manufacturerApi.delete(authHeaders, id);
    });

    const index = manufacturers.value.findIndex((su) => su.id === id);
    let name = "";
    if (index > -1) {
      name = manufacturers.value[index].name;
      manufacturers.value.splice(index, 1);
    }

    if (error.value) {
      toast.add({
        severity: "error",
        summary: t("product.sales-unit.toast.error-delete.summary"),
        closable: true,
      });
      return false;
    }

    toast.add({
      severity: "success",
      summary: t("product.sales-unit.toast.success-delete.summary"),
      detail: t("product.sales-unit.toast.success-delete.detail", { name: name }),
      closable: true,
    });
    return true;
  };

  const { t } = useI18n();
  const validate = useVuelidate();
  const isFormValid = async (): Promise<boolean> => {
    validate.value.$touch();

    if (!(await validate.value.$validate())) {
      toast.add({
        severity: "warn",
        summary: t("common.toast.warn.summary"),
        detail: t("common.toast.warn.detail"),
        closable: true,
      });
      return false;
    }
    return true;
  };

  onMounted(fetchManufacturers);

  return {
    manufacturers,
    manufacturer,
    isChanged,
    fetchManufacturerById,
    fetchManufacturers,
    createManufacturer,
    updateManufacturer,
    deleteManufacturer,
    loading,
    saving,
    error,
  };
}
