<template>
  <Dialog
    :visible="visibleDialog"
    :header="t('attribute.edit-attribute')"
    :modal="true"
    data-testid="attribute-form-dialog"
    class="max-w-3xl mx-auto"
    @update:visible="onCloseDialog"
  >
    <div v-if="loading" class="c-overlay-container">
      <ProgressSpinner />
    </div>
    <div class="px-4">
      <ProgressBar v-if="isTranslating" mode="indeterminate" class="my-1 h-1"></ProgressBar>
      <div class="flex w-full">
        <Button
          type="button"
          class="p-button-text p-button-sm w-fit ml-auto mr-2 my-1"
          data-testid="add-attribute-btn"
          :disabled="isTranslating"
          @click="onTranslate"
        >
          <i class="pi pi-pencil c-default-button c-circular-icon"></i>
          <span>{{ t("common.auto-translate") }}</span>
        </Button>
      </div>
      <Tabs value="0" class="mb-6">
        <TabList>
          <Tab
            v-for="(lang, index) in languages"
            :key="index"
            :disabled="isTranslating"
            :value="index.toString()"
            :pt:root:data-testid="`attribute-form-tab-${lang}`"
          >
            {{ t(`common.languages.${lang.code.toLowerCase()}`) }}
          </Tab>
        </TabList>
        <TabPanel v-for="(lang, index) in languages" :key="index" :value="index.toString()">
          <div class="px-4 pt-4">
            <AttributeForm
              :attribute="attribute"
              :attributeName="attributeNamesByLanguageComputed[lang.code]"
              :attribute-values="attributeValuesByLanguageComputed[lang.code]"
              :language-iso="lang.code"
              :disabled="isSaving || isTranslating"
              @set-attribute-name="setAttributeName"
              @set-attribute-value="setAttributeValue"
              @add-new-attribute-value="addNewAttributeValue"
              @delete-value="deleteValue"
            />
          </div>
        </TabPanel>
      </Tabs>
      <small v-if="showDeleteError" class="p-error" data-testid="attribute-state-error">{{
        t("attribute.error.attribute-active")
      }}</small>
    </div>

    <template #footer>
      <AttributeFooter
        :is-editing="true" 
        :is-saving="isSaving || isTranslating"
        :disabled="isSaving"
        @save-clicked="onSave"
        @delete-clicked="onConfirmDelete"
        @cancel-clicked="onCloseDialog"
      />
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { useToast } from "primevue/usetoast";
import { useCumulusToast, useUnsavedChanges } from "@cumulus/components";
import useValidate from "@vuelidate/core";
import { useAttribute } from "@/repositories/attribute/AttributeService";
import { useEditAttributeStore } from "../stores/useEditAttributeStore";
import { LanguageText } from "@/models/language/LanguageText";
import { AttributeValue } from "@/models/attribute/AttributeValue";
import { useConfirm } from "primevue/useconfirm";
import cloneDeep from "lodash.clonedeep";
import { AttributeState } from "@/models/attribute/AttributeState";
import { useProductLanguageFunctions } from "../../utils/productLanguageFunctions";

const props = defineProps<{
  attributeId: string;
}>();

const visibleDialog = defineModel<boolean>("visibleDialog", {
  required: true,
});

const { t } = useI18n();
const confirm = useConfirm();
const val = useValidate();
const toast = useCumulusToast(useToast());
const isSaving = ref(false);
const isDeleting = ref(false);
const isTranslating = ref(false);
const loading = ref(false);
const showDeleteError = ref(false);
const { languages } = useProductLanguageFunctions();

const editAttributeStore = useEditAttributeStore();
const {
  attribute,
  attributeNamesByLanguageComputed,
  attributeValuesByLanguageComputed,
  isAttributeModified,
  initialAttribute,
} = storeToRefs(editAttributeStore);
const { setAttributeName, setAttributeValue, addNewAttributeValue, deleteValue, resetStore, translate } =
  editAttributeStore;

const { updateAttribute, getAttributeById, deleteAttribute } = useAttribute();

const onCloseDialog = () => {
  if (confirmClose()) {
    resetStore();
    visibleDialog.value = false;
  }
};

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    group: "confirm-popup",
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: async () => {
      deleteAttributeById();
    },
  });
};

const deleteAttributeById = async () => {
  if (attribute.value.attributeState === AttributeState.Active) {
    showDeleteError.value = true;
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }
  try {
    isDeleting.value = true;
    await deleteAttribute(attribute.value.id);

    toast.add({
      severity: "success",
      summary: t("attribute.toast.delete-success.summary"),
      closable: true,
    });

    resetStore();
    visibleDialog.value = false;
  } finally {
    isDeleting.value = false;
  }
};

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  try {
    isSaving.value = true;
    await updateAttribute(attribute.value);

    toast.add({
      severity: "success",
      summary: t("attribute.toast.add-success.summary"),
      closable: true,
    });

    resetStore();
    visibleDialog.value = false;
  } finally {
    isSaving.value = false;
  }
};

const onTranslate = async () => {
  isTranslating.value = true;
  try {
    await translate();
  } catch (e) {
    console.error(e);
  }
  isTranslating.value = false;
};

const getAttribute = async () => {
  try {
    loading.value = true;
    const data = await getAttributeById(props.attributeId);

    attribute.value = {
      ...data,
      values: data.values.map((val) => {
        const attributeValue = new AttributeValue();
        attributeValue.id = val.id;
        attributeValue.values = val.values.map((v) => new LanguageText(v.languageIso, v.text));
        attributeValue.descriptions = val.descriptions.map((d) => new LanguageText(d.languageIso, d.text));
        attributeValue.additionalProperties = val.additionalProperties || {};
        return attributeValue;
      }),
    };

    initialAttribute.value = cloneDeep(attribute.value);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  getAttribute();
});

const { confirmClose } = useUnsavedChanges(isAttributeModified);
</script>

<style scoped lang="scss">
.c-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
</style>
