import { type AxiosResponse } from "axios";
import { httpClient, type AuthHeaders } from "@multicase/portal-api";
import type { Company } from "@/product/models/company/Company";

class CompanyApi {
  private uri = "";

  constructor() {
    this.uri =
      import.meta.env.VITE_APP_API_COMPANY != undefined && import.meta.env.VITE_APP_API_COMPANY
        ? import.meta.env.VITE_APP_API_COMPANY + "/companies"
        : `${import.meta.env.VITE_APP_API_URL as string}/companies`;
  }

  public async get(authHeaders: AuthHeaders): Promise<Company> {
    return await httpClient(this.uri, authHeaders)
      .get(authHeaders.companyId)
      .then(({ data }: AxiosResponse) => data);
  }
}

export const companyApi = new CompanyApi();
