<template>
  <Dialog
    v-model:visible="visible"
    content-class="h-full"
    modal
    closable
    :style="{ maxWidth: '1600px' }"
    @hide="emit('update:visible', false)"
    @keydown.esc.stop="emit('update:visible', false)"
  >
    <template #header>
      <h2 class="text-xl font-bold">{{ t("product.image.manage") }}</h2>
    </template>
    <div class="c-manage-images">
      <ProductImageEditor
        :product-images="productImages"
        @update-image-order="emit('updateImageOrder', $event)"
        @delete-image="emit('deleteImage', $event)"
        @update-image="(index, image) => emit('updateImage', index, image)"
        @add-image="emit('addImage', $event)"
      />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { ProductImage } from "@/repositories/product/model/ProductImage";
import ProductImageEditor from "./ProductImageEditor.vue";
import { useI18n } from "vue-i18n";

defineProps<{
  productImages: ProductImage[];
}>();

const visible = defineModel<boolean>("visible", {
  required: true,
});

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
  (e: "updateImageOrder", newOrder: ProductImage[]): void;
  (e: "deleteImage", imageUrl: string): void;
  (e: "updateImage", index: number, updatedImage: ProductImage): void;
  (e: "addImage", newImage: ProductImage): void;
}>();

const { t } = useI18n();
</script>

<style scoped lang="scss">
.c-manage-images {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding: 1.5rem;
}
</style>
