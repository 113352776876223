<template>
  <ProductHeader
    :edit-mode="true"
    :has-updates="hasUpdates"
    :collapsed-panels="false"
    :show-dialog="false"
    @on-save="saveProduct"
    @on-delete="onDeleteProduct"
  />

  <Message v-if="loadFailed" closable severity="error" class="mx-5 my-3" pt:text:class="w-full">
    <div class="w-full">
      {{ t("common.loading-failed") }} {{ errorReason }}
      <Button
        :label="t('common.reload')"
        severity="danger"
        variant="text"
        icon="pi pi-refresh"
        icon-pos="right"
        pt:label:class="underline"
        class="ml-2"
        @click="reload"
      ></Button>
    </div>
  </Message>

  <div class="c-page-content mt-3 ml-5 pr-1 flex-grow" data-testid="edit-product-variants">
    <Panel id="variantsCollapsed" :header="t('product.variants.label')" class="mb-4">
      <ProductVariantsView :product="product" />
    </Panel>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();
const { reload, onDeleteProduct, errorReason, loadFailed, product, saveProduct, hasUpdates } = useProductManager();
</script>
