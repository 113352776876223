<template>
  <span class="px-2 py-1 text-xs font-medium rounded-full" :class="statusClass">
    {{ statusText }}
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  status: string;
}>();

const { t } = useI18n();

const statusClass = computed(() => {
  switch (props.status) {
    case "Completed":
      return "bg-green-100 text-green-800";
    case "Failed":
      return "bg-red-100 text-red-800";
    case "Validating":
    case "ValidationComplete":
      return "bg-yellow-100 text-yellow-800";
    case "Processing":
    case "MappingStarted":
    case "MappingCompleted":
      return "bg-blue-100 text-blue-800";
    default:
      return "bg-gray-100 text-gray-800";
  }
});

const statusText = computed(() => {
  return t(`product.import.statuses.${props.status.toLowerCase()}`);
});
</script>
