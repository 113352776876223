import { imageApi } from "./ImageApi";
import { ImageInfo } from "./model/ImageInfo";
import { NewImage } from "./model/NewImage";
import { type AuthHeaders } from "@multicase/portal-api";

const resizeImage = (imageUrl: string, width: number, height: number, mode = "boxpad", output = ""): string => {
  const urlParts = imageUrl.replace("https://", "").split("/");

  //Check for domain and filename
  if (urlParts.length < 2) {
    return "";
  }
  const cdnUrl = import.meta.env.VITE_APP_IMAGE_CDN_URL + "/" + urlParts.slice(1).join("/");

  const args: string[] = [];
  if (width > 0) args.push(`w=${width}`);
  if (height > 0) args.push(`h=${height}`);
  if (mode.length > 0) args.push(`mode=${mode}`);
  if (output.length > 0) args.push(`output=${mode}`);

  if (args.length === 0) return cdnUrl;
  return cdnUrl + "?" + args.join("&");
};

const uploadImage = async (file: File, authHeaders: AuthHeaders): Promise<ImageInfo> => {
  const reader = new FileReader();

  const promise = new Promise<ImageInfo>((resolve, reject) => {
    reader.onloadend = async () => {
      if (!reader.result) {
        reject("Failed to upload file");
        return;
      }

      const blobString = reader.result.toString();
      const index = blobString?.indexOf("base64,");
      if (index === -1) {
        reject("Expected result to be in base64 format");
        return;
      }

      const base64 = blobString.substring(index + 7);
      const newImage = new NewImage();
      newImage.imageFile = base64;
      newImage.name = file.name;
      const imageInfo = await imageApi.upload(authHeaders, newImage);
      resolve(imageInfo);
    };
  });
  reader.readAsDataURL(file);
  return promise;
};

export const useImageService = () => {
  return {
    resizeImage,
    uploadImage,
  };
};
