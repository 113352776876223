<template>
  <PrimeDialog
    v-model:visible="visible"
    contentClass="h-full"
    modal
    closable
    :style="{ maxWidth: '1600px' }"
    @hide="$emit('update:visible', false)"
    @keydown.esc.stop="$emit('update:visible', false)"
  >
    <template #header>
      <h2 class="text-xl font-bold">{{ t("product.image.manage") }}</h2>
    </template>
    <div class="c-manage-images">
      <div
        :class="
          productImages.length === 0
            ? 'grid grid-cols-1'
            : 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-6'
        "
      >
        <FileUploadCard @addImage="emit('addImage', $event)" />
        <TransitionGroup name="c-image-list" tag="div" class="contents">
          <div
            v-for="(image, index) of productImages"
            :key="image.url"
            class="relative"
            :class="{ 'z-10': draggedIndex === index }"
            draggable="true"
            @dragstart="startDrag($event, index)"
            @dragend="endDrag"
            @dragover.prevent="dragOver($event, index)"
            @dragenter.prevent="dragEnter(index)"
            @dragleave.prevent="dragLeave($event)"
            @drop.prevent="drop(index)"
          >
            <ImageCard
              :image="image"
              :index="index"
              :draggedIndex="draggedIndex"
              :dragOverIndex="dragOverIndex"
              @deleteImage="() => emit('deleteImage', image.url)"
              @updateImage="(image) => emit('updateImage', index, image)"
            />
          </div>
        </TransitionGroup>
      </div>
    </div>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { ProductImage } from "@/repositories/product/model/ProductImage";
import ImageCard from "./ImageCard.vue";
import FileUploadCard from "./FileUploadCard.vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  productImages: ProductImage[];
}>();

const visible = defineModel<boolean>("visible", {
  required: true,
});

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
  (e: "updateImageOrder", newOrder: ProductImage[]): void;
  (e: "deleteImage", imageUrl: string): void;
  (e: "updateImage", index: number, updatedImage: ProductImage): void;
  (e: "addImage", newImage: ProductImage): void;
}>();

const { t } = useI18n();
const draggedIndex = ref<number | null>(null);
const dragOverIndex = ref<number | null>(null);

const startDrag = (event: DragEvent, index: number) => {
  if (event.dataTransfer) {
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/plain", index.toString());
  }
  draggedIndex.value = index;
};

const endDrag = () => {
  draggedIndex.value = null;
  dragOverIndex.value = null;
};

const dragEnter = (index: number) => {
  if (index !== draggedIndex.value) {
    dragOverIndex.value = index;
  }
};

const dragOver = (event: DragEvent, index: number) => {
  event.preventDefault();
  if (index !== draggedIndex.value) {
    dragOverIndex.value = index;
  }
};

const dragLeave = (event: DragEvent) => {
  if (event.target === event.currentTarget) {
    dragOverIndex.value = null;
  }
};

const drop = (index: number) => {
  if (draggedIndex.value !== null && index !== draggedIndex.value) {
    const newOrder = [...props.productImages];
    const [removed] = newOrder.splice(draggedIndex.value, 1);
    newOrder.splice(index, 0, removed);
    emit("updateImageOrder", newOrder);
  }
  endDrag();
};
</script>

<style scoped lang="scss">
.c-manage-images {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding: 1.5rem;
}

.c-image-list-move {
  transition: transform 0.5s ease;
}

.c-image-list-enter-active,
.c-image-list-leave-active {
  transition: all 0.5s ease;
}

.c-image-list-enter-from,
.c-image-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.c-image-list-leave-active {
  position: absolute;
}
</style>
