<template>
  <DataTable
    id="supplier-price-datatable"
    :value="supplierPricesComputed"
    class="c-product-supplier-table c-datatable"
    data-key="id"
    data-testid="product-supplier"
    :auto-layout="true"
    responsive-layout="stack"
    breakpoint="991px"
    striped-rows
  >
    <template #empty
      ><div class="c-list-not-found" :class="{ 'p-invalid': val.supplierPrices.$error }">
        {{ t(`product.supplier.no-suppliers-found`) }}
      </div></template
    >

    <Column field="name" :header="t(`product.supplier.name`)">
      <template #body="{ data }">
        {{ getSupplierNameById(data.supplierId) }}
      </template>
    </Column>

    <Column field="productNumber" :header="t(`product.supplier.product-number`)">
      <template #body="{ data, index }">
        <InputText
          :id="`supplier-product-number-${index}`"
          v-model="data.productNumber"
          :data-testid="`supplier-product-number-${index}`"
          @focus="selectAllOnFocus"
          @blur="updateSuppliers"
        />
      </template>
    </Column>

    <Column field="estimatedDeliveryDays" :header="t(`product.supplier.estimated-delivery`)">
      <template #body="{ data, index }">
        <InputNumber
          :id="`product-estimated-delivery-${index}`"
          v-model="data.estimatedDeliveryDays"
          :data-testid="`product-estimated-delivery-${index}`"
          mode="decimal"
          :min="0"
          :allow-empty="false"
          :max-fraction-digits="0"
          :highlight-on-focus="true"
          @blur="updateSuppliers"
        />
      </template>
    </Column>

    <Column field="currencyIso" :header="t(`product.supplier.currency`)">
      <template #body="{ data }">
        {{ data.currencyIso }}
      </template>
    </Column>

    <Column field="purchasePrice" :header="t('product.supplier.purchase-price')">
      <template #body="{ data, field, index }">
        <InputNumber
          :id="`product-net-price-${index}`"
          :data-testid="`product-net-price-${index}`"
          :model-value="data[field as keyof typeof data]"
          mode="currency"
          :currency="data.currencyIso"
          :locale="data.currencyIso"
          currency-display="narrowSymbol"
          :min="0"
          :allow-empty="false"
          :min-fraction-digits="2"
          :max-fraction-digits="2"
          :highlight-on-focus="true"
          @update:model-value="updateNetPrice($event, data)"
        />
      </template>
    </Column>

    <Column field="productFreightPrice" :header="t(`product.supplier.freight`)">
      <template #body="{ data, field, index }">
        <InputNumber
          :id="`product-freight-${index}`"
          :data-testid="`product-freight-${index}`"
          :model-value="data[field as keyof typeof data]"
          mode="currency"
          :currency="data.currencyIso"
          :locale="data.currencyIso"
          currency-display="narrowSymbol"
          :min="0"
          :allow-empty="false"
          :min-fraction-digits="2"
          :max-fraction-digits="2"
          :highlight-on-focus="true"
          @update:model-value="updateFreightPrice($event, data)"
        />
      </template>
    </Column>

    <Column field="productCustomsPrice" :header="t(`product.supplier.customs`)">
      <template #body="{ data, field, index }">
        <InputNumber
          :id="`product-customs-${index}`"
          :data-testid="`product-customs-${index}`"
          :model-value="data[field as keyof typeof data]"
          mode="currency"
          :currency="data.currencyIso"
          :locale="data.currencyIso"
          currency-display="narrowSymbol"
          :min="0"
          :allow-empty="false"
          :min-fraction-digits="2"
          :max-fraction-digits="2"
          :highlight-on-focus="true"
          @update:model-value="updateCustomsPrice($event, data)"
        />
      </template>
    </Column>

    <Column field="replacementCost" :header="t('product.supplier.replacement-cost')">
      <template #body="{ data, field }">
        <span id="supplier-replacement-cost">{{
          n(data[field as keyof typeof data], "currency", data.currencyIso)
        }}</span>
      </template>
    </Column>

    <Column :header="t('product.supplier.calculated-cost')">
      <template #body="{ data }">
        <span id="supplier-calculated-cost">{{ calculateCostToClientCurrency(data) }}</span>
      </template>
    </Column>

    <Column field="isDefaultSupplier" :header="t('product.supplier.default-supplier')">
      <template #body="{ data, field }">
        <Checkbox
          :model-value="data[field as keyof typeof data]"
          :binary="true"
          @update:model-value="updateDefaultSupplier(data)"
        />
      </template>
    </Column>

    <Column class="c-product-supplier-column-remove">
      <template #body="{ data, index }">
        <Button
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary p-button-text"
          :data-testid="'remove-supplier-' + index"
          @click.stop="onConfirmDelete($event, data)"
        />
      </template>
    </Column>
  </DataTable>
  <Button
    id="add-supplier-button"
    type="button"
    class="p-button-text p-button-sm"
    data-testid="add-supplier-btn"
    @click="showDialogAdd = true"
  >
    <i class="c-plus"></i>
    <span class="ml-2 c-default-text">{{ t("product.supplier.new") }}</span>
  </Button>

  <small v-if="val.supplierPrices.$error" id="supplier-help" class="p-error" data-testid="supplier-error">
    {{ val.supplierPrices.$errors[0].$message }}
  </small>

  <SupplierDialogAdd
    v-if="showDialogAdd"
    v-model:show-dialog-add="showDialogAdd"
    :supplier-prices="supplierPrices"
    :suppliers="suppliers"
    @add-supplier-price="$emit('addSupplierPrice', $event)"
  />
</template>

<script setup lang="ts">
import { computed, ref, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { SupplierPrice } from "@/repositories/product/model/SupplierPrice";
import SupplierDialogAdd from "./SupplierDialogAdd.vue";
import { type Supplier } from "@/repositories/supplier/model/Supplier";
import cloneDeep from "lodash.clonedeep";
import { Currency } from "@/repositories/currency/model/Currency";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();

const { t, n } = useI18n();
const showDialogAdd = ref(false);

const props = defineProps<{
  supplierPrices: SupplierPrice[];
  suppliers: Supplier[];
  clientCurrencyIso: string;
  currencies: Currency[];
}>();

const supplierPricesComputed = computed<SupplierPrice[]>(() => {
  return props.supplierPrices ? props.supplierPrices.map((s) => cloneDeep(s)) : [];
});

const emit = defineEmits<{
  (e: "addSupplierPrice", value: SupplierPrice): void;
  (e: "deleteSupplierPrice", value: SupplierPrice): void;
  (e: "update:supplierPrices", value: SupplierPrice[]): void;
  (e: "mainSupplierCostPriceUpdated", value: number): void;
}>();

const calculateCostToClientCurrency = (supplierPrice: SupplierPrice) => {
  const getOptions = (): Intl.NumberFormatOptions => ({
    style: "currency",
    currency: props.clientCurrencyIso || "NOK",
    currencyDisplay: "code",
  });

  let priceToFormat = supplierPrice.replacementCost;

  if (supplierPrice.currencyIso !== props.clientCurrencyIso) {
    const currency = props.currencies.find((c) => c.currencyIso === supplierPrice.currencyIso);
    if (currency) {
      priceToFormat = (supplierPrice.replacementCost * currency.buyRate) / currency.unit;
    }
  }

  if (supplierPrice.isDefaultSupplier) {
    emit("mainSupplierCostPriceUpdated", priceToFormat);
  }

  return n(priceToFormat, getOptions());
};

const updateSuppliers = () => {
  emit("update:supplierPrices", supplierPricesComputed.value);
};

const updateNetPrice = (value: number, supplierPrice: SupplierPrice) => {
  supplierPrice.purchasePrice = value;
  updateCostPrice(supplierPrice);
};

const updateFreightPrice = (value: number, supplierPrice: SupplierPrice) => {
  supplierPrice.productFreightPrice = value;
  updateCostPrice(supplierPrice);
};

const updateCustomsPrice = (value: number, supplierPrice: SupplierPrice) => {
  supplierPrice.productCustomsPrice = value;
  updateCostPrice(supplierPrice);
};

const updateCostPrice = (supplierPrice: SupplierPrice) => {
  const calculatedCostPrice =
    supplierPrice.purchasePrice + supplierPrice.productFreightPrice + supplierPrice.productCustomsPrice;
  supplierPrice.replacementCost = calculatedCostPrice;
  updateSuppliers();
};

const getSupplierNameById = (supplierId: string) => {
  const supplier = props.suppliers.find((s) => s.id === supplierId);
  return supplier ? supplier.name : "";
};

const updateDefaultSupplier = (supplier: SupplierPrice) => {
  supplier.isDefaultSupplier = true;
  const index = supplierPricesComputed.value.findIndex((x) => x.id === supplier.id);
  if (index > -1) {
    if (supplierPricesComputed.value[index].isDefaultSupplier) {
      for (let i = 0; i < supplierPricesComputed.value.length; i++) {
        if (i === index) {
          continue;
        }
        supplierPricesComputed.value[i].isDefaultSupplier = false;
      }
    }
  }

  updateSuppliers();
};

const deleteSupplierPrice = async (supplierPrice: SupplierPrice) => {
  emit("deleteSupplierPrice", supplierPrice);
  await nextTick();
  document.getElementById("add-supplier-button")?.focus();
};

const rules = {
  supplierPrices: {
    required: required,
  },
};
const val = useVuelidate(rules, props);

const selectAllOnFocus = (event: Event) => {
  (event.target as HTMLInputElement).select();
};

const onConfirmDelete = (event: Event, supplierPrice: SupplierPrice) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    group: "confirm-popup",
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: async () => {
      deleteSupplierPrice(supplierPrice);
    },
  });
};
</script>

<style scoped lang="scss">
.c-product-supplier-table {
  :deep(.p-datatable-thead > tr > th) {
    padding: 0 1rem;
  }

  :deep(.p-inputnumber-input) {
    max-width: 8rem;
  }
}
.c-product-supplier-column-remove {
  min-width: 3rem;
  padding: 0;

  :deep(.p-column-header-content) {
    float: right;
  }
}
</style>
