<template>
  <div class="flex flex-col space-y-6 max-w-full mx-auto p-6">
    <div class="flex gap-4 mb-4 max-w-3xl mx-auto">
      <div class="bg-white rounded-lg p-4 shadow-sm w-48">
        <div class="flex flex-col">
          <div class="text-sm text-gray-600 mb-1">{{ t("product.import.validation.total") }}</div>
          <div class="text-2xl font-bold">{{ totalProducts }}</div>
        </div>
      </div>
      <div class="bg-white rounded-lg p-4 shadow-sm w-48">
        <div class="flex flex-col">
          <div class="text-sm text-gray-600 mb-1">{{ t("product.import.validation.valid") }}</div>
          <div class="text-2xl font-bold text-green-600">{{ validCount }}</div>
        </div>
      </div>
      <div class="bg-white rounded-lg p-4 shadow-sm w-48">
        <div class="flex flex-col">
          <div class="text-sm text-gray-600 mb-1">{{ t("product.import.validation.errors") }}</div>
          <div class="text-2xl font-bold text-red-600">{{ errorCount }}</div>
        </div>
      </div>
    </div>

    <div class="flex gap-6">
      <div v-if="errorCount > 0" class="w-1/3">
        <div class="bg-white rounded-lg shadow-sm">
          <div class="p-4 border-b">
            <h3 class="font-medium">{{ t("product.import.validation.error-summary") }}</h3>
          </div>
          <div class="overflow-y-auto" style="max-height: 65vh">
            <div v-for="error in errorSummary" :key="error.message + error.field" class="p-4 border-b last:border-b-0">
              <div class="flex items-center gap-2 mb-2">
                <i class="pi pi-exclamation-circle text-red-500"></i>
                <div class="font-medium">{{ error.message }}</div>
              </div>
              <div v-if="error.field" class="ml-6 mb-2">
                <Tag severity="warning">{{ error.field }}</Tag>
              </div>
              <div class="ml-6 flex flex-col gap-1">
                <div class="text-sm text-gray-600">
                  {{ error.count }} {{ error.count === 1 ? "occurrence" : "occurrences" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div :class="errorCount > 0 ? 'w-2/3' : 'w-full'">
        <div class="bg-white rounded-lg shadow-sm">
          <div class="p-4 border-b">
            <h2 class="text-xl font-semibold">{{ t("product.import.validation.preview") }}</h2>
          </div>
          <DataTable
            :value="products"
            :paginator="true"
            :rows="pageSize"
            :totalRecords="totalProducts"
            :loading="loading"
            class="p-datatable-sm"
            :rowClass="getRowClass"
            :lazy="true"
            :currentPageReportTemplate="
              t('common.current-page-template', {
                first: '{first}',
                last: '{last}',
                totalRecords: '{totalRecords}',
              })
            "
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            scrollable
            scrollHeight="70vh"
            @page="onPageChange"
          >
            <Column
              v-for="field in displayFields"
              :key="field"
              :field="'product.' + field"
              :header="t('product.import.' + field.toLowerCase())"
              :sortable="true"
            >
              <template #body="{ data }">
                <div :class="{ 'text-red-600': data.hasErrors }">
                  {{ data.product[field] }}
                </div>
              </template>
            </Column>
            <Column header="Status" :sortable="false">
              <template #body="{ data }">
                <div v-if="data.hasErrors" class="text-red-600">
                  <i class="pi pi-exclamation-circle mr-2"></i>
                  {{ t("product.import.validation.has-errors") }}
                </div>
                <div v-else class="text-green-600">
                  <i class="pi pi-check-circle mr-2"></i>
                  {{ t("product.import.validation.valid") }}
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>

    <div class="flex justify-end gap-4">
      <PrimeButton
        :label="t('product.import.back')"
        icon="pi pi-arrow-left"
        class="p-button-secondary"
        @click="emit('back')"
      />
      <PrimeButton
        :label="t('product.import.validation.start')"
        icon="pi pi-check"
        :disabled="errorCount > 0"
        @click="emit('startImport')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProductImportStore } from "@/product/api/import/ProductImportStore";
import { type ProductValidationViewModel } from "@/product/models/ProductValidationViewModel";
import { type ValidationError } from "@/product/models/ValidationError";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  importId: string;
  ready: boolean;
}>();

const emit = defineEmits<{
  (e: "back"): void;
  (e: "startImport"): void;
}>();

const { t } = useI18n();

const products = ref<ProductValidationViewModel[]>([]);
const allErrors = ref<ValidationError[]>([]);
const totalProducts = ref(0);
const validCount = ref(0);
const errorCount = ref(0);
const loading = ref(false);
const currentPage = ref(1);
const pageSize = ref(25);

const displayFields = ["rowNumber", "productNumber", "name", "description", "productType", "productStatus"];
const totalErrors = computed(() => groupedErrors.value.reduce((sum, group) => sum + group.count, 0));

const errorSummary = computed(() =>
  groupedErrors.value.map((error) => ({
    ...error,
    percentage: (error.count / totalErrors.value) * 100,
  })),
);

const { getValidationResult } = useProductImportStore();

const fetchPage = async () => {
  loading.value = true;
  try {
    const response = await getValidationResult(props.importId, currentPage.value, pageSize.value);

    products.value = response.products;
    allErrors.value = response.allErrors;
    totalProducts.value = response.totalCount;
    validCount.value = response.validCount;
    errorCount.value = response.errorCount;
  } finally {
    loading.value = false;
  }
};

const groupedErrors = computed(() => {
  const errorGroups = allErrors.value.reduce(
    (groups, error) => {
      const key = `${error.message}-${error.field || ""}`;
      if (!groups[key]) {
        groups[key] = {
          message: error.message,
          field: error.field,
          count: 0,
          rows: new Set<number>(),
        };
      }
      groups[key].count++;
      if (error.rowNumber) {
        groups[key].rows.add(error.rowNumber);
      }
      return groups;
    },
    {} as Record<string, { message: string; field?: string; count: number; rows: Set<number> }>,
  );

  return Object.values(errorGroups).sort((a, b) => b.count - a.count);
});

const onPageChange = (event: { page: number; rows: number }) => {
  currentPage.value = event.page;
  fetchPage();
};

const getRowClass = (data: ProductValidationViewModel) => {
  return {
    "bg-red-50": data.hasErrors,
    "bg-green-50": !data.hasErrors,
  };
};

watch(
  () => props.ready,
  (isReady) => {
    if (isReady) {
      fetchPage();
    }
  },
  { immediate: true },
);
</script>
