<template>
  <teleport v-if="isMounted" to="#teleport-product-toolbar">
    <div v-tooltip.bottom="saveButtonTooltip" class="inline-block">
      <Button id="btn-save" class="c-circular-button mr-4" data-testid="btn-save" @click="emit('onSave')">
        <i class="pi pi-check c-success-button c-circular-icon"></i>
        <span class="px-4">{{ editMode ? t("common.save") : t("common.create") }}</span>
      </Button>
    </div>

    <Button v-if="editMode" class="c-circular-button mr-4" data-testid="btn-delete" @click="onConfirmDelete">
      <i class="pi pi-trash c-delete-button c-circular-icon"></i>
      <span class="px-4">{{ t("common.delete") }}</span>
    </Button>

    <Button v-if="!editMode" class="c-circular-button mr-4" data-testid="btn-toggle-panels" @click="onToggleAllPanels">
      <i class="pi pi-clone c-default-button c-circular-icon"></i>
      <span class="px-3">{{ collapsedPanels ? t("product.panel.open-panels") : t("product.panel.close-panels") }}</span>
    </Button>

    <Button
      v-if="showAiAssisstanceButton"
      class="c-circular-button"
      data-testid="btn-ai-dialog"
      @click="openAIDescriptionDialog()"
    >
      <i class="pi pi-pencil c-default-button c-circular-icon"></i>
      <span class="px-4">{{ t("common.ai-assistant") }}</span>
    </Button>
  </teleport>
</template>

<script setup lang="ts">
import { useConfirm } from "primevue/useconfirm";

const { t } = useI18n();
const confirm = useConfirm();

const props = defineProps<{
  hasUpdates: boolean;
  editMode: boolean;
  collapsedPanels: boolean;
  showDialog: boolean;
  showAiAssisstanceButton?: boolean;
}>();

const emit = defineEmits<{
  (e: "onSave"): void;
  (e: "onDelete"): void;
  (e: "onToggleAllPanels", currentValue: boolean): void;
  (e: "update:showDialog", value: boolean): void;
}>();

const onToggleAllPanels = () => {
  emit("onToggleAllPanels", props.collapsedPanels);
};

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    group: "confirm-dialog",
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: async () => {
      emit("onDelete");
    },
  });
};

const saveButtonTooltip = computed(() => {
  return props.hasUpdates ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
const openAIDescriptionDialog = () => {
  emit("update:showDialog", !props.showDialog);
};

const isMounted = ref(false);
onMounted(() => {
  isMounted.value = true;
});
</script>

<style scoped lang="scss">
.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
