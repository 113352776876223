import { defineStore } from "pinia";
import { useAuth } from "@cumulus/event-bus";
import { ref } from "vue";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { Product } from "./model/Product";
import { NewProduct } from "./model/NewProduct";
import { productApi } from "./ProductApi";
import { UpdateProduct } from "./model/UpdateProduct";
import { ProductVariantsRequest } from "./model/ProductVariantsRequest";
import { ProductImage } from "./model/ProductImage";
import { ProductWithLanguage } from "@/product/models/ProductWithLanguage";
import { ProductRelation } from "./model/ProductRelation";

export const useProductStore = defineStore("product", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const products = ref<Product[]>([]);
  const loading = ref(false);
  const saving = ref(false);

  let productCache: Product | null = null;
  let isLoadingProductId: string | null = null;

  const timeout = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const productById = (id: string): Product => {
    return products.value.find((product) => product.id === id) ?? new Product();
  };

  const getProduct = async (id: string, skipCache: boolean = false): Promise<Product> => {
    try {
      if (isLoadingProductId === id) {
        await timeout(50); // Wait for 50ms to to avoid multiple requests for the same product

        if (isLoadingProductId !== null) {
          return await getProduct(id);
        }
      }

      if (!skipCache && productCache !== null && productCache.id === id) {
        return productCache;
      }

      isLoadingProductId = id;

      const authHeaders = await getAuthHeaders();
      const product = await productApi.get(authHeaders, id);
      productCache = product;
      return product;
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      isLoadingProductId = null;
    }
  };

  const getProducts = async (ids: string[]): Promise<Product[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productApi.getProducts(authHeaders, ids);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const fetchProducts = async (): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      products.value = await productApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }
  };

  const createProduct = async (product: NewProduct): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await productApi.create(authHeaders, product);
      // products.value.push(createdProduct);
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  const createProductVariants = async (request: ProductVariantsRequest): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await productApi.createVariants(authHeaders, request);
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  const updateProduct = async (product: Product, imagesToDelete: ProductImage[]): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      const updateProduct = new UpdateProduct(product, imagesToDelete);
      await productApi.update(authHeaders, updateProduct);
      const index = products.value.findIndex((cg) => cg.id === product.id);
      products.value[index] = product;
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  const updateProductRelations = async (productId: string, relations: ProductRelation[]): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await productApi.updateRelations(authHeaders, productId, relations);
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  const deleteProduct = async (productId: string): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await productApi.delete(authHeaders, productId);
      products.value = products.value.filter((cg) => cg.id !== productId);
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  const updateProductsDefaultLanguage = async (
    productsLanguages: ProductWithLanguage[],
    defaultLanguage: string,
  ): Promise<void> => {
    productCache = null;
    for (const productLanguages of productsLanguages) {
      const product = products.value.find((product) => product.id !== productLanguages.productId);
      if (product) {
        const language = productLanguages.languages.find((l) => l.languageIso === defaultLanguage);
        if (language) {
          product.name = language.name;
          product.productInfo = language.productInfo;
          product.description = language.description;
        }

        await updateProduct(product, []);
      }
    }
  };

  return {
    products,
    loading,
    saving,
    getProduct,
    getProducts,
    productById,
    fetchProducts,
    createProduct,
    createProductVariants,
    updateProduct,
    updateProductRelations,
    deleteProduct,
    updateProductsDefaultLanguage,
  };
});
