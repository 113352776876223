<template>
  <DataTable
    ref="ProductGroupNameTableRef"
    v-model:filters="filters"
    :value="productGroupNameList"
    dataKey="id"
    :loading="loading"
    :rowHover="true"
    data-testid="product-group-name-list"
    sortField="name"
    selectionMode="multiple"
    :metaKeySelection="false"
    :sortOrder="1"
    stripedRows
    autoLayout
    class="c-datatable"
    tabindex="0"
    responsiveLayout="scroll"
    :globalFilterFields="['global', 'name']"
    scrollable
    scrollHeight="30vh"
    @row-select="onRowSelected"
  >
    <template #header>
      <span class="p-inputgroup w-full">
        <InputText
          ref="searchInputRef"
          v-model="filters['global'].value"
          :placeholder="t('common.search')"
          class="w-full"
          data-testid="product-group-search"
          @keyup.enter="searchInputEnterKey"
          @keypress.tab="focusProductGroupNameTable"
          @keydown.down="focusProductGroupNameTable"
        />
        <span class="p-button p-inputgroup-addon bg-transparent border-none z-10 -ml-10"
          ><i class="pi pi-search text-zinc-500"
        /></span>
      </span>
    </template>
    <Column field="name" :header="t('product-group-name.header')" filterField="name" sortable>
      <template #body="{ data, field, index }">
        <div class="flex justify-between flex-wrap">
          <div :data-testid="`product-group-${field}-row-${index}`" class="flex items-center justify-center w-4rem2">
            {{ data[field] }}
          </div>
        </div>
      </template>
    </Column>
    <template #loading>{{ t("product.sales-unit.loading") }}</template>

    <template #empty>
      <div id="empty-cg-filter" ref="emptyFilterRef">
        {{ t("product.sales-unit.not-found") }}
      </div></template
    >
  </DataTable>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { ref, computed, onMounted, nextTick } from "vue";
import { type DataTableRowSelectEvent } from "primevue/datatable";
import { useAuth } from "@cumulus/event-bus";
import { FilterMatchMode } from "@primevue/core/api";
import { ProductGroupNameByLanguage } from "../model/ProductGroupNameByLanguage";
import { LanguageIsoType } from "../../models/language/LanguageIsoType";
import { useProductHierarchyStore } from "../api/ProductHierarchyStore";

const { t } = useI18n();
const emptyFilterRef = ref(null);
const ProductGroupNameTableRef = ref();
const productHierarchyStore = useProductHierarchyStore();
const { loading, productGroupNames } = storeToRefs(productHierarchyStore);

onMounted(async () => await productHierarchyStore.getProductGroupNames());

defineProps<{
  selectedProductGroupName: ProductGroupNameByLanguage;
}>();

const emit = defineEmits<{
  (e: "update:selectedProductGroupName", value: ProductGroupNameByLanguage): void;
  (e: "hideOverlayPanel", value: void): void;
}>();

const onRowSelected = (event: DataTableRowSelectEvent) => {
  emit("update:selectedProductGroupName", event.data as ProductGroupNameByLanguage);
  emit("hideOverlayPanel");
};

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
});

const focusProductGroupNameTable = () => {
  ProductGroupNameTableRef.value.$el.querySelector("tbody tr:first-of-type").focus();
};

const searchInputRef = ref();
nextTick(() => {
  searchInputRef.value.$el.focus();
});

const searchInputEnterKey = () => {
  focusProductGroupNameTable();
};

const { getUser } = useAuth();
const languageIso = ref<LanguageIsoType>(LanguageIsoType.English);

onMounted(async () => {
  languageIso.value =
    ((await (await getUser()).getEmployee()?.languageCode) as LanguageIsoType) ?? LanguageIsoType.English;
});

const productGroupNameList = computed(() =>
  productGroupNames.value.map((productGroupName) =>
    ProductGroupNameByLanguage.createFromProductGroupName(productGroupName, languageIso.value),
  ),
);
</script>

<style scoped lang="scss">
.c-product-gGroup-name:hover {
  text-decoration: underline;
}

.p-datatable-selectable-row.p-highlight .p-button.p-button-text {
  color: var(--primary-color-text);
}

.c-edit-button.p-button.p-button-text,
.c-delete-button.p-button.p-button-text {
  visibility: hidden;
}

.c-delete-button.p-button.p-button-text:hover {
  color: var(--delete-btn-bg);
}

.c-edit-button.p-button.p-button-text:hover {
  color: var(--success-btn-bg);
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  .c-edit-button,
  .c-delete-button {
    visibility: visible;
  }
}

.c-datatableable.p-datatable-striped .p-datatable-tbody > tr.p-highlight,
.c-datatableable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  .c-edit-button,
  .c-delete-button {
    visibility: visible;
  }
}

.c-product-group-name-cog.p-button:focus {
  box-shadow: none;
}

:deep(.p-datatable) .p-datatable-header {
  background: none;
  border: none;
}
</style>
