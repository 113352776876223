<template>
  <div class="p-6">
    <label>
      {{ t("product.tax-rate") }}
    </label>
  </div>

  <DataTable :value="productTaxesComputed" class="c-datatable" striped-rows>
    <Column field="name" header="Name">
      <template #body="{ data, index }">
        <FloatLabelDropdownPanel
          :id="`taxes-dropdown-${index}`"
          :selected-item-name="data.name"
          class="p-0 mt-0"
          data-test-id="product-taxes"
          label=""
          :aria-label="t('product.tax.edit-tax')"
          :options="allTaxes"
          @toggle-dropdown-panel="showTaxesPanel($event, data, index)"
        /> </template
    ></Column>
    <Column field="description" header="Description" />
    <Column field="countryIso" header="Country">
      <template #body="{ data }">{{ data.countryIso }}</template>
    </Column>
    <Column field="taxType" header="Tax Type" />
    <Column field="taxRate" header="Tax Rate">
      <template #body="{ data }">{{ data.taxRate }}%</template>
    </Column>
    <Column field="isDefault" header="Default">
      <template #body="{ data }"
        ><Checkbox
          v-model="data.isDefault"
          :binary="true"
          :disabled="true"
          :aria-label="data.isDefault ? t('product.tax.default-selected') : t('product.tax.default')"
        ></Checkbox
      ></template>
    </Column>
  </DataTable>

  <TaxPanel
    ref="taxesPanelRef"
    v-model:show-tax-panel="showTaxPanel"
    :taxes="allTaxes"
    :selected-tax="taxToEdit"
    :selected-index="selectedIndex"
    @select-tax="onSelectTax"
  />
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { type Tax } from "@/repositories/tax/model/Tax";
import { useTax } from "@/repositories/tax/TaxService";
import { ProductTax } from "@/repositories/product/model/ProductTax";
import { TaxType } from "@/repositories/tax/model/TaxType";
import { ProductTaxDisplay } from "@/repositories/product/model/ProductTaxDisplay";
import TaxPanel from "./tax/TaxPanel.vue";
import cloneDeep from "lodash.clonedeep";
const taxesPanelRef = ref();

const showTaxesPanel = (event: Event, tax: ProductTaxDisplay, index: number) => {
  taxesPanelRef.value.toggle(event);
  editTax(tax, index);
};

const { t } = useI18n();
const { getTaxes } = useTax();

const props = defineProps<{
  productTaxes: ProductTax[];
}>();

const emit = defineEmits<{
  (e: "update:productTaxes", value: ProductTax[]): void;
}>();

const activeCountries = ref<string[]>(["NO"]);

const productTaxesComputed = computed<ProductTaxDisplay[]>(() => {
  if ((props.productTaxes?.length ?? 0) === 0) return [];

  const taxes: ProductTaxDisplay[] = [];
  props.productTaxes.forEach((t) => {
    const tax = getTaxById(t.taxId);
    if (tax === null) {
      // Error
    } else {
      taxes.push(new ProductTaxDisplay(tax));
    }
  });
  return taxes;
});

const setDefaultTaxes = () => {
  if (props.productTaxes?.length !== 0) return;

  const defaultTaxes: ProductTax[] = [];

  activeCountries.value.forEach((countryIso) => {
    const defaultOutgoingTax = getDefaultTax(TaxType.Outgoing, countryIso);
    if (defaultOutgoingTax === null) {
      // Error
    } else {
      const outgoing = new ProductTax(defaultOutgoingTax);
      defaultTaxes.push(outgoing);
    }

    const defaultIncomingTax = getDefaultTax(TaxType.Incoming, countryIso);
    if (defaultIncomingTax === null) {
      // Error
    } else {
      const incoming = new ProductTax(defaultIncomingTax);
      defaultTaxes.push(incoming);
    }
  });

  emit("update:productTaxes", defaultTaxes);
};

const getDefaultTax = (taxType: TaxType, countryIso: string) => {
  return (
    allTaxes.value?.find(
      (tax) => tax.taxType === taxType && tax.countryIso === countryIso && tax.isDefaultTaxForProduct,
    ) ?? null
  );
};

const getTaxById = (id: string) => {
  return allTaxes.value?.find((tax) => tax.id === id) ?? null;
};

const allTaxes = ref<Tax[]>([]);
const fetchTaxes = async () => {
  allTaxes.value = await getTaxes();
  setDefaultTaxes(); // For new products (add)
};
onMounted(fetchTaxes);

const selectedIndex = ref<number | null>(null);
const showTaxPanel = ref<boolean>(false);
const taxToEdit = ref<ProductTaxDisplay>(new ProductTaxDisplay());

const editTax = (tax: ProductTaxDisplay, index: number) => {
  taxToEdit.value = tax;
  selectedIndex.value = index;
};

const onSelectTax = (tax: Tax) => {
  const newProductTax = new ProductTax(tax);
  replaceProductTax(taxToEdit.value, newProductTax);
};

const replaceProductTax = (taxToRemove: ProductTax, taxToAdd: ProductTax) => {
  const productTaxes: ProductTax[] = props.productTaxes.map((t) => cloneDeep(t));
  const indexToRemove = productTaxes.findIndex((t) => t.taxId === taxToRemove.taxId);
  if (indexToRemove !== -1) {
    productTaxes.splice(indexToRemove, 1, taxToAdd);
    emit("update:productTaxes", productTaxes);
  }
};
</script>
