<template>
  <Stepper v-model:value="currentStep">
    <StepList>
      <Step value="1">{{ t("product.import.upload") }}</Step>
      <Step value="2">{{ t("product.import.map") }}</Step>
      <Step value="3">{{ t("product.import.review") }}</Step>
    </StepList>
    <StepPanels>
      <StepPanel value="1">
        <div class="max-w-6xl mx-auto p-6">
          <FileUpload @fileUploaded="navigateToStep('2')" />
          <RecentImports />
        </div>
      </StepPanel>
      <StepPanel value="2">
        <div v-if="status !== ImportStatus.Idle">
          <ImportProgress :status="status" :progress="progressPercentage" :error="error" />
        </div>
        <div v-else>
          <MapColumns v-model:columnMappings="columnMappings" :importId="importId" />

          <div class="flex pt-6 justify-between">
            <PrimeButton
              :label="t('product.import.back')"
              severity="secondary"
              icon="pi pi-arrow-left"
              @click="navigateToStep('1')"
            />
            <PrimeButton
              :label="t('product.import.next')"
              icon="pi pi-arrow-right"
              iconPos="right"
              @click="startProcessing"
            />
          </div>
        </div>
      </StepPanel>
      <StepPanel value="3">
        <ValidationResults
          :importId="importId"
          :ready="currentStep === '3'"
          @back="navigateToStep('2')"
          @startImport="handleStartImport"
        />
      </StepPanel>
    </StepPanels>
  </Stepper>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useProductImportStore } from "../api/import/ProductImportStore";
import { storeToRefs } from "pinia";
import FileUpload from "../components/import/FileUpload.vue";
import MapColumns from "../components/import/MapColumns.vue";
import { useI18n } from "vue-i18n";
import { ColumnMapping } from "../models/ColumnMapping";
import { ImportStatus, useProductImportProgress } from "../composables/productImportProgress";
import ImportProgress from "../components/import/ImportProgress.vue";
import ValidationResults from "../components/import/ValidationResults.vue";
import RecentImports from "../components/import/RecentImports.vue";
import type { ProductImportMapRequest } from "../models/ProductImportMapRequest";
import { useRoute, useRouter } from "vue-router";

const columnMappings = ref<ColumnMapping[]>([]);
const productImportStore = useProductImportStore();
const currentStep = ref<string>("1");
const route = useRoute();
const router = useRouter();
const { importId, importOptions } = storeToRefs(productImportStore);
const { t } = useI18n();

const { status, progressPercentage, error } = useProductImportProgress();
const productImportService = useProductImportStore();

const startProcessing = async (goToStep: (value: string) => void) => {
  status.value = ImportStatus.Mapping;
  progressPercentage.value = 0;

  try {
    const request: ProductImportMapRequest = {
      id: importId.value,
      columnMappings: columnMappings.value,
      importOptions: importOptions.value,
    };

    await productImportService.startMapping(request);

    const checkStatus = setInterval(() => {
      if (status.value === ImportStatus.ValidationComplete) {
        clearInterval(checkStatus);
        goToStep("3");
      } else if (status.value === ImportStatus.Failed) {
        clearInterval(checkStatus);
      }
    }, 1000);
  } catch (error) {
    console.error("Failed to start processing:", error);
  }
};

const handleStartImport = async () => {
  // TODO: Implement start import logic
  alert("Coming soon to a browser near you!");
};

const navigateToStep = (step: string) => {
  // Fix for navigating back to step 1 if we have uploaded a file
  if (!route.params.id && step === "2" && importId.value) {
    router.push({
      name: "product-import",
      params: {
        id: importId.value,
        step: "2",
      },
    });
    return;
  }

  // Otherwise, navigate to the step
  router.push({
    name: "product-import",
    params: {
      ...(importId.value && step !== "1" ? { id: importId.value } : {}),
      ...(step !== "1" ? { step } : {}),
    },
  });
};

watch(
  () => [route.params.id, route.params.step],
  ([newId, newStep]) => {
    if (newId) {
      importId.value = newId as string;
    }

    if (newStep) {
      currentStep.value = newStep as string;
    } else {
      currentStep.value = "1";
    }
  },
  { immediate: true },
);
</script>

<style scoped>
:deep(.p-button) {
  background-color: white;
  color: black;
  border: 1px solid #d1d5db;
  &:hover {
    background-color: #f9fafb;
  }
}

:deep(.p-dropdown) {
  border: 1px solid #d1d5db;
}

:deep(.p-datatable-scrollable) {
  overflow-x: auto;
}

:deep(.p-steplist) {
  max-width: 50rem;
  width: 100%;
  margin: auto;
}
</style>
