<template>
  <InputText
    :disabled="disabled"
    :model-value="value"
    class="w-full p-inputtext-sm"
    :data-testid="`attribute-value-${languageIso}-${index}`"
    @update:model-value="emit('updateValue', $event as string)"
  />
  <small v-if="val.text.$error" class="p-error" data-testid="attribute-value-error">
    {{ val.text.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import useVuelidate from "@vuelidate/core";

const value = defineModel<string>("value", {
  required: true,
});

defineProps<{
  languageIso: string;
  index: number;
  disabled: boolean;
}>();

const emit = defineEmits<{
  (e: "updateValue", value: string): void;
}>();

const rules = {
  text: {
    required: required,
  },
};

const val = useVuelidate(rules, { text: value });
</script>
