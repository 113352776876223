<template>
  <PrimeDialog
    v-model:visible="visible"
    @keydown.escape.stop="visible = false"
    :modal="true"
    :style="dialogStyle"
    :breakpoints="{ '1199px': '85vw', '575px': '90vw' }"
    :closable="true"
    :closeOnEscape="true"
    pt:header:class="p-1 bg-[#F0F6FA] dark:bg-[#27272A] rounded-xl"
    pt:content:class="h-full bg-[#F0F6FA] dark:bg-[#27272A]"
    pt:footer:class="pb-3"
  >
    <template #header>
      <div class="flex justify-center w-full">
        <div class="font-bold whitespace-nowrap">
          {{ t("product-info-dialog.title") }} :: {{ activeProduct.productNumber }} - {{ activeProduct.name }}
        </div>
      </div>
    </template>

    <div class="grid grid-cols-12 gap-6 pt-1 h-full">
      <div class="col-span-12" :class="!showSplitView ? 'lg:col-span-12' : 'lg:col-span-6'">
        <ProductInfoLanguage
          :languages="languages"
          :hasSplitView="true"
          :showSplitView="showSplitView"
          :productIndex="productIndex"
          :maxProductIndex="props.products.length - 1"
          v-model:product-language-text="activeProductLanguageText"
          @previousProduct="previousProduct"
          @nextProduct="nextProduct"
          @toggleSplitView="showSplitView = !showSplitView"
        />
      </div>

      <div v-if="showSplitView" class="col-span-12 lg:col-span-6">
        <ProductInfoLanguage
          :languages="languages"
          :showSplitView="showSplitView"
          :hasSplitView="false"
          :productIndex="productIndex"
          :maxProductIndex="props.products.length - 1"
          v-model:product-language-text="activeProductLanguageText"
          @previousProduct="previousProduct"
          @nextProduct="nextProduct"
          @toggleSplitView="showSplitView = !showSplitView"
        />
      </div>
    </div>

    <template #footer>
      <div class="flex justify-center gap-4 pt-2">
        <PrimeButton type="button" data-testid="ai-btn-ok" @click="save" class="c-default-button">
          <i class="pi pi-check"></i>
          <span class="px-2">{{ t("common.save") }}</span>
        </PrimeButton>
        <PrimeButton @click="visible = false" data-testid="ai-btn-cancel" severity="cancel" text>
          <i class="pi pi-times"></i>
          <span class="px-2 font-bold">{{ t("common.cancel") }}</span>
        </PrimeButton>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from "vue";
import { Product } from "@/repositories/product/model/Product";
import { useI18n } from "vue-i18n";
import { LanguageIsoType } from "@/models/language/LanguageIsoType";
import ProductInfoLanguage from "./ProductInfoLanguage.vue";
import { ProductText } from "@/product/models/ProductText";
import { LanguageViewModel } from "@/product/models/LanguageViewModel";
import { useProductStore } from "@/repositories/product/ProductStore";
import { ProductLanguageText } from "@/product/models/ProductLanguageText";

const { t } = useI18n();

const visible = defineModel<boolean>("visible", { required: true, default: false });
const showSplitView = ref(false);

const props = defineProps<{
  products: Product[];
}>();

const dialogStyle = computed(() => {
  if (showSplitView.value) {
    return {
      width: "110vw",
      maxWidth: "90%",
      height: "75vh",
      maxHeight: "95%",
    };
  }
  return { width: "50vw", maxWidth: "90%", height: "75vh", maxHeight: "95%" };
});

const productIndex = ref(0);
const activeProduct = computed<Product>(() => {
  return props.products[productIndex.value];
});

const previousProduct = () => {
  if (productIndex.value > 0) {
    productIndex.value--;
  }
};

const nextProduct = () => {
  if (productIndex.value < props.products.length - 1) {
    productIndex.value++;
  }
};

watch(
  () => activeProduct.value,
  () => {
    featchProductText();
  },
);

onMounted(() => {
  if (activeProduct.value) {
    featchProductText();
  }
});

const languages = ref<LanguageViewModel[]>([]);
const productLanguageText = ref<ProductLanguageText[]>([]);

const featchProductText = () => {
  //Get company default language
  const defaultLanguage = LanguageIsoType.Norwegian; //TODO: Use company default language

  const product = activeProduct.value;
  if (productLanguageText.value.find((x) => x.productId === product.id)) {
    return; //Has loaded product text
  }

  //TODO: Fetch product text for all languages from API
  const responseAllProductTexts: ProductText[] = [
    {
      languageIso: LanguageIsoType.English,
      name: "Missing API for English",
      description: "Missing API for English",
      productInfo: "Missing API for English",
      titleTag: "Missing API for English",
      metaDescription: "Missing API for English",
    },
  ] as ProductText[];

  productLanguageText.value.push({
    productId: product.id,
    defualtLanguage: defaultLanguage,
    productText: [ProductText.fromProduct(product, defaultLanguage), ...responseAllProductTexts],
  });

  if (languages.value.length < 1) {
    fetchLanguages();
  }
};

const fetchLanguages = () => {
  const defaultText = t("product-info-dialog.default-language");
  languages.value = [];
  if (productLanguageText.value.length < 1) {
    return;
  }

  const product = productLanguageText.value[0];

  for (const productText of product.productText) {
    languages.value.push({
      name:
        t(`product-info-dialog.languages.${productText.languageIso.toLowerCase()}`) +
        (product.defualtLanguage === productText.languageIso ? ` (${defaultText})` : ""),
      code: productText.languageIso,
    });
  }
};

const activeProductLanguageText = computed(() => {
  const product = activeProduct.value;
  return productLanguageText.value.find((x) => x.productId === product.id) ?? new ProductLanguageText();
});

const { updateProduct } = useProductStore();

const save = async () => {
  for (const productTexts of productLanguageText.value) {
    const defaultIndex = productTexts.productText.findIndex((x) => x.languageIso === productTexts.defualtLanguage);
    if (defaultIndex < 0) {
      continue;
    }
    const defaultProduct = productTexts.productText[defaultIndex];

    const product = props.products.find((x) => x.id === productTexts.productId);
    if (!product || !defaultProduct) {
      continue;
    }

    product.name = defaultProduct.name;
    product.description = defaultProduct.description;
    product.productInfo = defaultProduct.productInfo;
    product.titleTag = defaultProduct.titleTag;
    product.metaDescription = defaultProduct.metaDescription;

    //TODO: Only save product text, not product.
    await updateProduct(product, []);

    //Make a copy of the array
    const languageData = [...productTexts.productText];
    //Remove the default language from the array
    const withoutDefaultData = languageData.splice(defaultIndex, 1);

    //TODO: Save product text
    console.log("TODO: Save product text", withoutDefaultData);
  }
};
</script>
