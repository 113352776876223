import { type AxiosResponse } from "axios";
import { type ColumnMappingSuggestionResponse } from "@/product/models/ColumnMappingSuggestionResponse";
import { type ProductImportInitializeResponse } from "@/product/models/ProductImportInitializeResponse";
import { type ProductImportMapRequest } from "@/product/models/ProductImportMapRequest";
import { type ProductImportPreviewResponse } from "@/product/models/ProductImportPreviewResponse";
import { type ProductImportSessionResponse } from "@/product/models/ProductImportSessionResponse";
import { SystemField } from "@/product/models/SystemField";
import { type ValidationResultsResponse } from "@/product/models/ValidationResultsResponse";
import { httpClient, type AuthHeaders } from "@multicase/portal-api";
import type { ParsedRow } from "@/product/models/ParsedRow";
import type { ProductImportSession } from "@/product/models/ProductImportSession";
import type { MappedImportProduct } from "@/product/models/MappedImportProduct";

class ProductImportApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != "undefined" && import.meta.env.VITE_APP_API_PRODUCT
        ? import.meta.env.VITE_APP_API_PRODUCT + "/products"
        : `${import.meta.env.VITE_APP_API_URL as string}/products`;
  }

  public async initializeImport(authHeaders: AuthHeaders, file: FormData): Promise<ProductImportInitializeResponse> {
    return await httpClient(this.uri, authHeaders)
      .postForm("/import/initialize", file)
      .then(({ data }: AxiosResponse) => data);
  }

  public async getWebPubSubUrl(authHeaders: AuthHeaders): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .get("/import/web-pubsub-url")
      .then(({ data }: AxiosResponse) => data);
  }

  public async startMapping(authHeaders: AuthHeaders, request: ProductImportMapRequest): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .post(`/import/${request.id}/mapping`, request)
      .then(({ data }: AxiosResponse) => data);
  }

  public async getSystemFields(authHeaders: AuthHeaders): Promise<SystemField[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/import/system-fields")
      .then(({ data }: AxiosResponse) => data);
  }

  public async getValidationResult(
    authHeaders: AuthHeaders,
    id: string,
    page: number,
    pageSize: number,
  ): Promise<ValidationResultsResponse> {
    return await httpClient(this.uri, authHeaders)
      .get(`/import/${id}/validation`, { params: { page, pageSize } })
      .then(({ data }: AxiosResponse) => data);
  }

  public async getColumnMappingSuggestions(
    authHeaders: AuthHeaders,
    columns: string[],
    previewRows: ParsedRow[],
  ): Promise<ColumnMappingSuggestionResponse> {
    return await httpClient(this.uri, authHeaders)
      .post(`/import/suggest-mappings`, { columns, previewRows })
      .then(({ data }: AxiosResponse) => data);
  }

  public async getImportSessions(
    authHeaders: AuthHeaders,
    page: number,
    pageSize: number,
    sortBy?: string,
    sortOrder?: string,
  ): Promise<ProductImportSessionResponse> {
    return await httpClient(this.uri, authHeaders)
      .get("/import/sessions", { params: { page, pageSize, sortBy, sortOrder } })
      .then(({ data }: AxiosResponse) => data);
  }

  public async getImportSessionPreview(authHeaders: AuthHeaders, id: string): Promise<ProductImportPreviewResponse> {
    return await httpClient(this.uri, authHeaders)
      .get(`/import/${id}/preview`)
      .then(({ data }: AxiosResponse) => data);
  }

  public async startImport(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .post(`/import/${id}/start`)
      .then(({ data }: AxiosResponse) => data);
  }

  public async getImportSession(authHeaders: AuthHeaders, id: string): Promise<ProductImportSession> {
    return await httpClient(this.uri, authHeaders)
      .get(`/import/sessions/${id}`)
      .then(({ data }: AxiosResponse) => data);
  }

  public async updateMappedProduct(
    authHeaders: AuthHeaders,
    id: string,
    rowNumber: number,
    product: MappedImportProduct,
  ): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .put(`/import/${id}/products/${rowNumber}`, product)
      .then(({ data }: AxiosResponse) => data);
  }
}

export const productImportApi = new ProductImportApi();
