<template>
  <FloatLabel variant="on">
    <InputText
      id="product-number"
      ref="inputRef"
      v-model="productNumberComputed"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('product.number-input').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      data-testid="product-number"
      class="w-full"
      type="text"
      maxlength="8000"
      :highlight-on-focus="true"
      :autofocus="props.autofocus ?? false"
    />
    <label for="product-number">{{ t("product.number") }} *</label>
  </FloatLabel>
  <small v-if="val.productNumber.$error" class="p-error" data-testid="product-number-error">
    {{ val.productNumber.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";

const props = defineProps<{
  productNumber: string;
  isEditing: boolean;
  autofocus?: boolean;
  focus?: boolean;
}>();

const { t } = useI18n();

const rules = {
  productNumber: {
    required: requiredIf(props.isEditing),
  },
};

const val = useVuelidate(rules, props);

const emit = defineEmits<{
  (e: "update:productNumber", value: string): void;
}>();

const productNumberComputed = computed<string>({
  get: () => {
    return props.productNumber ? props.productNumber : "";
  },
  set: (value) => {
    emit("update:productNumber", value);
  },
});

const inputRef = ref();
const focus = () => {
  if (!props.focus) {
    return;
  }

  if (inputRef.value) {
    inputRef.value.$el.focus();
  }
};

onMounted(focus);
</script>
