<template>
  <div class="flex flex-wrap gap-6 overflow-hidden">
    <div class="box-border w-[320px] h-[338px] flex-none">
      <FileUploadCard @add-image="emit('addImage', $event)" />
    </div>
    <TransitionGroup name="c-image-list" tag="div" class="contents">
      <div
        v-for="(image, index) of productImages"
        :key="image.url"
        class="box-border w-[320px] h-[338px] flex-none relative"
        :class="{ 'z-10': draggedIndex === index }"
        draggable="true"
        @dragstart="startDrag($event, index)"
        @dragend="endDrag"
        @dragover.prevent="dragOver($event, index)"
        @dragenter.prevent="dragEnter(index)"
        @dragleave.prevent="dragLeave"
        @drop.prevent="drop(index)"
      >
        <ImageCard
          :image="image"
          :index="index"
          :dragged-index="draggedIndex"
          :drag-over-index="dragOverIndex"
          @delete-image="() => emit('deleteImage', image.url)"
          @update-image="(image) => emit('updateImage', index, image)"
        />
      </div>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import type { ProductImage } from "@/repositories/product/model/ProductImage";
import ImageCard from "./ImageCard.vue";
import FileUploadCard from "./FileUploadCard.vue";

const props = defineProps<{
  productImages: ProductImage[];
}>();

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
  (e: "updateImageOrder", newOrder: ProductImage[]): void;
  (e: "deleteImage", imageUrl: string): void;
  (e: "updateImage", index: number, updatedImage: ProductImage): void;
  (e: "addImage", newImage: ProductImage): void;
}>();

const draggedIndex = ref<number | null>(null);
const dragOverIndex = ref<number | null>(null);

const startDrag = (event: DragEvent, index: number) => {
  if (event.dataTransfer) {
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/plain", index.toString());
  }
  draggedIndex.value = index;
};

const endDrag = () => {
  draggedIndex.value = null;
  dragOverIndex.value = null;
};

const dragEnter = (index: number) => {
  if (index !== draggedIndex.value) {
    dragOverIndex.value = index;
  }
};

const dragOver = (event: DragEvent, index: number) => {
  event.preventDefault();
  if (index !== draggedIndex.value) {
    dragOverIndex.value = index;
  }
};

const dragLeave = () => {
  dragOverIndex.value = null;
};

const drop = (index: number) => {
  if (draggedIndex.value !== null && index !== draggedIndex.value) {
    const newOrder = [...props.productImages];
    const [removed] = newOrder.splice(draggedIndex.value, 1);
    newOrder.splice(index, 0, removed);
    emit("updateImageOrder", newOrder);
  }
  endDrag();
};
</script>

<style scoped lang="scss">
.c-image-list-move {
  transition: transform 0.5s ease;
}

.c-image-list-enter-active,
.c-image-list-leave-active {
  transition: all 0.5s ease;
}

.c-image-list-enter-from,
.c-image-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.c-image-list-leave-active {
  position: absolute;
}
</style>
