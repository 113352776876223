import type { ProductValidationViewModel } from "@/product/models/ProductValidationViewModel";

export function useProductValidationNavigation(
  products: Ref<ProductValidationViewModel[]>,
  selectedProduct: Ref<ProductValidationViewModel | null>,
) {
  const navigateProduct = (direction: "next" | "previous") => {
    if (!selectedProduct.value) return;

    const currentIndex = products.value.findIndex(
      (p: ProductValidationViewModel) => p.product.rowNumber === selectedProduct.value?.product.rowNumber,
    );

    if (currentIndex === -1) return;

    const productsWithErrors = products.value.filter((p: ProductValidationViewModel) => p.hasErrors);

    if (productsWithErrors.length > 0) {
      const currentErrorIndex = productsWithErrors.findIndex(
        (p: ProductValidationViewModel) => p.product.rowNumber === selectedProduct.value?.product.rowNumber,
      );

      if (currentErrorIndex !== -1) {
        if (direction === "next") {
          if (currentErrorIndex < productsWithErrors.length - 1) {
            selectedProduct.value = productsWithErrors[currentErrorIndex + 1];
          } else {
            selectedProduct.value = productsWithErrors[0];
          }
          return;
        } else {
          if (currentErrorIndex > 0) {
            selectedProduct.value = productsWithErrors[currentErrorIndex - 1];
          } else {
            selectedProduct.value = productsWithErrors[productsWithErrors.length - 1];
          }
          return;
        }
      } else {
        if (direction === "next") {
          selectedProduct.value = productsWithErrors[0];
          return;
        } else {
          selectedProduct.value = productsWithErrors[productsWithErrors.length - 1];
          return;
        }
      }
    }

    if (direction === "next") {
      if (currentIndex < products.value.length - 1) {
        selectedProduct.value = products.value[currentIndex + 1];
      } else {
        selectedProduct.value = products.value[0];
      }
    } else {
      if (currentIndex > 0) {
        selectedProduct.value = products.value[currentIndex - 1];
      } else {
        selectedProduct.value = products.value[products.value.length - 1];
      }
    }
  };

  return {
    navigateProduct,
  };
}
