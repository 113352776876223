<template>
  <FileUpload
    ref="fileUpload"
    :multiple="false"
    accept=".csv,.xlsx,.xls"
    :maxFileSize="10000000"
    class="hidden"
    data-testid="product-import-file-upload"
    pt:root:class="border-none"
    pt:header:class="hidden"
    @select="onFileSelect($event)"
  >
    <template #content>
      <div id="min-test">
        <div v-if="uploadingFile" class="text-center p-6">
          <ProgressSpinner />
          <p class="text-gray-500 mt-4">{{ t("product.import.file-upload.loading") }}</p>
        </div>
        <div
          v-else-if="!selectedFile"
          class="border-2 border-dashed border-gray-300 rounded-lg p-12 text-center cursor-pointer hover:border-gray-400 transition-colors"
          @click="browse"
        >
          <div class="mb-4">
            <i class="pi pi-upload text-4xl text-gray-400"></i>
          </div>
          <p class="text-lg mb-2">{{ t("product.import.file-upload.dropzone.title") }}</p>
          <p class="text-sm text-gray-500">{{ t("product.import.file-upload.dropzone.click-to-browse") }}</p>
        </div>

        <div v-else class="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center">
          <div class="flex items-center justify-center">
            <div class="flex items-center">
              <div class="text-green-500 mr-3">
                <i class="pi pi-file-excel text-4xl"></i>
              </div>
              <div class="text-left">
                <p class="text-lg font-medium">{{ selectedFile.name }}</p>
              </div>
              <button
                class="ml-4 text-gray-400 hover:text-gray-600"
                :aria-label="t('product.import.file-upload.preview.remove-file')"
                @click="removeFile"
              >
                <i class="pi pi-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </FileUpload>
</template>

<script setup lang="ts">
import { useProductImportStore } from "@/product/api/import/ProductImportStore";
import { type FileUploadSelectEvent } from "primevue";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

interface ImportRow {
  [key: string]: string | number;
}

const emit = defineEmits<{
  (e: "fileUploaded"): void;
}>();

const fileUpload = ref();
const uploadingFile = ref(false);
const selectedFile = ref<File | null>(null);
const fileData = ref<ImportRow[]>([]);
const fileColumns = ref<string[]>([]);
const productImportStore = useProductImportStore();
const { t } = useI18n();

const browse = () => {
  fileUpload.value?.$el.querySelector('input[type="file"]').click();
};

const onFileSelect = async (event: FileUploadSelectEvent) => {
  const file = event.files?.[0];
  if (!file) return;

  try {
    uploadingFile.value = true;
    selectedFile.value = file;

    const formData = new FormData();
    formData.append("file", file);

    await productImportStore.initializeImport(formData);
    emit("fileUploaded");
  } catch {
    removeFile();
  } finally {
    uploadingFile.value = false;
  }
};

const removeFile = () => {
  selectedFile.value = null;
  fileData.value = [];
  fileColumns.value = [];
};
</script>
