<template>
  <div class="place-items-center mx-6 mt-4">
    <FloatLabel variant="on" :pt:root:class="`w-full`">
      <InputText
        v-model="nameComputed"
        id="ai-prompt-name"
        data-testid="ai-prompt-name"
        type="text"
        class="inputfield w-full"
        :maxlength="100"
        @blur="checkNameUniqueness"
        :disabled="isDisabled"
      />
      <label for="ai-prompt-name">{{ t("product.ai-search.style-name") }}</label>
    </FloatLabel>
    <p v-if="!isNameUnique" class="text-red-500">{{ t("product.ai-search.name-not-unique") }}</p>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { AIStandardPrompt } from "@/models/product-info/AIStandardPrompt";

const { t } = useI18n();
const props = defineProps<{
  name: string;
}>();

const emit = defineEmits<{
  (e: "update:name", value: string): void;
}>();
const isNameUnique = ref(true);

const nameComputed = computed({
  get: () => {
    if (props.name === AIStandardPrompt.DetailedPrompt) {
      return t("product.ai-search.prompt-title.detailedprompt");
    }
    if (props.name === AIStandardPrompt.SimplePrompt) {
      return t("product.ai-search.prompt-title.simpleprompt");
    }
    return props.name;
  },
  set: (value: string) => {
    emit("update:name", value);
  },
});

const isDisabled = computed(() => {
  return props.name === AIStandardPrompt.DetailedPrompt || props.name === AIStandardPrompt.SimplePrompt;
});

const checkNameUniqueness = () => {
  isNameUnique.value = props.name !== "";
};
</script>
