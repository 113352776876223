<template>
  <div>
    <DataTable
      :value="attributes"
      class="c-datatable [&_.p-datatable-tbody>tr>td]:py-1 [&_.p-datatable-thead>tr>th]:py-1"
      :rows="10"
      scrollable
      scroll-height="400px"
    >
      <Column field="attributeName" :header="t('product.attribute.name')" class="w-[300px]">
        <template #body="{ data }">
          <div v-if="data.isEditing" class="py-0">
            <Select
              v-model="data.attributeId"
              :options="getAvailableAttributesForSelection()"
              option-label="name"
              option-value="id"
              :placeholder="t('placeholder.select', { property: t('product.attribute.attribute').toLowerCase() })"
              class="w-[300px]"
              @change="onAttributeSelected(data)"
            />
            <div v-if="data.errors?.attributeId" class="text-red-500 text-sm mt-1">
              {{ data.errors.attributeId }}
            </div>
          </div>
          <span v-else class="py-0">{{ data.attributeName }}</span>
        </template>
      </Column>

      <Column field="value" :header="t('product.attribute.value')" class="w-[300px]">
        <template #body="{ data }">
          <div v-if="data.isEditing" class="py-0">
            <MultiSelect
              v-if="data.attributeId"
              v-model="data.attributeValueIds"
              :options="getAttributeValues(data.attributeId)"
              option-label="value"
              option-value="id"
              :placeholder="
                t('placeholder.select', { property: t('product.attribute.attribute-values').toLowerCase() })
              "
              class="w-[300px]"
            />
            <div v-if="data.attributeId && data.errors?.attributeValueIds" class="text-red-500 text-sm mt-1">
              {{ data.errors.attributeValueIds }}ssss
            </div>
          </div>
          <span v-else class="py-0">{{ data.value }}</span>
        </template>
      </Column>

      <Column class="w-[150px]">
        <template #body="{ data, index }">
          <div class="flex gap-2 py-0">
            <Button
              v-if="data.isEditing"
              icon="pi pi-check"
              class="p-button-rounded p-button-success p-button-text"
              :disabled="!isAttributeValid(data)"
              @click="handleSaveAttributeRow(data)"
            />
            <Button v-else icon="pi pi-pencil" class="p-button-rounded p-button-text" @click="editAttribute(data)" />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-text"
              :data-testid="`remove-attribute-${index}`"
              @click="onConfirmDelete($event, data)"
            />
          </div>
        </template>
      </Column>

      <template #empty>
        <div class="text-center py-4">{{ t("product.attribute.no-attributes") }}</div>
      </template>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { ProductAttributeRow } from "@/models/attribute/ProductAttributeRow";
import type { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import type { AttributeValueByLanguageIso } from "@/models/attribute/AttributeValueByLanguageIso";

const { t } = useI18n();

interface Props {
  attributes: ProductAttributeRow[];
  availableAttributes: AttributeByLanguageIso[];
  validateAttribute: (data: { attributeId: string; attributeValueIds: string[] }) => Promise<{
    valid: boolean;
    errors: { attributeId: string; attributeValueIds: string };
  }>;
  getAttributeValues: (attributeId: string) => AttributeValueByLanguageIso[];
  getAvailableAttributesForSelection: () => AttributeByLanguageIso[];
  onAttributeSelected: (attribute: ProductAttributeRow) => void;
  editAttribute: (attribute: ProductAttributeRow) => void;
  handleSaveAttributeRow: (attribute: ProductAttributeRow) => Promise<void>;
  onConfirmDelete: (event: Event, attribute: ProductAttributeRow) => void;
  isAttributeValid: (attribute: ProductAttributeRow) => boolean;
}

defineProps<Props>();
</script>
