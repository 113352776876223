<template>
  <Popover
    id="taxes_overlay_panel"
    ref="taxesPanelRef"
    append-to="body"
    :dismissable="true"
    :breakpoints="{ '960px': '75vw', '640px': '100vw', '90000px': '600px' }"
    @keydown.esc.stop="hideOverlayPanel"
  >
    <div class="">
      <DataTable
        :value="filteredTaxes"
        class="c-datatable"
        data-key="id"
        :auto-layout="true"
        responsive-layout="scroll"
        selection-mode="single"
        striped-rows
        @row-select="onRowSelect"
      >
        <div class="">
          <Column field="taxName" header="Name" />
          <Column field="description" header="Description" />
          <Column field="countryIso" header="Country">
            <template #body="{ data }">{{ data.countryIso }}</template>
          </Column>
          <Column field="taxType" header="Tax Type" />
          <Column field="rate" header="Tax Rate">
            <template #body="{ data }">{{ data.rate }}%</template>
          </Column>
          <Column field="isDefaultTaxForProduct" header="Default">
            <template #body="{ data }"
              ><Checkbox v-model="data.isDefaultTaxForProduct" :binary="true" :disabled="true"></Checkbox
            ></template>
          </Column>
        </div>

        <template #footer>
          <div class="p-dialog-footer" data-pc-section="footer">
            <div class="formgrid grid grid-cols-12 gap-4 mt-4">
              <div class="col-span-12 lg:col-span-12 flex flex-col">
                <div class="flex justify-end">
                  <Button
                    type="button"
                    :label="t('common.cancel')"
                    data-testid="cancel-btn"
                    class="c-dialog-default-button"
                    autofocus
                    severity="cancel"
                    variant="text"
                    icon="pi pi-times"
                    @click.stop="closeOverlayPanel"
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </DataTable>
    </div>
  </Popover>
</template>

<script setup lang="ts">
import { ProductTaxDisplay } from "@/repositories/product/model/ProductTaxDisplay";
import { type Tax } from "@/repositories/tax/model/Tax";
import { type DataTableRowSelectEvent } from "primevue/datatable";
import { computed, ref } from "vue";

import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps<{
  taxes: Tax[];
  showTaxPanel: boolean;
  selectedTax: ProductTaxDisplay;
  selectedIndex: number | null;
}>();

const emit = defineEmits<{
  (e: "selectTax", value: Tax): void;
  (e: "update:showTaxPanel", value: boolean): void;
}>();

const taxesPanelRef = ref();
const toggle = (event: Event) => {
  taxesPanelRef.value.toggle(event);
};

const visible = computed({
  get: () => {
    return props.showTaxPanel;
  },
  set: (value) => emit("update:showTaxPanel", value),
});

const hideOverlayPanel = () => {
  visible.value = false;
};
const filteredTaxes = computed<Tax[]>(() => {
  return props.taxes.filter((tax: Tax) => {
    return tax.countryIso === props.selectedTax.countryIso && tax.taxType === props.selectedTax.taxType;
  });
});

const onRowSelect = (event: DataTableRowSelectEvent) => {
  const tax = event.data as Tax;
  emit("selectTax", tax);
  taxesPanelRef.value.hide();
};

const closeOverlayPanel = () => {
  taxesPanelRef.value.hide();
};

defineExpose({ toggle: toggle });
</script>
<style scoped lang="scss"></style>
