import { useSalesUnitApi } from "@multicase/portal-api/sales-unit";
import { AddSalesUnitRequest, SalesUnit } from "@multicase/portal-api/sales-unit";
import { useVuelidate } from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import type { AuthHeaders } from "@multicase/portal-api";

export function useSalesUnits() {
  const salesUnit = ref<SalesUnit>(new SalesUnit());
  const salesUnits = ref<SalesUnit[]>([]);

  const salesUnitApi = useSalesUnitApi();
  const toast = useCumulusToast(useToast());
  const { getRequest, mutationRequest, loading, saving, error } = useRequests();

  const fetchSalesUnits = async () => {
    return await getRequest(async (authHeaders: AuthHeaders) => {
      salesUnits.value = await salesUnitApi.getAll(authHeaders);
    });
  };

  const fetchSalesUnitById = async (id: string) => {
    await getRequest(async (authHeaders: AuthHeaders) => {
      salesUnit.value = await salesUnitApi.get(authHeaders, id);
      salesUnitUnmodified.value = cloneDeep(salesUnit.value);
    });
  };

  const salesUnitUnmodified = ref<SalesUnit>(new SalesUnit());
  const isChanged = computed(() => {
    return !isEqual(salesUnit.value, salesUnitUnmodified.value);
  });

  const createSalesUnit = async (request: AddSalesUnitRequest): Promise<string> => {
    let newId = "";
    if (!(await isFormValid())) {
      return "";
    }

    await mutationRequest(async (authHeaders: AuthHeaders) => {
      newId = await salesUnitApi.create(authHeaders, request);
      const item = new SalesUnit();
      item.id = newId;
      item.name = request.name;
      item.description = request.description;
      salesUnits.value.push(item);
    });

    if (error.value) {
      toast.add({
        severity: "warn",
        summary: t("product.sales-unit.toast.error-create.summary"),
        detail: t("product.sales-unit.toast.error-create.detail"),
        closable: true,
      });
      console.error(error.value);
      return "";
    }

    toast.add({
      severity: "success",
      summary: t("product.sales-unit.toast.success-create.summary"),
      detail: t("product.sales-unit.toast.success-create.detail", { name: salesUnit.value.name }),
      closable: true,
    });

    return newId;
  };

  const updateSalesUnit = async (salesUnit: SalesUnit): Promise<boolean> => {
    if (!(await isFormValid())) {
      return false;
    }

    await mutationRequest(async (authHeaders: AuthHeaders) => {
      await salesUnitApi.update(authHeaders, salesUnit);

      const index = salesUnits.value.findIndex((su) => su.id === salesUnit.id);
      if (index !== -1) {
        salesUnits.value[index] = salesUnit;
        salesUnitUnmodified.value = cloneDeep(salesUnit);
      }
    });

    if (error.value) {
      toast.add({
        severity: "warn",
        summary: t("product.sales-unit.toast.error-update.summary"),
        detail: t("product.sales-unit.toast.error-update.detail"),
        closable: true,
      });
      return false;
    }

    toast.add({
      severity: "success",
      summary: t("product.sales-unit.toast.success-update.summary"),
      detail: t("product.sales-unit.toast.success-update.detail", { name: salesUnit.name }),
      closable: true,
    });
    return true;
  };

  const deleteSalesUnit = async (id: string): Promise<boolean> => {
    await mutationRequest(async (authHeaders: AuthHeaders) => {
      await salesUnitApi.delete(authHeaders, id);
    });

    const index = salesUnits.value.findIndex((su) => su.id === id);
    let name = "";
    if (index > -1) {
      name = salesUnits.value[index].name;
      salesUnits.value.splice(index, 1);
    }

    if (error.value) {
      toast.add({
        severity: "error",
        summary: t("product.sales-unit.toast.error-delete.summary"),
        closable: true,
      });
      return false;
    }

    toast.add({
      severity: "success",
      summary: t("product.sales-unit.toast.success-delete.summary"),
      detail: t("product.sales-unit.toast.success-delete.detail", { name: name }),
      closable: true,
    });
    return true;
  };

  const { t } = useI18n();
  const validate = useVuelidate();
  const isFormValid = async (): Promise<boolean> => {
    validate.value.$touch();

    if (!(await validate.value.$validate())) {
      toast.add({
        severity: "warn",
        summary: t("common.toast.warn.summary"),
        detail: t("common.toast.warn.detail"),
        closable: true,
      });
      return false;
    }
    return true;
  };

  onMounted(fetchSalesUnits);

  return {
    salesUnits,
    salesUnit,
    isChanged,
    fetchSalesUnitById,
    fetchSalesUnits,
    createSalesUnit,
    updateSalesUnit,
    deleteSalesUnit,
    loading,
    saving,
    error,
  };
}
