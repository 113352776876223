import { LanguageIsoType } from "@/models/language/LanguageIsoType";
import type { Product } from "@/repositories/product/model/Product";

export class ProductText {
  languageIso: LanguageIsoType = LanguageIsoType.Norwegian;
  name: string = "";
  description: string = "";
  productInfo: string = "";
  titleTag: string = "";
  metaDescription: string = "";

  public static fromProduct(product: Product, defualtLanguage: LanguageIsoType): ProductText {
    const productText = new ProductText();
    productText.languageIso = defualtLanguage;
    productText.name = product.name;
    productText.description = product.description;
    productText.productInfo = product.productInfo;
    productText.titleTag = product.titleTag ?? "";
    productText.metaDescription = product.metaDescription ?? "";
    return productText;
  }
}
