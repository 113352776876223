import { type Component } from "vue";
import { type RouteRecordRaw } from "vue-router";

export const mainRoutes: Array<RouteRecordRaw> =
  import.meta.env.VITE_APP_STANDALONE === "true" && !import.meta.env.VITE_APP_TESTS
    ? [
        {
          name: "Pages",
          path: "/",
          component: (): Component => import("./Routes.vue"),
        },
        {
          name: "oauth2-redirect",
          path: "/oauth2-redirect",
          component: (): Component => import("../components/auth/OAuth2Redirect.vue"),
        },
        {
          name: "oauth2-singout",
          path: "/oauth2-singout",
          component: (): Component => import("../components/auth/OAuth2Signout.vue"),
        },
        {
          name: "logout",
          path: "/logout",
          component: (): Component => import("../components/auth/Logout.vue"),
        },
        {
          path: "/failed",
          name: "Failed",
          component: (): Component => import("../components/auth/Failed.vue"),
        },
      ]
    : [];

export const productRoutes = [
  {
    path: "/product/search/:search?",
    name: "product-search",
    component: (): Component => import("@/product/views/ProductSearch.vue"),
    meta: {
      authRequired: true,
      title: "product.search-product",
    },
  },
  {
    path: "/product/add",
    name: "product-add",
    component: (): Component => import("@/product/views/ProductAdd.vue"),
    meta: {
      authRequired: true,
      title: "product.add-product",
    },
  },
  {
    path: "/product/edit/:id",
    name: "product-edit",
    component: (): Component => import("@/product/views/ProductEdit.vue"),
    meta: {
      authRequired: true,
      title: "product.edit-product",
    },
  },
  {
    path: "/product/import/:id?/:step?",
    name: "product-import",
    component: (): Component => import("@/product/views/ProductImport.vue"),
    props: true,
    meta: {
      authRequired: true,
      title: "product.import-product",
    },
  },
  {
    path: "/product/import/history",
    name: "product-import-history",
    component: () => import("@/product/views/ImportHistory.vue"),
    meta: {
      authRequired: true,
      title: "product.import-product",
    },
  },
];

export const attributeRoutes = [
  {
    path: "/product/attributes",
    name: "attributes",
    component: (): Component => import("@/attribute/views/AttributeList.vue"),
    meta: {
      authRequired: true,
      title: "product.attribute.label",
    },
  },
];
