<template>
  <div
    class="border rounded-lg p-3 h-[338px] flex flex-col bg-white shadow-sm transition-all duration-200 w-full transform-gpu"
    :class="{
      'ring-2 ring-primary': isEditing,
      'opacity-50': draggedIndex === index,
      'c-transform border-2 border-primary': dragOverIndex === index,
      'hover:shadow-md': draggedIndex !== index && dragOverIndex !== index,
    }"
  >
    <div class="relative mb-3">
      <img :src="image.url" :alt="image.name" class="w-full h-40 object-cover rounded" />
      <span
        class="absolute top-2 left-2 bg-primary text-white rounded-full w-6 h-6 flex items-center justify-center text-sm"
      >
        {{ index + 1 }}
      </span>
      <i class="pi pi-bars absolute top-2 right-2 text-white cursor-move" />
    </div>
    <div v-if="isEditing" class="flex-grow">
      <InputText
        v-model="editingName"
        :placeholder="t('placeholder.type', { property: t('product.image.image-name').toLowerCase() })"
        class="w-full mb-2"
      />
      <Textarea
        v-model="editingDescription"
        :placeholder="t('placeholder.type', { property: t('product.image.image-description').toLowerCase() })"
        class="w-full mb-2"
        rows="3"
      />
      <div class="flex justify-end space-x-2">
        <Button :label="t('common.cancel')" class="p-button-text p-button-sm" @click="cancelEdit" />
        <Button :label="t('common.save')" class="p-button-sm" @click="saveEdit" />
      </div>
    </div>
    <div v-else class="flex-grow">
      <h3 class="text-lg font-semibold mb-1 truncate">{{ image.name || t("product.image.unnamed-image") }}</h3>
      <p class="text-base text-gray-600 mb-2 line-clamp-5">
        {{ image.description || t("product.image.no-description") }}
      </p>
      <div class="flex justify-between items-center">
        <Button icon="pi pi-pencil" class="p-button-text p-button-sm" @click="startEdit" />
        <Button
          icon="pi pi-trash"
          class="p-button-text p-button-danger p-button-sm"
          :data-testid="`remove-image-${index}`"
          @click="onConfirmDelete"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProductImage } from "@/repositories/product/model/ProductImage";
import { useConfirm } from "primevue/useconfirm";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const confirm = useConfirm();
const { t } = useI18n();

const props = defineProps<{
  image: ProductImage;
  index: number;
  draggedIndex: number | null;
  dragOverIndex: number | null;
}>();

const emit = defineEmits<{
  (e: "deleteImage"): void;
  (e: "updateImage", image: ProductImage): void;
}>();

const startEdit = () => {
  isEditing.value = true;
  editingName.value = props.image.name;
  editingDescription.value = props.image.description;
};

const saveEdit = () => {
  const updatedImage = {
    ...props.image,
    name: editingName.value,
    description: editingDescription.value,
  };
  emit("updateImage", updatedImage);
  cancelEdit();
};

const isEditing = ref(false);
const editingName = ref("");
const editingDescription = ref("");

const cancelEdit = () => {
  isEditing.value = false;
  editingName.value = "";
  editingDescription.value = "";
};

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    group: "confirm-popup",
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: async () => {
      emit("deleteImage");
    },
  });
};
</script>

<style scoped>
.c-transform {
  transition: transform 0.2s ease-in-out;
}
</style>
